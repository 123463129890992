import axios, { AxiosResponse } from "axios";
import { ParamsDescriptions } from "utils/form/hooks/types/flowTypes";
import { DefaultPresetSettings } from "utils/form/hooks/types/presetTypes";
import { Auth } from "utils/form/hooks/useAuth";

/**
 * Получает описания параметров пресетов.
 *
 * @param {Auth} auth - учетные данные аутентификации
 * @return {ParamsDescriptions} описания параметров пресетов
 */
export const getPresetsParamsDescriptions = async(auth: Auth) => {
    try{
        const response: AxiosResponse<ParamsDescriptions> = await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/configs/descriptions`, {
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchPresetsDefaultSettings = async(auth: Auth) => {
    try {
        const response: AxiosResponse<DefaultPresetSettings> = await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/bass_presets/params`, {
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
    }
}