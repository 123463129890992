import React, { useContext, useState } from 'react'
import Papa from 'papaparse';
import { Box, Typography } from '@mui/material';
import { AppState } from 'App';

interface CSVSelectorProps {
  onChange: (data: string[][]) => void;
}

export function CSVSelector(props: CSVSelectorProps) {
  const state = useContext(AppState);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          try {
            const file = e.target.files[0];
    
            Papa.parse<string[]>(file, {
              worker: true, // use a web worker so that the page doesn't hang up
              complete({ data }) {
                props.onChange(data);
              },
            });
            state.uploadedCsvName.value = file.name;
            // call the onChange event
          } catch (error) {
            console.error(error);
          }
        }
      };

    return (
      <Box>
        <input 
          type="file" 
          accept=".csv" 
          onChange={handleFileChange} 
          hidden
        />
      </Box>
    )
}
