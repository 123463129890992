import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { AppState } from 'App';
import { AllMidiButton, DownloadArchiveButton, Loader } from 'components';
import React, { useContext } from 'react'
import { useAppSelector } from 'store/store';
import { LoadStatus } from 'utils/form/hooks/enums/loader';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';
import { PacketObject } from 'utils/form/hooks/types/packetTypes'

interface PacketTableRowProps {
    packet: PacketObject,
    handleCheckboxChange: (id: number) => void
}
export function PacketTableRow(props: PacketTableRowProps) {
    const state = useContext(AppState);
    const wavs = useAppSelector((state) => state.wavPackObjects.wavs);


    const getDate = (date: number | undefined) => {
        if (date === undefined) { return "" }
        return new Date(date * 1000).toLocaleString();
    }

  return (
    <React.Fragment>
        {/* <TableRow hover onClick={() => handlePackChange(pack)} key={pack.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> */}
        <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

            <TableCell>
                
            </TableCell>

            <TableCell>
                {props.packet.id}
            </TableCell>

            <TableCell>
                {
                    getDate(state.lastFlowResults.value.find((flow) => flow.packet_id === props.packet.id)?.start_time)
                }
            </TableCell>

            <TableCell>
                {
                    getDate(state.lastFlowResults.value.find((flow) => flow.packet_id === props.packet.id)?.end_time)
                }
            </TableCell>
            
            <TableCell>
                {
                    props.packet.rows.map((row, index) => (
                        <Typography key={index}>{row.path}</Typography>
                    ))
                }
            </TableCell>

            <TableCell align="right">
                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} alignItems={"center"}>
                    {   
                        wavs.find(wav => wav.id === props.packet.id)?.urlFile === LoadStatus.LOADING
                        || wavs.find(wav => wav.id === props.packet.id)?.urlFile === LoadStatus.FAILED
                        ? wavs.find(wav => wav.id === props.packet.id)?.urlFile : <></>
                    }
                    {
                        wavs.find(wav => wav.id === props.packet.id)?.urlFile === LoadStatus.LOADING ? <Loader/> : <></>
                    }
                    {
                        wavs.find(wav => wav.id === props.packet.id)?.urlFile !== LoadStatus.LOADING 
                        && wavs.find(wav => wav.id === props.packet.id)?.urlFile !== LoadStatus.FAILED 
                        && wavs.find(wav => wav.id === props.packet.id)?.urlFile !== null 
                        && wavs.find(wav => wav.id === props.packet.id)? (
                            <DownloadArchiveButton packet_id={props.packet.id} url={wavs.find(wav => wav.id === props.packet.id)?.urlFile || ""}/>
                            ) : <></>
                    }
                    <AllMidiButton 
                        id_packet={props.packet.id} 
                        packet_name={props.packet.name}
                        type={TypeMidi.STANDART}
                    />
                </Stack>

            </TableCell>

            <TableCell component="th" scope="row" >
                <input 
                    type="checkbox" 
                    checked={state.selectedRowsPackets.value.includes(props.packet.id)}
                    onChange={() => props.handleCheckboxChange(props.packet.id)}
                />
            </TableCell>
        </TableRow>
    </React.Fragment>

  )
}
