import { AppState } from "App";
import { useContext, useState } from "react";

export default function useNumNotes() {
    const state = useContext(AppState);

    const [selectedNumNotes, setSelectedNumNotes] = useState<number | null>(
        state.bassPreset.value.min_num_notes ? 
        state.bassPreset.value.min_num_notes[0] : null);

    const [selectedUniqueNumNotes, setSelectedUniqueNumNotes] = useState<number | null>(
        state.bassPreset.value.min_num_unique_notes ? 
        state.bassPreset.value.min_num_unique_notes[0] : null
    );

    const [selectedNotesCutoff, setSelectedNotesCutoff] = useState<number[] | null>(
        state.bassPreset.value.notes_cutoff ?
        state.bassPreset.value.notes_cutoff[0] : null
    )

    const [selectedRangeNotes, setSelectedRangeNotes] = useState<number[] | null>(
        state.bassPreset.value.range_notes ?
        state.bassPreset.value.range_notes : null
    )

    const handleChangeBaseNote = (event: React.ChangeEvent<HTMLInputElement>) => {
        const base_note = event.target.value;
        let new_range = state.bassPreset.value.range_notes;
        if (!parseInt(base_note)) {
            state.bassPreset.value = {
                ...state.bassPreset.value,
                range_notes: null
            }
            return;
        }
        if (new_range) {
            new_range = [Number(base_note), new_range[1], new_range[2]];
        } else {
            new_range = [Number(base_note), 0, 0];
        }

        setSelectedRangeNotes(new_range);
        state.bassPreset.value = {
            ...state.bassPreset.value,
            range_notes: new_range
        }
    };

    const handleChangeUp = (event: React.ChangeEvent<HTMLInputElement>) => {
        let up = event.target.value;
        let new_range = state.bassPreset.value.range_notes;
        if (!parseInt(up)) {
            up = '0';
            // return;
        }
        if (new_range) {
            new_range[2] = Number(up);
            // new_range = [new_range[0], new_range[1], Number(up)];
        } else {
            // new_range = [52, 0, Number(up)];
        }
        setSelectedRangeNotes(new_range);
        state.bassPreset.value = {
            ...state.bassPreset.value,
            range_notes: new_range
        }
    };

    const handleChangeDown = (event: React.ChangeEvent<HTMLInputElement>) => {
        let down = event.target.value;
        let new_range = state.bassPreset.value.range_notes;
        if (!parseInt(down)) {
            down = '0';
            // return;
        }
        if (new_range) {
            new_range = [new_range[0], Number(down), new_range[2]];
        } else {
            // new_range = [52, Number(down), 0];
        }
        setSelectedRangeNotes(new_range);
        state.bassPreset.value = {
            ...state.bassPreset.value,
            range_notes: new_range
        }
    }

    const handleDeleteNote = (index: number) => {
        let result = state.bassPreset.value.notes_cutoff;
        if (!result) {
            return;
        }
        let new_notes_cutoff = result[0];
        new_notes_cutoff.splice(index, 1);

        result[0] = new_notes_cutoff;

        setSelectedNotesCutoff(new_notes_cutoff);
        state.bassPreset.value = {
            ...state.bassPreset.value,
            notes_cutoff: result
        }
    }

    const handleAddNote = (note: string) => {
        let new_notes: number[] = [];
        if (!parseInt(note)) {
            return;
        }
        if (state.bassPreset.value.notes_cutoff) {
            new_notes = state.bassPreset.value.notes_cutoff[0];
            new_notes = [...new_notes, Number(note)];
            setSelectedNotesCutoff(new_notes);
        } else {
            new_notes = [Number(note)];
            setSelectedNotesCutoff(new_notes);
        }
        state.bassPreset.value = {
            ...state.bassPreset.value,
            notes_cutoff: [new_notes]
        }

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedNumNotes(Number(e.target.value));

        let res = parseInt(e.target.value);
        if (!res) {
            state.bassPreset.value = {
                ...state.bassPreset.value,
                min_num_notes: null
            }
            return;
        }
        
        state.bassPreset.value = {
            ...state.bassPreset.value,
            min_num_notes: [Number(e.target.value)] || null
        }
    }

    const handleChangeUnique = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedUniqueNumNotes(Number(e.target.value));

        let res = parseInt(e.target.value);
        if (!res) {
            state.bassPreset.value = {
                ...state.bassPreset.value,
                min_num_unique_notes: null
            }
            return;
        }

        state.bassPreset.value = {
            ...state.bassPreset.value,
            min_num_unique_notes: [Number(e.target.value)] || null
        }
    }

    return {
        handleChange,
        selectedNumNotes,
        handleChangeUnique,
        handleAddNote,
        handleDeleteNote,
        handleChangeBaseNote,
        handleChangeDown,
        handleChangeUp,
    }
}