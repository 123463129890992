import { useContext, useState } from "react";
import { NoteCapacity } from "./types/presetTypes";
import { Auth } from "./useAuth";
import { AppState } from "App";
import { SelectChangeEvent } from "@mui/material";

export default function useNotesCapacity() {
    const state = useContext(AppState);
    const [notesCapacity, setNotesCapacity] = useState<NoteCapacity[] | null>(state.bassPreset.value.notes_capacity);
    const directions = ["less", "more"];
    const [selectedNotesCapacity, setSelectedNotesCapacity] = useState<NoteCapacity>({
        note: "52",
        capacity: 0.5,
        direction: "less"
    });

    const deleteKey = (index: number) => {
        let result = state.bassPreset.value.notes_capacity;
        if (!result) {
            return;
        }
        // let new_notes_capacity = result[0];
        result.splice(index, 1);

        // result[0] = new_notes_capacity;

        setNotesCapacity(result);
        state.bassPreset.value = {
            ...state.bassPreset.value,
            notes_capacity: result
        }

        // console.log(state.bassPreset.value.notes_capacity);

    }

    const setNewItem = () => {
        let oldNotesCapacity = state.bassPreset.value.notes_capacity;
        if (oldNotesCapacity) {
            // let result = state.bassPreset.value.notes_capacity;
            oldNotesCapacity = [...oldNotesCapacity, selectedNotesCapacity];
            setNotesCapacity(oldNotesCapacity);

            // state.bassPreset.value.notes_capacity = result;
            state.bassPreset.value = {
                ...state.bassPreset.value,
                notes_capacity: oldNotesCapacity
            }

        } else {
            let nc = [selectedNotesCapacity];
            setNotesCapacity(nc);
            state.bassPreset.value = {
                ...state.bassPreset.value,
                notes_capacity: nc
            }
        }

        // console.log(notesCapacity);
        // console.log(state.bassPreset.value.notes_capacity);

    }

    const toDefaultSettings = (notes_capacity: NoteCapacity[]) => {
        setNotesCapacity(notes_capacity);
        state.bassPreset.value = {
            ...state.bassPreset.value,
            notes_capacity: notes_capacity
        }
    }

    const changeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedNotesCapacity({
            ...selectedNotesCapacity,
            note: e.target.value
        });
    }

    const changeDirection = (event: SelectChangeEvent) => {
        setSelectedNotesCapacity({
            ...selectedNotesCapacity, 
            direction: directions[parseInt(event.target.value)]
        });
    }

    const changeCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
        let capacity: number = parseInt(e.target.value) / 100;
        
        setSelectedNotesCapacity({
            ...selectedNotesCapacity,
            capacity: capacity
        });
    }

    return {
        notesCapacity,
        directions,
        deleteKey,
        selectedNotesCapacity,
        setNewItem,
        changeNote,
        changeDirection,
        changeCapacity
    }
}