import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import useAuth from 'utils/form/hooks/useAuth';
import useLearning from 'utils/form/hooks/useLearning';
import usePresets from 'utils/form/hooks/usePresets';

export function StartLearningButton() {
    const {auth} = useAuth();
    const {presets} = usePresets(auth);
    const {handleStartLearning, isLoaded, errorMessage} = useLearning();

  return (
    <Stack direction={"column"} spacing={2}>
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => handleStartLearning(presets)}
        disabled={isLoaded}
      >
        {isLoaded ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <CircularProgress/> 
            <Typography>Loading...</Typography>
          </Stack>
          ): 'Начать обучение'}
      </Button>

      <Typography variant="body2" color="error">{errorMessage}</Typography>
    </Stack>
  )
}
