import { Button } from '@mui/material'
import React, { useState } from 'react'
import RemoveIcon from '@mui/icons-material/Remove';
interface ArrayInputButtonProps {
    index: number,
    value: number,
    handleDeleteKey: (index: number) => void,
}
export function ArrayInputButton(props: ArrayInputButtonProps) {
    const [hovered, setHovered] = useState(false);

    const handleMove = () => {
        setHovered(true);
    }
    const handleLeave = () => {
        setHovered(false);
    }
  return (
    <React.Fragment>
        <Button 
            variant='outlined' 
            sx={{p: "2px"}} 
            onClick={() => props.handleDeleteKey(props.index)}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave}
        >
            {hovered ? <RemoveIcon/> : props.value}
        </Button>
    </React.Fragment>
  )
}
