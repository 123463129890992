import { ChromaticParams } from "utils/form/hooks/types/experimentsTypes";
import { Auth } from "utils/form/hooks/useAuth";
import { errorCatch } from "./error";
import axios, { AxiosResponse } from "axios";

export const generateChromatic = async (auth: Auth, params: ChromaticParams) => {
    try {
        const fileMidi = await axios.get<Blob>(params.source_file_url, {
            responseType: 'blob',
        })
        const fileMidiBlob = fileMidi.data

        const formData = new FormData();
        formData.append('midi_file', fileMidiBlob, params.source_file_name);

        const response: AxiosResponse<Blob> = await axios.post(
            `${process.env.REACT_APP_BACKEND_API_URL}/generate/chromaticV1/${params.algorithm}?synth_type=${params.algorithm}`,
            formData, {
                responseType: 'blob',
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            }
        )
        return new Blob([response.data]);
    } catch (error) {
        const errorMessage = errorCatch(error);
        return errorMessage
    }
}