import { ButtonsPackages, PackagesTable, TextareaPackages } from 'views'
import React from 'react'

export function PackagesPage() {
  return (
    <>
      <PackagesTable/>
    </>
  )
}

