import React from 'react'
import { AddCsvFileButton, AddTrackButton, ListOfButtonsMain, MainTable, TableFilter } from 'views/index'
import { Paper, Stack } from '@mui/material'

export function MainPage() {
  return (
    <>
      <Stack direction="column" spacing={"24px"} sx={{mt: "0px !important", mb: "36px !important"}}>
        <Paper sx={{ width: '100%', p: "24px", borderRadius: "24px" }} elevation={0}>

          <Stack direction="row" justifyContent="space-between" alignItems={"flex-start"} alignContent={"stretch"} sx={{ py: 4 }} >
            <TableFilter />

            <Stack direction="row" spacing={"16px"}>
              <AddCsvFileButton />
              <AddTrackButton />
            </Stack>
          </Stack>

          <MainTable/>
        </Paper>

        <ListOfButtonsMain/>
      </Stack>
    </>
  )
}

