import { Stack } from '@mui/material'
import React from 'react'

interface ExperimentsTableRatingContainerProps {
    children: React.ReactNode
}

export function ExperimentsTableRatingContainer(
    {children}: ExperimentsTableRatingContainerProps
) {
  return (
    <Stack direction={"row"} spacing={2} justifyContent={"space-between"} sx={{mt: 1, mb: 1}}>
      {children}
    </Stack>
  )
}
