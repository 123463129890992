import { Dialog, DialogTitle, Stack } from '@mui/material';
import { AppState } from 'App';
import { DownloadArchiveAllButton, DownloadMidiButton, MidiPlayerWrapper } from 'components';
import React, { useContext, useEffect, useState } from 'react'
import { LoadStatus } from 'utils/form/hooks/enums/loader';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';

interface AllMidiModalProps {
    isOpen: boolean;
    onClose: () => void;
    id_packet: number;
    packet_name: string;
    type: TypeMidi
}

export function AllMidiModal(props: AllMidiModalProps) {
    const state = useContext(AppState);


    const handleClose = () => {
        props.onClose();
    }

  return (
    <Dialog onClose={handleClose} open={props.isOpen} className='modal-dialog'>
        <DialogTitle>Все сгенерированные MIDI для пакета {props.packet_name}</DialogTitle>
        <Stack direction={"column"} spacing={2} sx={{ p: 3 }}>
            <DownloadArchiveAllButton packet_id={props.id_packet} type={props.type}/>
            {
                state.allMidisForPacket.value.map((midi) => (
                    <div key={midi.result_id}>
                        {
                            midi.midi_files.map((file) => (
                                <Stack key={file.id} direction={"column"} spacing={2}>
                                    <p>{file.name.split('/')[file.name.split('/').length-1]}</p>

                                    {file.url === LoadStatus.LOADING ? <p>Загрузка...</p> : null}

                                    {file.url === LoadStatus.FAILED ? <p>Ошибка загрузки</p> : null}

                                    {
                                        file.url.match('blob:') ? (
                                            <Stack direction={"row"} spacing={2}>

                                                <MidiPlayerWrapper src={file.url} />

                                                <DownloadMidiButton
                                                    packet_id={props.id_packet}
                                                    name={file.name.split('/')[file.name.split('/').length-1]}
                                                    result_id={midi.result_id}
                                                    type={props.type}
                                                />
                                            </Stack>
                                            ) : null
                                    }

                                </Stack>
                            ))
                        }
                    </div>
                ))
            }

        </Stack>
    </Dialog>
  )
}
