import { Stack, Tooltip } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
// import 'styles/Navbar.scss'
import { Router } from 'utils/routes/Routes'
import { NavTabs } from '../navigation/NavTabs'

export function Header() {
  const location = useLocation()

  return (
    <header>
      <nav>
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%', py: "36px" }} >
          <NavTabs />
        </Stack>
      </nav>
    </header>
  )
}

