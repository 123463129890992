import { Box, CardMedia, CircularProgress, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { AppState } from 'App';
import { GradeContainer, MidiPlayerWrapper } from 'components';
import React, { useContext } from 'react'
import { LoadStatus } from 'utils/form/hooks/enums/loader';
import { RatingVariant } from 'utils/form/hooks/enums/rating';
import { MidiFile } from 'utils/form/hooks/types/allDataTypes';
import { getTagById } from 'utils/helpers/tags.helper';
// import { WavFile } from 'utils/form/hooks/types/types'


interface MainTableRowProps {
    file: MidiFile,
    handleCheckboxChange: (id: number) => void
}

export function MainTableRow(props: MainTableRowProps) {
    const state = useContext(AppState);

  return (
    <React.Fragment>
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 }}} 
            selected={state.selectedRowsAllData.value.includes(props.file.id) ? true : false}
        >
            <TableCell>
                <Stack direction={"column"} spacing={"8px"}>
                    <Typography>{props.file.fileName}</Typography>
                    <MidiPlayerWrapper src={props.file.fileUrl} />
                    {/* <CardMedia
                        src={props.file.fileUrl}
                        component="audio"
                        controls
                    /> */}
                </Stack>
            </TableCell>

            <TableCell align="left">{getTagById(props.file.tagset.key_id, state.keyTags.value)?.name}</TableCell>

            <TableCell align="left">{getTagById(props.file.tagset.tonality_id, state.tonalityTags.value)?.name}</TableCell>

            <TableCell align="left">{props.file.tagset.bpm}</TableCell>

            <TableCell align="left">{getTagById(props.file.tagset.instrument_id, state.instrumentTags.value)?.name}</TableCell>

            <TableCell align='left'>{getTagById(props.file.tagset.genre_id, state.genreTags.value)?.name}</TableCell>

            <TableCell align="left">{props.file.packet}</TableCell>   

            {/* <TableCell align='left'> */}
                {/* <Stack direction={"row"} spacing={"8px"} alignItems={"center"}> */}
                    {/* { props.file.midiUrl !== LoadStatus.IDLE && !props.file.midiUrl.match('blob:') ? props.file.midiUrl : <></> } */}
                    {/* { props.file.midiUrl === LoadStatus.LOADING ? <CircularProgress/> : <></> } */}
                    {/* { */}
                        {/* props.file.midiUrl.match('blob:')  ? (        */}
                        {/* <Stack direction={"row"} spacing={"8px"}> */}
                            {/* <a href={file.midiUrl} download={file.midiUrl}>Скачать</a> */}
                            {/* <MidiPlayerWrapper src={props.file.midiUrl}/> */}
                            {/* <GradeContainer  */}
                                {/* variant={RatingVariant.CONVERSION}  */}
                                {/* conv_params={props.file} */}
                                {/* gen_params={null} */}
                            {/* /> */}
                        {/* </Stack>) : <></> */}
                    {/* } */}
                {/* </Stack> */}
            {/* </TableCell>     */}

            <TableCell component="th" scope="row">
                <input 
                    type="checkbox" 
                    checked={state.selectedRowsAllData.value.includes(props.file.id)}
                    onChange={() => props.handleCheckboxChange(props.file.id)}
                    />
            </TableCell>
        </TableRow>
    </React.Fragment>
  )
}
