import { Stack } from '@mui/material'
import { useSignals } from '@preact/signals-react/runtime'
import React, { createContext, useContext, useEffect } from 'react'
import useAuth from 'utils/form/hooks/useAuth'
import usePresets from 'utils/form/hooks/usePresets'
// import { createExperimentsState } from 'state/experimentsState'
import { BassPresetPanel, ExperimentsTable, ExperimentsPanel, MelodyRNNPanel, Melody2RhytmPanel, GeneralActionsPanel, ChromaticPanel, SynthXPanel } from 'views'
import { SavedWeightsPanel } from 'views/panels/experimentsPanel/SavedWeightsPanel'

// export const ExperimentsState = createContext(createExperimentsState());

export function ExperimentsPage() {

  return (
    <Stack direction="column" spacing={2}>
      {/* <ExperimentsState.Provider value={createExperimentsState()}> */}
        <GeneralActionsPanel/>
        <SynthXPanel/>
        <SavedWeightsPanel/>

        <Stack direction={"row"} spacing={2}>
          <Stack direction={"column"} spacing={1}>
            <ExperimentsPanel/>
            <MelodyRNNPanel />
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Melody2RhytmPanel/>
            <ChromaticPanel/>
          </Stack>
        </Stack>
        <BassPresetPanel/>
        <ExperimentsTable/>
      {/* </ExperimentsState.Provider> */}
    </Stack>
  )
}
