import { Auth } from "utils/form/hooks/useAuth";
import { errorCatch } from "./error";
import { Melody2RhitmOut, Melody2RhitmParams } from "utils/form/hooks/types/experimentsTypes";
import axios, { AxiosResponse } from "axios";

export const generateMelody2rhitm = async (auth: Auth, params: Melody2RhitmParams) => {
    try {

        const fileMelody = await axios.get<Blob>(params.melody_file_url, {
            responseType: 'blob',
        })
        const fileMelodyBlob = fileMelody.data

        const fileRhithm = await axios.get<Blob>(params.rhythm_file_url, {
            responseType: 'blob'
        })
        const fileRhithmBlob = fileRhithm.data

        const formData = new FormData();
        formData.append('melody_file', fileMelodyBlob, params.melody_file_name);
        formData.append('rhythm_file', fileRhithmBlob, params.rhythm_file_name);

        const response: AxiosResponse<Blob> = await axios.post(
            `${process.env.REACT_APP_BACKEND_API_URL}/generate/melody2rhythm`,
            formData, {
                responseType: 'blob',
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            }
        )
        return new Blob([response.data]);
    } catch (error) {
        const errorMessage = errorCatch(error);
        return errorMessage
    }
}