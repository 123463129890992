import { Button, Paper, Stack } from '@mui/material';
import { useSignal } from '@preact/signals-react';
import { AppState } from 'App';
import React, { useContext } from 'react'
import useAuth from 'utils/form/hooks/useAuth';
import usePresets from 'utils/form/hooks/usePresets';
import { BassPresetForm, NotesCapacityInput, NotesCutoffInput, NumNotesInput, NumUniqueInput, PresetSelect, RangeNotesInput } from 'views/forms';

export function BassPresetPanel() {
    // const state = useContext(AppState);
    // const {auth} = useAuth();

    // const {
    //   presetsParamsDescriptions, 
    //   fetchPresetsParamsDescriptions,
    //   presets,
    //   selectedPreset,
    //   presetSettings,
    // } = usePresets(auth);

    
  return (
    <Paper sx={{padding: 2}}>
      <BassPresetForm>
        <PresetSelect/>
        <Stack direction={"row"} spacing={2}>
          <NotesCapacityInput/>
          <NumNotesInput/>
          <NumUniqueInput/>
        </Stack>
        <Stack direction="row" spacing={2}>         
          <NotesCutoffInput/>
          <RangeNotesInput/>
        </Stack>
      </BassPresetForm>    
    </Paper>
  )
}
