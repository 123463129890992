import { Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Slider, Stack, Typography } from '@mui/material'
import { AppState } from 'App';
import React, { useContext, useState } from 'react'
import { useAppSelector } from 'store/store';

export function FiltersAllData() {
    const state = useContext(AppState);
    const minDistance = 0.010;
    // const [valence, setValence] = useState<number[]>([0, 1]);

    const handleChangeKeyTag = (e: SelectChangeEvent) => {
        const seldKeyTag = state.keyTags.value.find(tag => tag.id === Number(e.target.value));
        state.filterAllData.value = {
            ...state.filterAllData.value,
            keyTagId: seldKeyTag ? seldKeyTag.id : 0
        } 
    };

    const handleChangeTonalityTag = (e: SelectChangeEvent) => {
        const seldTonalityTag = state.tonalityTags.value.find(tag => tag.id === Number(e.target.value));
        state.filterAllData.value = {
            ...state.filterAllData.value,
            tonalityTagId: seldTonalityTag ? seldTonalityTag.id : 0
        }
    };

    const handleChangeInstrumentTag = (e: SelectChangeEvent) => {
        const seldInstrument = state.instrumentTags.value.find(tag => tag.id === Number(e.target.value));
        state.filterAllData.value = {
            ...state.filterAllData.value,
            instrumentTagId: seldInstrument ? seldInstrument.id : 0
        } 
    };

    const handleChangeGenresTag = (e: SelectChangeEvent) => {
        const seldGenre = state.genreTags.value.find(tag => tag.id === Number(e.target.value));
        state.filterAllData.value = {
            ...state.filterAllData.value,
            genreTagId: seldGenre ? seldGenre.id : 0
        } 
    };

    // const handleChangeTypeTag = (e: SelectChangeEvent) => {
    //     const seldTypeTag = tagTypes.find(tag => tag.id === Number(e.target.value));
    //     state.filterAllData.value = {
    //         ...state.filterAllData.value,
    //         typeTagId: seldTypeTag ? seldTypeTag.id : 0
    //     } 
    // };


    const handleChangeMinBpm = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.filterAllData.value = {
            ...state.filterAllData.value,
            bpmRange: [Number(e.target.value), state.filterAllData.value.bpmRange[1]]
        }
    }

    const handleChangeMaxBpm = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.filterAllData.value = {
            ...state.filterAllData.value,
            bpmRange: Number(e.target.value) === 0 ? [state.filterAllData.value.bpmRange[0], 1000001] : [state.filterAllData.value.bpmRange[1], Number(e.target.value)]
        }
    }

    // const handleChangeValence = (event: Event, newValue: number | number[], activeThumb: number) => {
    //     if (!Array.isArray(newValue)) {
    //         return;
    //     }

    //     if (newValue[1] - newValue[0] < minDistance) {
    //         if (activeThumb === 0) {
    //             const clamped = Math.min(newValue[0], 1-minDistance);
    //             state.filterAllData.value = {
    //                 ...state.filterAllData.value,
    //                 valenceRange: [clamped, clamped + minDistance]
    //             }
    //         } else {
    //             const clamped = Math.max(newValue[1], minDistance);
    //             state.filterAllData.value = {
    //                 ...state.filterAllData.value,
    //                 valenceRange: [clamped - minDistance, clamped]
    //             }
    //         } 
    //     } else {
    //         state.filterAllData.value = {
    //             ...state.filterAllData.value,
    //             valenceRange: newValue as [number, number]
    //         }   
    //     }
    // }

    // const handleChangeArousal = (event: Event, newValue: number | number[], activeThumb: number) => {
    //     if (!Array.isArray(newValue)) {
    //         return;
    //     }

    //     if (newValue[1] - newValue[0] < minDistance) {
    //         if (activeThumb === 0) {
    //             const clamped = Math.min(newValue[0], 1-minDistance);
    //             state.filterAllData.value = {
    //                 ...state.filterAllData.value,
    //                 arousalRange: [clamped, clamped + minDistance]
    //             }
    //         } else {
    //             const clamped = Math.max(newValue[1], minDistance);
    //             state.filterAllData.value = {
    //                 ...state.filterAllData.value,
    //                 arousalRange: [clamped - minDistance, clamped]
    //             }
    //         } 
    //     } else {
    //         state.filterAllData.value = {
    //             ...state.filterAllData.value,
    //             arousalRange: newValue as [number, number]
    //         }   
    //     }
    // }


  return (
    <Stack direction="column" spacing={"24px"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6">Фильтры</Typography>
        </Stack>

        <Stack direction={"column"} spacing={"16px"}>

            <Stack direction={"row"} spacing={"16px"}>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel id="filter-key-tag">Тональность</InputLabel>
                    <Select
                        id="filter-key-tag-select"
                        onChange={handleChangeKeyTag}
                        label="Key"
                        value={state.keyTags.value.find(tag => tag.id === state.filterAllData.value.keyTagId)?.id.toString() || ''}
                    > 
                        <MenuItem key={0} value={0}>All</MenuItem>
                        {
                            state.keyTags.value.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl sx={{width: "100%"}}>
                    <InputLabel id="filter-tonality-tag"></InputLabel>
                    <Select
                        id="filter-tonality-tag-select"
                        onChange={handleChangeTonalityTag}
                        label="Tonality"
                        value={state.tonalityTags.value.find(tag => tag.id === state.filterAllData.value.tonalityTagId)?.id.toString() || ''}
                    > 
                        <MenuItem key={0} value={0}>All</MenuItem>
                        {
                            state.tonalityTags.value.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>

            <FormControl>
                <InputLabel id="filter-chord-type-tag">Инструмент</InputLabel>
                <Select
                    id="filter-chord-type-tag-select"
                    onChange={handleChangeInstrumentTag}
                    label="Chord type"
                    value={state.instrumentTags.value.find(tag => tag.id === state.filterAllData.value.instrumentTagId)?.id.toString() || ''}
                > 
                    <MenuItem key={0} value={0}>All</MenuItem>
                    {
                        state.instrumentTags.value.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="filter-main-genre-tag">Жанр</InputLabel>
                <Select
                    id="filter-main-genre-tag-select"
                    onChange={handleChangeGenresTag}
                    label="Main genre"
                    value={state.genreTags.value.find(tag => tag.id === state.filterAllData.value.genreTagId)?.id.toString() || ''}
                > 
                    <MenuItem key={0} value={0}>All</MenuItem>
                    {
                        state.genreTags.value.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>


            <Stack direction={"column"} spacing={"4px"}>
                <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>Темп, bpm</Typography>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={"8px"}>
                    <FormControl>
                        <OutlinedInput
                            value={state.filterAllData.value.bpmRange[0] === 0 ? '' : state.filterAllData.value.bpmRange[0]}
                            onChange={handleChangeMinBpm}
                            inputProps={{
                                min: 0,
                                type: 'number',
                            }}
                            startAdornment={<InputAdornment position="start">От</InputAdornment>}
                        />
                    </FormControl>

                    <FormControl>
                        <OutlinedInput
                            value={state.filterAllData.value.bpmRange[1] >= 1000000 ? '' : state.filterAllData.value.bpmRange[1]}
                            onChange={handleChangeMaxBpm}
                            inputProps={{
                                type: 'number',
                            }}
                            startAdornment={<InputAdornment position="start">До</InputAdornment>}
                        />
                    </FormControl>
                </Stack>
            </Stack>

            {/* <Stack direction={"column"} spacing={"4px"}>
                <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>Valence</Typography>
                <Slider 
                    getAriaLabel={() => "Minimum distance shift"}
                    value={state.filterAllData.value.valenceRange}
                    onChange={handleChangeValence}
                    valueLabelDisplay='auto'
                    disableSwap
                    min={0}
                    step={0.01}
                    max={1}
                />
            </Stack> */}

            {/* <Stack direction={"column"} spacing={"4px"}>
                <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>Arousal</Typography>
                <Slider 
                    getAriaLabel={() => "Minimum distance shift"}
                    value={state.filterAllData.value.arousalRange}
                    onChange={handleChangeArousal}
                    valueLabelDisplay='auto'
                    disableSwap
                    min={0}
                    step={0.01}
                    max={1}
                />
            </Stack> */}

        </Stack>

    </Stack>
  )
}
