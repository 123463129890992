import { Rating, Stack, Typography } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useConfig from 'utils/form/hooks/useConfig'

interface RatingInputProps {
    config_id: number
}
export function RatingInput(props: RatingInputProps) {
    const state = useContext(AppState)
    const {handleAddRating} = useConfig()
  return (
    <Stack direction={"column"} spacing={1} alignItems={"start"}>
        <Typography component="legend">Оценка</Typography>
        <Rating 
            name="config-rating" 
            value={state.configs.value.find(c => c.id === props.config_id)?.rating || null} 
            onChange={(event, newValue) => handleAddRating(event, newValue, props.config_id)}
        />
    </Stack>
  )
}
