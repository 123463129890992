import { Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import { NoteCapacity } from 'utils/form/hooks/types/presetTypes'
import RemoveIcon from '@mui/icons-material/Remove';

interface NotesCapacitiInputButtonProps {
  index: number,
  value: NoteCapacity,
  handleDeleteNoteCapacity: (index: number) => void,
}

export function NotesCapacitiInputButton(props: NotesCapacitiInputButtonProps) {

  const [hovered, setHovered] = useState(false);

  const handleMove = () => {
    setHovered(true);
  }
  const handleLeave = () => {
      setHovered(false);
  }

  // const 

  return (
    <React.Fragment>
      <Button
        variant='outlined'
        sx={{p: "2px"}}
        onClick={() => props.handleDeleteNoteCapacity(props.index)}
        onMouseMove={handleMove}
        onMouseLeave={handleLeave}
      >
        {hovered ? <RemoveIcon/> : (
          <Typography variant="body1">
            {props.value.note} is {props.value.direction} than {props.value.capacity * 100} %
          </Typography>
        )}
      </Button>
    </React.Fragment>
  )
}
