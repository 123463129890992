import { Rating, Stack } from '@mui/material';
import React, { SyntheticEvent } from 'react'

interface RatingStarsProps {
    gradeNumber: number,
    onChangeGrade: (event: SyntheticEvent<Element, Event>, newValue: number | null) => void
}

export function RatingStars(props: RatingStarsProps) {
    const [hover, setHover] = React.useState(-1);
  return (
    <Stack direction="row" spacing={"8px"}>
        <Rating
            name="simple-controlled"
            value={props.gradeNumber}
            onChange={props.onChangeGrade}
            // precision={0.5}
            max={5}
            onChangeActive={(event, newHover) => {
                setHover(newHover);
            }}
        />
        {
            hover !== -1 ? hover : props.gradeNumber
        }
    </Stack>
  )
}
