import { ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react'
import { useAppSelector } from 'store/store';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { PacketObjectRow } from 'utils/form/hooks/types/packetTypes';

interface ResultRowTagsListProps {
    packet_id: number;
    rows: PacketObjectRow[];
    // onOpen: () => void;
}

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
  
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
            <Typography>{data[index].path}</Typography>
            {/* <Typography>{data[index].path}</Typography> */}
            {/* <Typography>{data[index].path}</Typography> */}
            {/* <Typography>{data[index].path}</Typography> */}
            {/* <Typography>{data[index].path}</Typography> */}
        </Stack>
      </ListItem>
    );
}

export function ResultRowTagsList(props: ResultRowTagsListProps) {
    // const packets = useAppSelector(state => state.packageObjects.packages);

    // const rows = packets.find(p => p.id === props.packet_id)?.rows || [];

  return (
    <>
        <FixedSizeList
            itemCount={props.rows.length}
            itemData={props.rows}
            itemSize={48}
            height={200}
            width={'100%'}
        >
            {renderRow}
        </FixedSizeList>
    </>
  )
}
