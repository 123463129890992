import { SelectChangeEvent } from "@mui/material"
import { AppState } from "App"
import { useContext } from "react"

export default function useChromatic() {
    const state = useContext(AppState)

    const algorithms = ['default', 'alg_2', 'big']

    const handleChangeSourceFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUrl = URL.createObjectURL(e.target.files[0])
            state.chromaticParams.value = {
                ...state.chromaticParams.value,
                source_file_name: e.target.files[0].name,
                source_file_url: fileUrl
            }
        }
    }
    const handleChangeAlgorithm = (event: SelectChangeEvent) => {
        state.chromaticParams.value = {
            ...state.chromaticParams.value,
            algorithm: event.target.value
        }
    }

    return {
        algorithms,
        handleChangeSourceFile,
        handleChangeAlgorithm
    }
}