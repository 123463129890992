import axios, { AxiosResponse } from "axios";
import { TypeMidi } from "utils/form/hooks/enums/typesResult";
import { Auth } from "utils/form/hooks/useAuth";
 
interface ParamsConfigArchive {
    auth: Auth
    type: TypeMidi
    obj_id: number
}

interface ParamsPacketArchive extends ParamsConfigArchive {
    url: string
}

export const fetchConfigArchive = async (params: ParamsConfigArchive) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/results/archive/config/${params.type}/${params.obj_id}`, {
            responseType: 'arraybuffer',
            auth: {
                username: params.auth.username,
                password: params.auth.password
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchArchiveAll = async (params: ParamsConfigArchive) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/results/archive/all/${params.type}/${params.obj_id}`, {
            responseType: 'arraybuffer',
            auth: {
                username: params.auth.username,
                password: params.auth.password
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchArchivePacketResult = async (params: ParamsPacketArchive) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/results/archive/packet/${TypeMidi.STANDART}/${params.obj_id}/${params.url}`, {
        responseType: 'arraybuffer',
          auth: {
              username: params.auth.username,
              password: params.auth.password
          }
    });
    return response.data;
}

export const fetchOriginalArchive = async (params:{auth: Auth, obj_id: number}) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/results/archive/original/${params.obj_id}`, {
                responseType: 'arraybuffer',
                auth: {
                    username: params.auth.username,
                    password: params.auth.password
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
    }
}