import { AppState } from "App";
import { useContext, useState } from "react";
import useAuth from "./useAuth";
import axios, { AxiosResponse } from "axios";
import { FlowParams, TaskName, TaskResultOut } from "./types/flowTypes";
import { urlBase } from "store/store";
import { BASE_MAGENTA_SETTINGS, INTERVAL, MELODY_RNN_PARAMS } from "utils/constants/constans";
import { fetchConfigs } from "api";
import { IMelodyRNNParams } from "./types/experimentsTypes";

export default function useLearning() {
    const state = useContext(AppState);
    const {auth} = useAuth();

    const [isLoaded, setIsLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const getConfigs = async () => {
        state.configs.value = [];
    
        const response = await fetchConfigs(auth);
        if (!response){
          state.configs.value = [];
          return;
        } 
    
        state.configs.value = response;
      }

    const checkTask = async (task_id: string) => {
        const intervalId = setInterval(async () => {
          try {
              const response: AxiosResponse<TaskResultOut> = await axios.get(`${urlBase}/status/${task_id}`, {
                  auth: {
                      username: auth.username,
                      password: auth.password
                  }
              });
              
              if (response.data.task_result) {
                  state.flowResults.value = [...state.flowResults.value, response.data.task_result];
                  clearInterval(intervalId);

                  getConfigs();
                  setIsLoaded(false);
              }
            } catch (error) {
              clearInterval(intervalId);
              setIsLoaded(false);
              setErrorMessage('Произошла ошибка генерации');
              console.error("interval error");
            }
        }, INTERVAL);
    }

    function replaceNullMelodyRNNParams() {
        let params: IMelodyRNNParams = state.melodyRNNParams.value;
        const base_params = MELODY_RNN_PARAMS;
    
        Object.keys(params).forEach((key) => {
          if (params[key] === null) {
            params[key] = base_params[key];
          }
        })
    
        return params
    }

    const handleStartLearning = async (
        presets: string[]
    ) => {
        let params: FlowParams = state.selectedParams.value;
    
        params = {
          ...params,
          bass_preset_settings: {
            bass_preset: presets[state.bassPreset.value.bass_preset],
            preset_settings: {
              ...state.bassPreset.value
            },
          },
          melodyRNNParams: replaceNullMelodyRNNParams()
        }
    
        if (!isLoaded) {
          setIsLoaded(true);
          setErrorMessage('');
        }
        try {
          const response: AxiosResponse<TaskName> = await axios.post(`${urlBase}/run/flow/`, params, {
            auth: {
                username: auth.username,
                password: auth.password
            }
          });
          const task: string = response.data.task_name;
          checkTask(task);
    
        } catch (error) {
          setIsLoaded(false);
          setErrorMessage('Произошла ошибка генерации');
          console.error(error); 
        }
    }
    
    const handleResetParams = () => {
        state.selectedParams.value = {
          ...state.selectedParams.value,
          model_settings: BASE_MAGENTA_SETTINGS
        }
    
        state.bassPreset.value = {
          bass_preset: 0,
          range_notes: null,
          min_num_notes: null,
          min_num_unique_notes: null,
          notes_capacity: [],
          notes_cutoff: null
        }
    }

    return {
        handleStartLearning,
        isLoaded,
        errorMessage,
        handleResetParams
    }
}