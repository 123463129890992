import { FormControl, TextField } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useSynthX from 'utils/form/hooks/useSynthX'

export function SynthXEpochsInput() {
    const state = useContext(AppState)
    const {handelChangeEpochs} = useSynthX()

  return (
    <FormControl>
        <TextField 
            type='number'
            value={state.synthX.value.epochs}
            onChange={handelChangeEpochs}
            name="branch_factor"
            label="branch_factor"
        />
    </FormControl>
  )
}
