import { FormControl, MenuItem, Select } from '@mui/material'
import { AppState } from 'App';
import React, { useContext } from 'react'
import useChromatic from 'utils/form/hooks/useChromatic';

export function ChromaticAlgorithmSelect() {
  const state = useContext(AppState);
  const {algorithms, handleChangeAlgorithm} = useChromatic()

  return (
    <FormControl>
      <Select
        value={state.chromaticParams.value.algorithm}
        onChange={handleChangeAlgorithm}
      >
        {
          algorithms.map((algorithm, index) => (
            <MenuItem key={index} value={algorithm}>{algorithm}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
