import { Divider, Stack, TableCell, TableRow } from '@mui/material'
import { AllMidiByConfig, DeleteConfigButton, DownloadArchiveOriginalButton, DownloadConfigArchive, SaveConfigButton } from 'components'
import React from 'react'
import { ConfigOut } from 'utils/form/hooks/types/configTypes'
import { ExperimentsTableParams } from './ExperimentsTableParams'
import { TypeMidi } from 'utils/form/hooks/enums/typesResult'
import { ExperimentsTableRatingContainer, TimeBlock, RatingInput } from 'views'

interface ExperimentsTableRowProps {
  result: ConfigOut
  page: number
  rowPerPage: number
  index: number
}
export function ExperimentsTableRow(props: ExperimentsTableRowProps) {

  return (
    <React.Fragment>
      <TableRow>
        {/* <TableCell></TableCell> */}
        <TableCell 
          align="left"
          // onClick={onOpen}
        >
          {props.result.datasets_id}
        </TableCell>

        <TableCell>
          <ExperimentsTableParams 
            config_id={props.result.id} 
            epochs={props.result.epochs}
            page={props.page}
            rowPerPage={props.rowPerPage}
            index={props.index}
          />
        </TableCell>

        <TableCell align="right">
          <Stack direction={"column"} spacing={1}>



            <ExperimentsTableRatingContainer>
              <RatingInput config_id={props.result.id}/>
              <TimeBlock config_id={props.result.id}/>
            </ExperimentsTableRatingContainer>

            <Stack direction={"row"} spacing={1}>
              <SaveConfigButton config_id={props.result.id} is_saved={props.result.is_saved}/>
              <DeleteConfigButton config_id={props.result.id} />
            </Stack>

            <Divider/>


            <Stack direction={"row"} spacing={1}>
            <DownloadArchiveOriginalButton
              config_id={props.result.id}
            />

            <DownloadConfigArchive
              config_id={props.result.id}
              type={TypeMidi.MERGED}
            />
            </Stack>
          </Stack>
          
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
