import { Paper, Typography } from '@mui/material'
import React from 'react'
import { ChromaticAlgorithmSelect, ChromaticForm, ChromaticGenerateButton, ChromaticSourceFileInput } from 'views/forms'

export function ChromaticPanel() {
  return (
    <Paper sx={{padding: 2}}>
        <ChromaticForm>
            <Typography variant="body2">Chromatic</Typography>

            <ChromaticAlgorithmSelect/>
            <ChromaticSourceFileInput/>
            <ChromaticGenerateButton/>
        </ChromaticForm>
    </Paper>
  )
}
