import axios, { AxiosResponse } from "axios";
import { urlBase } from "store/store";
import { ConfigOut, WeightsParams } from "utils/form/hooks/types/configTypes";
import { AllSettings } from "utils/form/hooks/types/experimentsTypes";
import { Auth } from "utils/form/hooks/useAuth";
import { formatNumberToThreeDecimals } from "utils/helpers/numberFormatting.helpers";
import { errorCatch } from "./error";
import { RatingOut } from "utils/form/hooks/types/ratingTypes";

/**
* Получает список всех весов (конфиги).
*
* @param auth - Объект, содержащий имя пользователя и пароль для аутентификации.
* @returns Promise, который преобразуется в массив объектов ConfigOut, представляющих полученные веса (конфиги)).
*/
export const fetchConfigs = async (auth: Auth) => {
    try {
        const response: AxiosResponse<ConfigOut[]> = await axios.get(`${urlBase}/configs/all`, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });

        return response.data;
    } catch (error) {
        console.error(errorCatch(error));
    }
}

/**
* Возвращает настройки обучения для указанного конфига (весов).
* (Используется только для экспериментов)
*
* @param auth - Объект, содержащий имя пользователя и пароль для аутентификации.
* @param pid - Id весов (конфига).
* @returns Promise, который преобразуется в объект AllSettings.
*/
export const getPresetByConfigId = async (auth: Auth, pid: number) => {
    try {
        const response: AxiosResponse<AllSettings> = await axios.get(`${urlBase}/configs/preset/${pid}`, {
        auth: {
        username: auth.username,
        password: auth.password
        }
        });
        return response.data;

    } catch (error) {
        console.error(errorCatch(error));
    }
}


export const deleteWeights = async (auth: Auth, config_id: number) => {
    try {
        await axios.delete(`${urlBase}/configs/delete_weight/${config_id}`, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });

        return true;
    } catch (error) {
        console.error(errorCatch(error));
        return false
    }
}

export const saveWeights = async (auth: Auth, config_id: number) => {
    try {
        const response: AxiosResponse<ConfigOut> = await axios.post(`${urlBase}/configs/save_weights/${config_id}`, {}, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });

        return response.data;
    } catch (error) {
        console.error(errorCatch(error));
        return false
    }
}

export const cancelSavingWeights = async (auth: Auth, config_id: number) => {
    try {
        const response: AxiosResponse<ConfigOut> = await axios.post(`${urlBase}/configs/cancel_saving_weights/${config_id}`, {}, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });
        return response.data;
    } catch (error) {
        console.error(errorCatch(error));
        return false
    }
}

export const changeConfigRating = async (auth: Auth, config_id: number, rating: number) => {
    try {
        const response: AxiosResponse<RatingOut> = await axios.post(`${urlBase}/configs/rating`, {
            config_id: config_id,
            rating_value: rating
        }, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });
        return response.data;
    } catch (error) {
        console.error(errorCatch(error));
    }
}

export const getSavedWeights = async (auth: Auth) => {
    try {
        const response: AxiosResponse<WeightsParams[]> = await axios.get(`${urlBase}/configs/saved_params`, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });
        return response.data;
    } catch (error) {
        console.error(errorCatch(error));
    }
}