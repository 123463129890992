import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";


type muiTab = {
    defaultProps?: ComponentsProps['MuiTab'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTab'];
    variants?: ComponentsVariants['MuiTab'];
}

export const MuiTab: muiTab = {
    styleOverrides: {
        root: {
            "&.Mui-selected": {
                color: colors.primaryMain,
                backgroundColor: colors.primaryLight,
            },
            borderRadius: "10px",
            padding: "8px 16px",
            color: colors.secondaryText,
            textTransform: "none",
        },
    },
}