import { Stack } from '@mui/material'
import React from 'react'
import { BeamSizeInput } from './variationPresetFormInputs/BeamSizeInput'
import { BranchFactorInput } from './variationPresetFormInputs/BranchFactorInput'
import { NumOutputsInput } from './variationPresetFormInputs/NumOutputsInput'
import { NumStepsInput } from './variationPresetFormInputs/NumStepsInput'
import { QpmInput } from './variationPresetFormInputs/QpmInput'
import { StepsPerIterationInput } from './variationPresetFormInputs/StepsPerIterationInput'
import { TemperatureInput } from './variationPresetFormInputs/TemperatureInput'

export function VariationPresetForm() {
  return (
    <Stack direction={"row"} spacing={2}>
      <BeamSizeInput />
      <BranchFactorInput />
      <NumOutputsInput />
      <NumStepsInput />
      <QpmInput />
      <StepsPerIterationInput />
      <TemperatureInput />
    </Stack>
  )
}
