import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SynthXForm } from 'views/forms';
import useSynthX from 'utils/form/hooks/useSynthX';
import { AppState } from 'App';
import { SynthXVariationSelect } from 'views/forms/experimental/synthXFormInputs/SynthXVariationSelect';
import { SynthXBassPresetSelect } from 'views/forms/experimental/synthXFormInputs/SynthXBassPresetSelect';
import { SynthXEpochsInput } from 'views/forms/experimental/synthXFormInputs/SynthXEpochsInput';
import { SynthXPacketSelect } from 'views/forms/experimental/synthXFormInputs/SynthXPacketSelect';
import { SynthPresetAddMidi } from 'views/forms/experimental/synthXFormInputs/SynthPresetAddMidi';
import { SynthXUpdateButton } from 'views/forms/experimental/synthXFormInputs/SynthXUpdateButton';

export function SynthXPanel() {
    const state = useContext(AppState)

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Настройки Synth X
        </AccordionSummary>
        <AccordionDetails>
            <SynthXForm>
                <SynthXPacketSelect/>
                <SynthXEpochsInput/>
                <SynthXBassPresetSelect/>
                <SynthXVariationSelect/>
                <SynthPresetAddMidi/>
                <SynthXUpdateButton/>
            </SynthXForm>
        </AccordionDetails>
    </Accordion>
  )
}
