import { Divider, Stack, Typography } from '@mui/material';
import { AppState } from 'App';
import { getPresetByConfigId } from 'api/configs.api';
import React, { useContext, useEffect, useState } from 'react'
import { BASE_BASS_PRESET_SETTINGS, BASE_MAGENTA_SETTINGS, MELODY_RNN_PARAMS } from 'utils/constants/constans'
import { AllSettings, MagentaSettings } from 'utils/form/hooks/types/experimentsTypes'
import useAuth from 'utils/form/hooks/useAuth';

interface ExperimentsTableParamsProps {
  config_id: number,
  epochs: number,
  page: number,
  rowPerPage: number,
  index: number
}
export function ExperimentsTableParams(props: ExperimentsTableParamsProps) {
  const state=useContext(AppState);

  const [preset, setPreset] = useState<AllSettings>({
    magenta_settings: BASE_MAGENTA_SETTINGS,
    bass_preset_settings: BASE_BASS_PRESET_SETTINGS,
    melodyRNNParams: MELODY_RNN_PARAMS
  });
  const {auth, setAuth} = useAuth();


  const fetchPreset = async () => {
    const response: AllSettings | undefined = await getPresetByConfigId(auth, props.config_id);
    setPreset(response ? response : {
      magenta_settings: BASE_MAGENTA_SETTINGS,
      bass_preset_settings: BASE_BASS_PRESET_SETTINGS,
      melodyRNNParams: MELODY_RNN_PARAMS
    });
  }

  useEffect(() => {
    // const index = state.configs.value.findIndex(config => config.id === props.config_id);
    if (props.index >= props.page * props.rowPerPage && props.index <= (props.page + 1) * props.rowPerPage) {
      fetchPreset();
    }
  }, [])
  
  return (
    <Stack direction={"column"} spacing={'4px'}>
      <Stack direction={"row"} spacing={2} sx={{p: '4px', borderStyle: 'solid', borderWidth: 1, borderColor: 'divider', borderRadius: '8px'}}>
        <Stack direction={"column"}>
          <Typography variant="body2">количество эпох: <b>{props.epochs}</b></Typography>
          <Typography variant="body2">algorithm: <b>{preset.magenta_settings.algorithm}</b></Typography>
          <Typography variant="body2">num_notes_in_melody: <b>{preset.magenta_settings.num_notes_in_melody}</b></Typography>
          <Typography variant="body2">first_note: <b>{preset.magenta_settings.first_note}</b></Typography>
          <Typography variant="body2">enable_random: <b>{preset.magenta_settings.enable_random ? "true" : "false"}</b></Typography>
        </Stack>

        <Stack direction={"column"}>
          <Typography variant="body2">reward_scaler: <b>{preset.magenta_settings.reward_scaler}</b></Typography>
          <Typography variant="body2">exploration_mode: <b>{preset.magenta_settings.exploration_mode}</b></Typography>
          <Typography variant="body2">stochastic_observations: <b>{preset.magenta_settings.stochastic_observations ? "true" : "false"}</b></Typography>
        </Stack>

        <Stack direction={"column"}>
          <Typography variant="body2">TECH_TONE: <b>{preset.magenta_settings.TECH_TONE}</b></Typography>
          <Typography variant="body2">TECH_DURATION: <b>{"["}{preset.magenta_settings.TECH_KEY?.map(x => x.toString()).join(", ") || ""}{"]"}</b></Typography>
          <Typography variant="body2">transpose_amount: <b>{preset.magenta_settings.transpose_amount}</b></Typography>
        </Stack>
      </Stack>
      {/* <Divider/> */}
      {
        preset.bass_preset_settings.preset_settings ? (
          <Stack direction={"row"} spacing={2} sx={{p: '4px', borderStyle: 'solid', borderWidth: 1, borderColor: 'divider', borderRadius: '8px'}}>
            <Stack direction={"column"}>
              <Typography variant='body2'>bass_preset: <b>{preset.bass_preset_settings.bass_preset}</b></Typography>
              <Typography variant='body2'>min_num_notes: <b>{preset.bass_preset_settings.preset_settings.min_num_notes || 'null'}</b></Typography>
              <Typography variant='body2'>min_num_unique_notes: <b>{preset.bass_preset_settings.preset_settings.min_num_unique_notes || 'null'}</b></Typography>
            </Stack>
            <Stack direction={"column"}>
              <Typography variant='body2'>notes_capacity: </Typography>
              {
                preset.bass_preset_settings.preset_settings.notes_capacity ? 
                preset.bass_preset_settings.preset_settings.notes_capacity.map((x, index) => (
                  <Typography key={index} variant='body2'><b>{x.note} is {x.direction} than {x.capacity * 100}%</b></Typography>
                )) : <b>null</b>
              }
            </Stack>
            <Stack direction={"column"}>
              <Typography variant='body2'>notes_cutoff: <b>
                {
                  preset.bass_preset_settings.preset_settings.notes_cutoff ? 
                  preset.bass_preset_settings.preset_settings.notes_cutoff.map((x, index) => (
                    <p key={index}>
                      {'['}{
                        x.map((y, ind) => (
                          <span key={ind}>{y} </span>
                        ))
                      }{']'}
                    </p>
                  )) : <b>null</b>
                }
              </b>
              </Typography>

              <Typography variant='body2'>range_notes: </Typography>
              {
                preset.bass_preset_settings.preset_settings.range_notes ? 
                (
                  <Stack direction={"column"} sx={{pl: 2}}>
                    <Typography variant='body2'>base_note: <b>{preset.bass_preset_settings.preset_settings.range_notes[0]}</b></Typography>
                    <Typography variant='body2'>down: <b>{preset.bass_preset_settings.preset_settings.range_notes[1]}</b></Typography>
                    <Typography variant='body2'>up: <b>{preset.bass_preset_settings.preset_settings.range_notes[2]}</b></Typography>
                  </Stack>
                ) : <b>null</b>
              }
              
            </Stack>
          </Stack>
        ) : <Stack>{preset.bass_preset_settings.bass_preset}</Stack>
      }
    </Stack>
  )
}
