import { Stack, Typography } from '@mui/material'
import { AppState } from 'App'
import { AddMidiFileButton } from 'components'
import React, { useContext } from 'react'
import useChromatic from 'utils/form/hooks/useChromatic'

export function ChromaticSourceFileInput() {
  const state = useContext(AppState)
  const {handleChangeSourceFile} = useChromatic()
  return (
    <Stack direction={"column"} spacing={1}>
      <AddMidiFileButton
        buttonText="Загрузить Миди Файл"
        handleFileChange={handleChangeSourceFile}
      />

      <Typography variant="caption">File: {state.chromaticParams.value.source_file_name}</Typography>

    </Stack>
  )
}
