import React, { useContext, useState } from 'react'
import { ModalPackageSave, ModalPackageSetup } from 'views/index'
// import 'styles/ListOfButtons.scss'
import axios from 'axios';
import {urlBase} from 'store/store'
import { Button, Stack } from '@mui/material';
import { AppState } from 'App';

interface ListOfButtonsProps {
  // selectionIds: number[];
}

export function ListOfButtonsMain(props: ListOfButtonsProps) {
  const state = useContext(AppState);

  const [isOpenModalSavePack, setIsOpenModalSavePack] = useState(false);
  const [isOpenModalPack, setIsOpenModalPack] = useState(false);


  const handleClick = () => {};

  /**
   * Открывает модальное окно для сохранениия пакета
  */    
  const handleSavePackage = () => {
    if (state.selectedRowsAllData.value.length === 0) {
      alert('Выберите хотя бы один трек');
      return;
    }
    setIsOpenModalSavePack(true);
  }

  /**
   * Закрывает модальное окно для отмене операции сохранения пакета
  */ 
  const handleCloseModal = () => {
    setIsOpenModalSavePack(false);
  }

    /**
   * Открывает модальное окно пакета.
   */
    const handleOpenModalPack = () => {
      if (state.selectedRowsAllData.value.length === 0) {
        alert('Выберите хотя бы один трек');
        return;
      }
      setIsOpenModalPack(true);
    }
  
    /**
     * Закрывает модальное окно пакета.
     */
    const handleCloseModalPack = () => {
      setIsOpenModalPack(false);
    }

  return (
    <Stack direction={"row"} spacing={"16px"} justifyContent={"flex-end"}>

      {/* <Button variant='outlined' disabled>Загрузить новые</Button> */}
      <Button variant='outlined' onClick={handleOpenModalPack}>Тегировать</Button>
      <Button onClick={handleSavePackage} variant='contained'>Сохранить в пакет</Button>

      <ModalPackageSave isOpen={isOpenModalSavePack} onClose={handleCloseModal}/>
      <ModalPackageSetup isOpen={isOpenModalPack} onClose={handleCloseModalPack}/>

    </Stack>
  )
}

