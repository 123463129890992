export const statisticsTexts = {
    "notes_not_in_key": "Количество нот не в тональности (на N сгенерированных примеров)",
    "notes_in_motif": "Количество нот в составе мотива (на N сгенерированных примеров)",
    "notes_in_repeated_motif": "Количество нот в составе повторяющегося мотива (на N сгенерированных примеров)",
    "num_starting_tonic": "Количество примеров, начинающихся с тоники ",
    "num_repeated_notes": "Количество повторов нот (на N сгенерированных примеров)",
    "num_octave_jumps": "Количество прыжков на октаву (на N сгенерированных примеров)",
    "num_fifths": "Количество квинт (на N сгенерированных примеров)",
    "num_thirds": "Количество терций (на N сгенерированных примеров)",
    "num_sixths": "Количество секст (на N сгенерированных примеров)",
    "num_seconds": "Количество секунд (на N сгенерированных примеров)",
    "num_fourths": "Количество кварт (на N сгенерированных примеров)",
    "num_sevenths": "Количество септ (на N сгенерированных примеров)",
    "num_rest_intervals": "Количество пауз (на N сгенерированных примеров)",
    "num_special_rest_intervals": "Количество особых пауз",
    "num_in_key_preferred_intervals": "Количество интервалов, соответствующих тональности (на N сгенерированных примеров)",
    "num_resolved_leaps": "Количество разрешившихся скачков (интервалов больше, чем 2 полутона) (на N сгенерированных примеров)",
    "num_leap_twice": "Количество двойных скачков (на N сгенерированных примеров)",
    "num_high_unique": "Количество композиций с уникальным верхним тоном ",
    "num_low_unique": "Количество композиций с уникальным нижним тоном",
    "num_compositions": "Количество композиций, сгенерированных в процессе обучения",
    "total_notes": "Количество нот, сгенерированных в процессе обучения"
}
