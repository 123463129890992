import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiTableCell = {
    defaultProps?: ComponentsProps['MuiTableCell'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTableCell'];
    variants?: ComponentsVariants['MuiTableCell'];
}

export const MuiTableCell: muiTableCell = {
    styleOverrides: {
        root: {
            
        },
        head: {
            backgroundColor: colors.commonWhite,
        }
  }
}