import { FormControl, TextField } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useVariationPreset from 'utils/form/hooks/useVariationPreset'

export function BranchFactorInput() {
    const state = useContext(AppState)
    const {handleChangeNumberData} = useVariationPreset()
    
  return (
    <FormControl>
      <TextField 
        type='number'
        value={state.melodyRNNParams.value.branch_factor}
        onChange={handleChangeNumberData}
        name="branch_factor"
        label="branch_factor"
      />
    </FormControl>
  )
}
