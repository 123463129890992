import { FormControl, MenuItem, Select, Stack, Typography } from '@mui/material'
import { AppState } from 'App';
import React, { useContext } from 'react'
import useAuth from 'utils/form/hooks/useAuth';
import usePresets from 'utils/form/hooks/usePresets'

export function PresetSelect() {
  const state = useContext(AppState);
  const {auth} = useAuth();
  const {handleChangePreset, presets} = usePresets(auth);
  return (
    <Stack spacing={"4px"} sx={{minWidth: "200px"}}>
      <Typography 
        variant="caption" 
        display="block" 
        sx={{color: 'secondary.contrastText'}}
      >
          Выбор BassPreset 
      </Typography>

      <FormControl>
        <Select
          // value={selectedPreset.toString()}
          value={state.bassPreset.value.bass_preset.toString()}
          onChange={handleChangePreset}
        >
          {
            presets.map((preset, index) => (
              <MenuItem key={index} value={index}>{preset}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Stack>

  )
}
