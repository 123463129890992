import { LocalStorage } from "services/LocalStorage/LocalStorage.service";
import { SessionStorage } from "services/SessionStorage/SessionStorage.service"

export const logout = () => {
    // SessionStorage.setItem("username", "");
    // SessionStorage.setItem("password", "");
    console.log("logout");
    // SessionStorage.clear();
    LocalStorage.clear();
}

export const getAuth = () => {
    const name = LocalStorage.get("username");
    const pass = LocalStorage.get("password");

    if (name && pass) {
        return true;
    } else {
        return false;
    }
}