import { Popover, Stack, Typography } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { RatingStars } from './RatingStars'

interface GradePopoverProps {
    anchorEl: HTMLElement | null
    open: boolean
    onClose: () => void
    gradeNumber: number
    onChangeGrade: (event: SyntheticEvent<Element, Event>, newValue: number | null) => void
}

export function GradePopover(props: GradePopoverProps) {
  return (
    <Popover
        id="mouse-over-popover"
        open={props.open}
        anchorEl={props.anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onClose={props.onClose}
    >
        <Stack>
            <Typography variant="body2">Оценить:</Typography>
            {/* <GradeStars gradeNumber={props.gradeNumber} onChangeGrade={props.onChangeGrade}/> */}
            <RatingStars gradeNumber={props.gradeNumber} onChangeGrade={props.onChangeGrade}/>
        </Stack>
    </Popover>
  )
}
