import React, { useContext, useState } from 'react'
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import { Button } from '@mui/material';
import { AllMidiModal } from 'views/index';
import { urlBase } from 'store/store';
import axios, { AxiosResponse } from 'axios';
import { LoadStatus } from 'utils/form/hooks/enums/loader';
import { AppState } from 'App';
import { MidisForPacket, NamesMidiForPacket } from 'utils/form/hooks/types/packetTypes';
import useAuth from 'utils/form/hooks/useAuth';
import { getAxiosErrorStatus } from 'utils/helpers/axiosErrorStatus.helpers';
import { logout } from 'services';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';

interface AllMidiButtonProps {
    id_packet: number
    packet_name: string
    type: TypeMidi
}

export function AllMidiButton(props: AllMidiButtonProps) {
    const state = useContext(AppState);
    // const dispatch = useAppDispatch();
    const {auth, setAuth} = useAuth();

    const [open, setOpen] = useState(false);

    // const midisForPacket = useAppSelector((state) => state.midisForPacket.midis);

    const fetchMidiFile = async (result_id: string, name: string) => {
        try {
            const fname = name.split("/")[name.split("/").length - 1];
            const response: AxiosResponse<Blob> = await axios.get(`${urlBase}/results/midi/${props.type}/${result_id}/${props.id_packet}/${fname}`, {
                responseType: 'blob',
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            });
            const resUrl = URL.createObjectURL(response.data);

            state.allMidisForPacket.value.forEach((item) => {
                if (item.result_id === result_id) {
                    item.midi_files.forEach((midiFile) => {
                        if (midiFile.name === name) {
                            midiFile.url = resUrl;
                        }
                    });
                };
            });

            state.allMidisForPacket.value = [...state.allMidisForPacket.value];
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (axios.isAxiosError(error)) {
                    const status = getAxiosErrorStatus(error);
    
                    if (status === 401) {
                        logout();
                        state.isLogin.value = false;
                    }
                }
            }
        }
    }

    const fetchAllMidis = async () => {
        try {
            const response: AxiosResponse<NamesMidiForPacket[]> = await axios.get(`${urlBase}/results/packet/${props.type}/${props.id_packet}`, {
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            });
            const responseData: NamesMidiForPacket[] = response.data;

            responseData.forEach((item) => {
                let newMidisForPacket: MidisForPacket = {
                    result_id: item.result_id,
                    midi_files: []
                }

                item.midi_files.forEach((name) => {
                    newMidisForPacket.midi_files = [...newMidisForPacket.midi_files, {
                        id: newMidisForPacket.midi_files.length,
                        name: name,
                        url: LoadStatus.LOADING
                    }]
                });

                state.allMidisForPacket.value = [...state.allMidisForPacket.value, newMidisForPacket];
            });

            responseData.forEach((item) => {
                item.midi_files.forEach((name) => {
                    fetchMidiFile(item.result_id, name);
                })
            })
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (axios.isAxiosError(error)) {
                    const status = getAxiosErrorStatus(error);
    
                    if (status === 401) {
                        logout();
                        state.isLogin.value = false;
                    }
                }
            }
        }
    }

    const handleClickOpen = () => {
        // dispatch(fetchNamesMidiForPacket(props.id_packet));
        
        // state.selectedPacketId.value = props.id_packet;
        fetchAllMidis();
        setOpen(true);
    };

    const handleClose = () => {
        // dispatch(clearMidisForPacket());

        state.allMidisForPacket.value = [];
        setOpen(false);
    }

    return (
        <>
            <Button onClick={handleClickOpen}>
                <QueueMusicIcon/>
            </Button>
            <AllMidiModal 
                isOpen={open} 
                onClose={handleClose} 
                id_packet={props.id_packet}
                packet_name={props.packet_name}
                type={props.type}
                />
        </>
    )
}
