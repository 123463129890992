import axios, { AxiosResponse } from "axios";
import {useContext, useEffect, useState} from "react";
import { Auth } from "./useAuth";
import { Dictionary } from "@reduxjs/toolkit";
import { ParamsDescriptions } from "./types/flowTypes";
import { signal } from "@preact/signals-react";
import { fetchPresetsDefaultSettings, getPresetsParamsDescriptions } from "api/presets.api";
import { SelectChangeEvent } from "@mui/material";
import { DefaultPresetSettings, PresetSettings } from "./types/presetTypes";
import { AppState } from "App";

export default function usePresets(auth: Auth) {
    const state = useContext(AppState);
    // const [presetsParamsDescriptions, setPresetsParamsDescriptions] = useState<ParamsDescriptions>();
    const presets = [ "sharp_remove", "complex_bass", "simple_bass", "middle_bass"];
    // const [defaultPresets, setDefaultPresets] = useState<DefaultPresetSettings>();
    const [presetSettings, setPresetSettings] = useState<PresetSettings>();

    // const [selectedPreset, setSelectedPreset] = useState<number>(0);

    // useEffect(() => {
    //     if (!presetsParamsDescriptions) {
    //         fetchPresetsParamsDescriptions(auth);
    //     }
    //     if (!defaultPresets) {
    //         fetchDefaultPresetSettings(auth);
    //     }
    // }, [])

    const fetchPresetsParamsDescriptions = async (auth: Auth) => {
        const data = await getPresetsParamsDescriptions(auth);
        if (!data) {
            return;
        }
        // setPresetsParamsDescriptions(data);
        state.presetsParamsDesc.value = data;
    };

    const fetchDefaultPresetSettings = async (auth: Auth) => {
        const data = await fetchPresetsDefaultSettings(auth);
        if (!data) {
            return;
        }
        // setDefaultPresets(data);
        state.defaultBassPresets.value = data;
    }

    const handleChangePreset = (event: SelectChangeEvent) => {
        if (state.defaultBassPresets.value) {
            fetchDefaultPresetSettings(auth);
            const defautSettings = state.defaultBassPresets.value[presets[parseInt(event.target.value)]];
            state.bassPreset.value = {
                notes_capacity: defautSettings.notes_capacity,
                range_notes: defautSettings.range_notes,
                min_num_notes: defautSettings.min_num_notes,
                min_num_unique_notes: defautSettings.min_num_unique_notes,
                notes_cutoff: defautSettings.notes_cutoff,
                bass_preset: parseInt(event.target.value)
            }

            // setSelectedPreset(parseInt(event.target.value));
            state.selectedBassPreset.value = parseInt(event.target.value);

            // const indexPreset: number = selectedPreset;
            setPresetSettings(defautSettings);
            // console.log(defaultPresets[presets[parseInt(event.target.value)]]);

            // console.log(state.bassPreset.value.notes_capacity)
            // console.log(defautSettings.notes_capacity)
        }

        // console.log(state.bassPreset.value);

    }

    return {
        // presetsParamsDescriptions,
        presets,
        // defaultPresets,
        presetSettings,
        // selectedPreset,
        fetchPresetsParamsDescriptions,
        fetchDefaultPresetSettings,
        handleChangePreset
    }
}