import { Button, Stack, Typography, CircularProgress } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import DownloadIcon from '@mui/icons-material/Download';

import useAuth from 'utils/form/hooks/useAuth'
import { generateChromatic } from 'api/chromatic.api';

export function ChromaticGenerateButton() {
  const state = useContext(AppState)
  const {auth} = useAuth()
  const handleGenerate = async (auth: any) => {
    // проверка: загружены все файлы
    if (state.chromaticParams.value.source_file_name === "") {
        state.chromaticParams.value = {
            ...state.chromaticParams.value,
            error: "Файл не загружен"
        }
        return
    }

    try {
        state.chromaticParams.value = {
            ...state.chromaticParams.value,
            isLoading: true,
            error: ""
        }

        const response = await generateChromatic(auth, state.chromaticParams.value)

        if (typeof response === "string") {
            // если ошибка - обновляем состояние
            state.chromaticParams.value = {
                ...state.chromaticParams.value,
                isLoading: false,
                error: response
            }
            return
        }
        const resUrl = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = resUrl;
        link.setAttribute('download', `chromatic_${state.chromaticParams.value.algorithm}_${Date.now()}.mid`);
        document.body.appendChild(link);
        link.click();
        link.remove();  
        URL.revokeObjectURL(resUrl);

        state.chromaticParams.value = {
            ...state.chromaticParams.value,
            isLoading: false,
            error: ""
        }
    } catch (error) {
        state.chromaticParams.value = {
            ...state.chromaticParams.value,
            isLoading: false,
            error: "что-то не так"
        }
    }
  }

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        <Button variant="contained"
          onClick={() => handleGenerate(auth)}
          disabled={state.chromaticParams.value.isLoading}
        >
          {
              state.chromaticParams.value.isLoading ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                      <CircularProgress/> 
                      <Typography>Loading...</Typography>
                  </Stack>
              ) : 'Сгенерировать'
          }
        </Button>
      </Stack>
    </Stack>
  )
}
