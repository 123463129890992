import axios, { AxiosResponse } from "axios";
import { urlBase } from "store/store";
import { MidiFile } from "utils/form/hooks/types/allDataTypes";
import { ConfigOut } from "utils/form/hooks/types/configTypes";
import { RatingOut } from "utils/form/hooks/types/ratingTypes";
// import { WavFile } from "utils/form/hooks/types/types";
import { Auth } from "utils/form/hooks/useAuth";
import { formatNumberToThreeDecimals } from "utils/helpers/numberFormatting.helpers";

// export async function setRatingConversion(file: MidiFile, rating: number, auth: Auth) {
//     try {
//         const fileName = file.fileName.replace(/#/g, "%23");
//         const response: AxiosResponse<RatingOutConv> = await axios.post(`${urlBase}/results/rating/${file.packet}/${fileName}/${rating}`, {}, {
//             auth: {
//                 username: auth.username,
//                 password: auth.password
//             }
//         });
//         return response.data;
//     } catch (error) {
//         console.error(error);
//     }
// }

export async function setRatingGeneration(config: ConfigOut, rating: number, auth: Auth) {
    try {
        const params = {
            rate: rating,
            result: config.id
        }
        const response: AxiosResponse<RatingOut> = await axios.post(`${urlBase}/results/rating/add`, params, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }

}

export async function getRatingByConfigId(config_id: number) {
    try {
        const response: AxiosResponse<number> = await axios.get(`${urlBase}/results/rating/${config_id}`);
        return formatNumberToThreeDecimals(response.data);
    } catch (error) {
        console.error(error);
    }
}