import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { stat } from "fs"
import { MidiFile } from "utils/form/hooks/types/allDataTypes";

// import { WavFile } from 'utils/form/hooks/types/types'

interface FileState{
    files: MidiFile[]
}

const initialState: FileState = {
    files: []
}

export const FileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {
        addFile: (state, action: PayloadAction<{fileInfo: MidiFile}>) => {
            // назначить id
            const num = state.files.length;
            action.payload.fileInfo.id = num;

            state.files.push(action.payload.fileInfo);
            // localStorage.setItem(action.payload.fileInfo.fileUrl, action.payload.fileInfo.id.toString());
        },
        updateValuesFileWav: (state, action: PayloadAction<{newValues: MidiFile}>) => {
            state.files.forEach(file => {
                if (file.id === action.payload.newValues.id) {
                    file.tagset = action.payload.newValues.tagset;
                    file.packet = action.payload.newValues.packet;
                    // file.midiUrl = action.payload.newValues.midiUrl;
                    // file.rating = action.payload.newValues.rating;
                }
            })
        },

        // updateRatingConversion: (state, action: PayloadAction<{id: number, rating: number}>) => {
        //     state.files.forEach(file => {
        //         if(file.id === action.payload.id) {
        //             file.rating = action.payload.rating;
        //         }
        //     });
        // },

        // fileUpdateMidi: (state, action: PayloadAction<{id: number, midiUrl: string}>) => {
        //     state.files.forEach(file => {
        //         if (file.id === action.payload.id) {
        //             file.midiUrl = action.payload.midiUrl;
        //         }
        //     })
        // }
    },
});

export default FileSlice.reducer
export const {addFile, updateValuesFileWav} = FileSlice.actions