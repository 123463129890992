import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { type } from "@testing-library/user-event/dist/type";
import axios, { AxiosError, AxiosResponse } from "axios";
import { urlBase } from "store/store";
import { LoadStatus } from "utils/form/hooks/enums/loader";
import { TypeMidi } from "utils/form/hooks/enums/typesResult";
// import { MidiFileForPacket, MidisForPacket } from "utils/form/hooks/types/packetTypes";

interface MidisForPacket {
    result_id: string,
    midi_files: MidiFileForPacket[],
}

interface MidiFileForPacket {
    name: string,
    url: string
}
interface MidiForPacketTypeState {
    midis: MidisForPacket[],
    loading: boolean,
    error: string | null
}

const initialState: MidiForPacketTypeState = {
    midis: [],
    loading: false,
    error: null
}

export type NamesMidiForPacket = {
    result_id: string,
    midi_files: string[]
}

export type MidiForPacketType = {
    packet_id: number,
    type: TypeMidi,
}

export const fetchNamesMidiForPacket = createAsyncThunk(
    "names-midi-for-packet/fetch",
    async (params: MidiForPacketType) => {
        const response: AxiosResponse<NamesMidiForPacket[]> = await axios.get(`${urlBase}/results/packet/${params.type}/${params.packet_id}`);

        const responseData: NamesMidiForPacket[] = response.data;
        let resultMidis: MidisForPacket[] = [];

        responseData.forEach((res) => {
            let newMidiFileForPacket: MidisForPacket = {
                result_id: res.result_id,
                midi_files: []
            }

            res.midi_files.forEach((name) => {
                newMidiFileForPacket.midi_files.push({name: name, url: LoadStatus.IDLE});
            });

            resultMidis = [...resultMidis, newMidiFileForPacket];
        });
        return resultMidis;
    }
)

export interface MidiFetchParams {
    result_id: string,
    packet_id: number,
    name: string
}

export interface MidiFetchReturnObj {
    packet_id: number,
    result_id: string,
    name: string,
    urlMidi: string
}

export const fetchMidisForPacket = createAsyncThunk(
    "midi-for-packet/fetch",
    async (params: MidiFetchParams)=> {
        const response: AxiosResponse<Blob> = await axios.get(`${urlBase}/results/wav/${params.result_id}/${params.packet_id}/${params.name}`, {responseType: 'blob',});
        const urlMidi = URL.createObjectURL(response.data);
        const result: MidiFetchReturnObj = {
            packet_id: params.packet_id,
            result_id: params.result_id,
            name: params.name,
            urlMidi: urlMidi
        }
        return result;
    }
)

export const MidiForPacketSlice = createSlice({
    name: "midi-for-packet",
    initialState,
    reducers: {
        // addMidiForPacket: (state, action: PayloadAction<MidisForPacket>) => {
        //     state.midis.push(action.payload);
        // },
        clearMidisForPacket: (state) => {
            state.midis = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNamesMidiForPacket.fulfilled, (state, action: PayloadAction<MidisForPacket[]>)=>{
            state.midis = action.payload;
            console.log("state: ", state.midis);
        });

        builder.addCase(fetchMidisForPacket.fulfilled, (state, action: PayloadAction<MidiFetchReturnObj>)=>{
            state.midis.forEach(midiForPacket => {
                if (midiForPacket.result_id === action.payload.result_id) {
                    midiForPacket.midi_files.forEach(midi => {
                        if (midi.name === action.payload.name) {
                            midi.url = action.payload.urlMidi
                        }
                    })
                }
            })
        });
    }
})

export default MidiForPacketSlice.reducer;
// export const {addMidiForPacket, clearMidisForPacket} = MidiForPacketSlice.actions;
export const {clearMidisForPacket} = MidiForPacketSlice.actions;
