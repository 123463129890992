import { Paper, Typography } from '@mui/material'
import React from 'react'
import { BeamSizeInput, VariationPresetForm } from 'views/forms'

export function MelodyRNNPanel() {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="caption">MelodyRNNParams</Typography>
      <VariationPresetForm />
    </Paper>
  )
}
