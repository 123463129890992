import { Stack, Tooltip, Typography } from '@mui/material'
import { AppState } from 'App';
import React, { useContext } from 'react'
import useAuth from 'utils/form/hooks/useAuth';
import usePresets from 'utils/form/hooks/usePresets';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ArrayInputForm } from 'components';
import useNumNotes from 'utils/form/hooks/useNumNotes';

export function NotesCutoffInput() {
  const state = useContext(AppState);
  const {auth} = useAuth();
  // const {presetsParamsDescriptions} = usePresets(auth);
  const {handleAddNote, handleDeleteNote} = useNumNotes();

  return (
    <Stack spacing={"4px"} sx={{minWidth: "200px"}}>
      <Tooltip title={state.presetsParamsDesc.value?.notes_cutoff}>
        <Typography 
          variant="caption" 
          display="block" 
          sx={{color: 'secondary.contrastText'}}
        >
            notes_cutoff
            <HelpOutlineIcon fontSize='small' sx={{p: '2px'}}/>
        </Typography>
      </Tooltip>

      <ArrayInputForm
        tech_key={state.bassPreset.value.notes_cutoff ? state.bassPreset.value.notes_cutoff[0] : []}
        handleDeleteKey={handleDeleteNote}
        handleSetNewkey={handleAddNote}
      />
    </Stack>
  )
}
