import { Button } from '@mui/material'
import { AppState } from 'App';
import React, { ChangeEvent, useContext } from 'react'
import { addFile } from 'store/features/fileSlice';
import { useAppDispatch, useAppSelector } from 'store/store'
import { LoadStatus } from 'utils/form/hooks/enums/loader';
// import { WavFile } from 'utils/form/hooks/types/types';
import { TagForm, TagFormChips, TagFormNumber, TagFormSlider } from 'components/index';
import { TagStr } from 'utils/form/hooks/types/tagsTypes';
import { MidiFile } from 'utils/form/hooks/types/allDataTypes';


export function AddTrackButton() {
    const state = useContext(AppState);
    const files = useAppSelector(state => state.files.files);
    const dispatch = useAppDispatch();

    const isInCsv = (fileName: string) : boolean => {
        if (state.csvFile.value.find((row) => row[0] === fileName)) {
            return true;
        }
        return false;
    }

    /**
     * Retrieves a TagStr object from the given tagsList based on the provided nameTag.
     * 
     * @param nameTag - The name of the tag to search for.
     * @param tagsList - The list of tags to search in.
     * @returns The matching TagStr object, or null if no match is found.
     */
    const getValueFromListTags = (nameTag: string, tagsList: TagStr[]) : TagStr | null => {
        return tagsList.find((tag) => tag.name === nameTag) || null;
    }


    /**
     * Adds tags to the WavFile object from a CSV file.
     * @param fileInfo - The WavFile object to add tags to.
     * @returns The updated WavFile object with added tags.
     */
    const addTagsFromCsv = (fileInfo: MidiFile) : MidiFile => {
        if (!isInCsv(fileInfo.fileName)) return fileInfo;

        // 0-Name, 1-Key, 2-Chord_type, 3-bpm, 4-packName, 5-label,
        // 6-main_genre, 7-type, 8-tag_1, 9-tag_2, 10-tag_3,
        // 11-tag_4, 12-tag_5, 13-valence, 14-arousal
        try {
            const csvRow = state.csvFile.value.find((row) => row[0] === fileInfo.fileName);
            // fileInfo.tagset.key_id = csvRow ? (csvRow[1] ? getValueFromListTags(csvRow[1], keyTypes) : null) : null;
            // fileInfo.chordTypeTag = csvRow ? (csvRow[2] ? getValueFromListTags(csvRow[2], chordTypes) : null) : null;
            // fileInfo.bpmTag = csvRow ? (Number(csvRow[3] )|| null) : null;

            // fileInfo.mainGenreTag = csvRow ? (csvRow[4] ? getValueFromListTags(csvRow[4], mainGenres) : null) : null;
            // fileInfo.typeTag = csvRow ? (csvRow[5] ? getValueFromListTags(csvRow[5], tagTypes) : null) : null;
            // // 8, 9, 10, 11, 12 - tag_1 - tag_5
            // fileInfo.tags1to5 = csvRow ? getTags1To5(csvRow.slice(6, 11)) : [];
            // fileInfo.valenceTag = csvRow ? (Number(csvRow[11] )|| null) : null;
            // fileInfo.arousalTag = csvRow ? (Number(csvRow[12] )|| null) : null;
    
        } catch (error) {
            console.error(error);
        }
        return fileInfo;
    }

    /**
     * Handles the change event when a file is selected.
     * @param event - The change event triggered by the file input element.
     */
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        // выбранные файлы
        const new_files = Array.from(event.target.files ?? []);
        for (let i = 0; i < new_files.length; i++) {

            const urlFile = URL.createObjectURL(new_files[i]);

            let fileInfo: MidiFile = {
                id: 0,
                fileName: new_files[i].name,
                fileUrl: urlFile,
                tagset: {
                    key_id: null,
                    tonality_id: null,
                    instrument_id: null,
                    genre_id: null,
                    bpm: null,
                },
                packet: null,
                // midiUrl: LoadStatus.IDLE,
                // rating: 0,
            }

            fileInfo = addTagsFromCsv(fileInfo);

            const fileIncluded = files.find((file) => file.fileName === fileInfo.fileName);
            if (fileIncluded) {
                const result = window.confirm(fileIncluded.fileName + ' уже есть в таблице. Добавить еще раз?');

                if (result) {
                    dispatch(addFile({fileInfo: fileInfo}));
                } else {
                }
            } else {
                    dispatch(addFile({fileInfo: fileInfo}));
        }
        }
    };


    return (
        <Button variant='contained' component="label">
            Загрузить файлы
            <input
                type='file'
                accept="audio/midi"
                onChange={handleFileChange}
                hidden
                multiple
            />
        </Button>
    )
}
