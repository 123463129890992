import { Button, Stack, Typography } from '@mui/material';
import { AppState } from 'App';
import { CSVSelector } from 'components';
import React, { useContext } from 'react'
import {HEADERS_CSV} from 'utils/constants/constans';

export function AddCsvFileButton() {
    const state = useContext(AppState);

    const checkHeaders = (headers: string[]) : boolean => {
        // if (headers.length !== 15) return false;
        // for (let i = 0; i < HEADERS_CSV.length; i++) {
            // if (headers[i] !== HEADERS_CSV[i]) return false;
        // }
        return true;
    }

    const getIndexHeaderByValue = (value: string) : number => {
        return HEADERS_CSV.indexOf(value) || -1;
    }

    const addCsvFile = (_data: string[][]) => {
        // проверка headers
        const csvData = _data;
        if (!checkHeaders(csvData[0])) {
            // alert("Неправильные заголовки файла");
            return;
        }

        // получение индексов
        // let indexesHeaders: number[] = [];
        // csvData[0].forEach((header) => {
        //     indexesHeaders.push(getIndexHeaderByValue(header));
        // });

        // let newData: string[][] = [];
        // for (let i = 0; i < indexesHeaders.length; i++) {
        //     if (indexesHeaders[i] !== -1) {
                
        //     }
        // }
        state.csvFile.value = csvData;
        console.log("csvData", csvData.slice(1));
    }

  return (
    <Stack direction={"column"}>
        <Button variant="outlined" component="label">
            CSV&nbsp;
            <CSVSelector onChange={addCsvFile}/>
        </Button>

        <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>{state.uploadedCsvName.value}</Typography>
    </Stack>
  )
}
