import { FormControl, IconButton, InputAdornment, MenuPaper, Popover, TextField } from '@mui/material';
import React from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { FiltersAllData } from './FiltersAllData';

interface SearchFilterProps {
    searchTerm: string
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function SearchFilter(props: SearchFilterProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <FormControl sx={{width: '100%' }}>
            <TextField
                type="search"
                value={props.searchTerm}
                onChange={props.handleSearchChange}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchRoundedIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleClick}>
                                <TuneRoundedIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    placeholder: 'Поиск',
                }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <FiltersAllData />
            </Popover>
        </FormControl>
    )
}
