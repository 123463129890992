import { Button, FormControl, OutlinedInput, Stack } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { ArrayInputButton } from '../buttons/ArrayInputButton';

interface ArrayInputFormProps {
    tech_key: number[],
    handleDeleteKey: (index: number) => void,
    handleSetNewkey: (newKey: string) => void,
}
export function ArrayInputForm(props: ArrayInputFormProps) {

    const [newKey, setNewKey] = useState(0);

    const changeNewKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewKey(parseInt(e.target.value))
    }
    
  return (
    <Stack direction="column" spacing={"2px"} alignContent={"flex-end"}>
        <Stack direction="row" spacing={"2px"}>
            <FormControl>
                <OutlinedInput
                    type="number"
                    value={newKey}
                    onChange={changeNewKey}
                    size='small'
                />
            </FormControl>

            <Button 
                variant='contained' 
                sx={{p: "2px"}}
                size='small'
                onClick={() => props.handleSetNewkey(newKey.toString())}
            >
                <AddIcon/>
            </Button>
        </Stack>

        <Stack direction="row" spacing={"2px"}>
            {
                props.tech_key.map((value, index) => (
                    <ArrayInputButton
                        key={index}
                        index={index}
                        value={value}
                        handleDeleteKey={props.handleDeleteKey}
                    />
                ))
            }

        </Stack>


    </Stack>
  )
}
