import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type tableRow = {
    defaultProps?: ComponentsProps['MuiTableRow'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTableRow'];
    variants?: ComponentsVariants['MuiTableRow'];
}

export const MuiTableRow: tableRow = {
    styleOverrides: {
        root: {
            backgroundColor: colors.commonWhite,
            "&.Mui-selected": {
                backgroundColor: "#F3F3FF",
            },

        }
    },
}