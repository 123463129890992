import { Button, Dialog, DialogActions, DialogContent, DialogTitle, SelectChangeEvent, Stack, Typography} from '@mui/material';
import { AppState } from 'App';
import React, { useContext, useEffect, useState } from 'react'
import { updateValuesFileWav } from 'store/features/fileSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
// import 'styles/modal.scss'
// import { LoadStatus } from 'utils/form/hooks/enums/loader';
// import { WavFile } from 'utils/form/hooks/types/types';
// import { TagStr } from 'utils/form/hooks/types/tagsTypes';

import { TagForm, TagFormNumber } from 'components/index';
import { MidiFile } from 'utils/form/hooks/types/allDataTypes';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

// interface RadioOption {
//     id: number;
//     name: string;
//     tags: Jenre[] | Subjenre[] | RhythmicPattern[] | Instrument[] | number[] | PartCompose[];
// }

// interface RadioTag {
//     id: number;
//     tag: Jenre | Subjenre | RhythmicPattern | Instrument | number | PartCompose;
// }

export function ModalPackageSetup(props: ModalProps) {
    const state = useContext(AppState);

    const dispatch = useAppDispatch();
    const files=useAppSelector((state) => state.files.files);

    const [selectedKeyTag, setSelectedKeyTag] = useState<number>(0);
    const [selectedTonalityTag, setSelectedTonalityTag] = useState<number>(0);
    const [selectedInstrumentTag, setSelectedInstrumentTag] = useState<number>(0);
    const [selectedBpm, setSelectedBpm] = useState<number>(0);
    const [selectedGenreTag, setSelectedGenreTag] = useState<number>(0);

    const [tags, setTags] = useState<MidiFile>({
        id: 0,
        fileName: '',
        fileUrl: '',
        tagset: {
            key_id: null,
            tonality_id: null,
            instrument_id: null,
            bpm: null,
            genre_id: null
        },
        packet: null,
    });

    useEffect(() => {
        return () => {
            if (state.selectedRowsAllData.value.length === 1) {
                const file = files.find(file => file.id === state.selectedRowsAllData.value[0]);
                setSelectedKeyTag(file? (file.tagset.key_id || 0) : 0);
                setSelectedInstrumentTag(file? (file.tagset.instrument_id || 0) : 0);
                setSelectedBpm(file? (file.tagset.bpm || 0) : 0);
                setSelectedGenreTag(file? (file.tagset.genre_id || 0) : 0);
                setSelectedTonalityTag(file? (file.tagset.tonality_id || 0) : 0);
                setTags({
                    id: 0,
                    fileName: '',
                    fileUrl: '',
                    tagset: {
                        key_id: file? file.tagset.key_id : null,
                        tonality_id: file? file.tagset.tonality_id : null,
                        instrument_id: file? file.tagset.instrument_id : null,
                        bpm: file? file.tagset.bpm : null,
                        genre_id: file? file.tagset.genre_id : null
                    },
                    packet: null,
                });

            } else {
                setSelectedKeyTag(0);
                setSelectedInstrumentTag(0);
                setSelectedBpm(0);
                setSelectedGenreTag(0);
                setSelectedTonalityTag(0);
                setTags({
                    id: 0,
                    fileName: '',
                    fileUrl: '',
                    tagset: {
                        key_id: null,
                        tonality_id: null,
                        instrument_id: null,
                        bpm: null,
                        genre_id: null
                    },
                    packet: null,
                })
            }
        }
    }, [state.selectedRowsAllData.value]);

    // const [selectedOption, setSelectedOption] = useState(0);
    // const [selectedTag, setSelectedTag] = useState(0);

    const handleChangeKey = (e: SelectChangeEvent) => {
        const seldKey = state.keyTags.value.find(key => key.id === Number(e.target.value));
        if (seldKey) {
            setSelectedKeyTag(seldKey.id);
        } else {
            setSelectedKeyTag(0);
        }
        setTags({
            ...tags,
            tagset: {
               ...tags.tagset,
                key_id: state.keyTags.value.find(key => key.id === Number(e.target.value))?.id || null,
            }
        })
    }

    const handleChangeTonality = (e: SelectChangeEvent) => {
        const seldTonality = state.tonalityTags.value.find(tag => tag.id === Number(e.target.value));
        if (seldTonality) {
            setSelectedTonalityTag(seldTonality.id);
        } else {
            setSelectedTonalityTag(0);
        }
        setTags({
            ...tags,
            tagset: {
               ...tags.tagset,
                tonality_id: state.tonalityTags.value.find(tag => tag.id === Number(e.target.value))?.id || null,
            }
        });
    }

    const handleChangeInstrument = (e: SelectChangeEvent) => {
        const seldInstrument = state.instrumentTags.value.find(tag => tag.id === Number(e.target.value));
        if (seldInstrument) {
            setSelectedInstrumentTag(seldInstrument.id);
        } else {
            setSelectedInstrumentTag(0);
        }
        setTags({
            ...tags,
            tagset: {
               ...tags.tagset,
                instrument_id: state.instrumentTags.value.find(tag => tag.id === Number(e.target.value))?.id || null,
            }
        });
    }

    const handleChangeBpm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const seldBpm = Number(e.target.value);
        if (seldBpm && seldBpm >= 0) {
            setSelectedBpm(seldBpm);
        } else {
            setSelectedBpm(0);
        }
        setTags({
            ...tags,
            tagset: {
               ...tags.tagset,
                bpm: seldBpm,
            }
        })
    }

    const handleChangeGenre = (e: SelectChangeEvent) => {
        const seldGenre = state.genreTags.value.find(tag => tag.id === Number(e.target.value));
        if (seldGenre) {
            setSelectedGenreTag(seldGenre.id);
        } else {
            setSelectedGenreTag(0);
        }
        setTags({
            ...tags,
            tagset: {
               ...tags.tagset,
                genre_id: state.genreTags.value.find(tag => tag.id === Number(e.target.value))?.id || null,
            }
        });
    }

    // const handleSelectTag = (chipToSelect: TagStr) => {
    //     // console.log("select: ")
    //     const tagsLength = selectedTags1to5.length;
    //     if (tagsLength >= 5) {
    //         alert("You can't select more than 5 tags!");
    //         return;
    //     } else {
    //         setSelectedTags1to5([...selectedTags1to5, chipToSelect.id]);
    //     }
    // }

    // const handleDeselectTag = (chipToDeselect: number) => {
    //     // console.log("deselect: ");
    //     // console.log(selectedTags1to5);
    //     setSelectedTags1to5(selectedTags1to5.filter(tag => tag !== chipToDeselect));
    // }

    const onSave = () => {
        // let seldTags1To5: TagStr[] = [];
        // selectedTags1to5.forEach(tagId => {
        //     seldTags1To5 = [...seldTags1To5, tagsNumb.find(tag => tag.id === tagId) || tagTypes[0]];
        // });

        state.selectedRowsAllData.value.forEach(id => {
            const file = files.find(file => file.id === id);
            const newValue: MidiFile = {
                id: id,
                fileName: file?.fileName || "",
                fileUrl: file?.fileUrl || "",
                tagset: {
                    key_id: state.keyTags.value.find(key => key.id === tags.tagset.key_id)?.id || null,
                    tonality_id: state.tonalityTags.value.find(tag => tag.id === tags.tagset.tonality_id)?.id || null,
                    instrument_id: state.instrumentTags.value.find(tag => tag.id === tags.tagset.instrument_id)?.id || null,
                    bpm: selectedBpm? selectedBpm : null,
                    genre_id: state.genreTags.value.find(tag => tag.id === tags.tagset.genre_id)?.id || null,
                },
                packet: file ? file.packet : null,
            }
            dispatch(updateValuesFileWav({newValues: newValue}));
        })
        props.onClose();
    }


    return (
        <Dialog onClose={props.onClose} open={props.isOpen} className='modal-dialog'>
            <DialogTitle sx={{textAlign: 'center', px: '100px !important'}}>
                Тегировать новые
            </DialogTitle>

            <DialogContent>
                {/* <Stack direction='row' spacing={"16px"}> */}
                <Stack direction='column' spacing={"16px"}>
                    <Stack spacing={"8px"}>
                        <Typography variant='body1' sx={{color: 'primary.contrastText'}}><b>Тональность</b></Typography>
                        <Stack direction='row' spacing={"16px"}>
                            <TagForm tagList={state.keyTags.value} handleChangeTag={handleChangeKey} selectedTag={selectedKeyTag} nameForm=''/>

                            <TagForm tagList={state.tonalityTags.value} handleChangeTag={handleChangeTonality} selectedTag={selectedTonalityTag} nameForm=''/>
                        </Stack>
                    </Stack>
                    
                    <Stack spacing={"8px"}>
                        <Typography variant='body1' sx={{color: 'primary.contrastText'}}><b>Темп</b></Typography>
                        <TagFormNumber selectedNumber={selectedBpm} handleChangeNumber={handleChangeBpm} name='BPM'/>
                    </Stack>

                    <Stack spacing={"8px"}>
                        <Typography variant='body1' sx={{color: 'primary.contrastText'}}><b>Инструмент</b></Typography>
                        <TagForm tagList={state.instrumentTags.value} handleChangeTag={handleChangeInstrument} selectedTag={selectedInstrumentTag} nameForm=''/>
                    </Stack>
                    
                    <Stack spacing={"8px"}>
                        <Typography variant='body1' sx={{color: 'primary.contrastText'}}><b>Жанр</b></Typography>
                        <TagForm tagList={state.genreTags.value} handleChangeTag={handleChangeGenre} selectedTag={selectedGenreTag} nameForm=''/>
                    </Stack>
                </Stack>
                    {/* <Box>
                        <TagFormChips 
                            nameForm='Tags' 
                            tagList={tagsNumb} 
                            selectedTags={selectedTags1to5} 
                            handleSelectTag={handleSelectTag} 
                            handleDeselectTag={handleDeselectTag}
                            />
                    </Box> */}
                {/* </Stack> */}

            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} variant='outlined'>Отменить</Button>
                <Button onClick={onSave} variant='contained'>Сохранить</Button>
            </DialogActions>

        </Dialog>
    )
}

