import { Stack, Typography } from '@mui/material'
import { AppState } from 'App'
import { AddMidiFileButton } from 'components'
import React, { useContext } from 'react'
import useMelody2Rhithm from 'utils/form/hooks/useMelody2Rhithm'

export function MelodyFileInput() {
    const state = useContext(AppState)
    const {handleChangeMelodyFile} = useMelody2Rhithm()
  return (
    <Stack direction={"column"} spacing={1}>
        <AddMidiFileButton 
            buttonText="Загрузить Мелоди Миди Файл"
            handleFileChange={handleChangeMelodyFile}
        />
        <Typography variant="caption">File: {state.melody2rhitmParams.value.melody_file_name}</Typography>
    </Stack>
  )
}
