import { FormControl, TextField } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useVariationPreset from 'utils/form/hooks/useVariationPreset'

export function NumOutputsInput() {
    const state = useContext(AppState)
    const {handleChangeNumberData} = useVariationPreset()

  return (
    <FormControl>
        <TextField 
            type='number'
            value={state.melodyRNNParams.value.num_outputs}
            onChange={handleChangeNumberData}
            name="num_outputs"
            label="num_outputs"
        />
    </FormControl>
  )
}
