import axios, { AxiosResponse } from "axios";
import { urlBase } from "store/store";
import { TagStr } from "utils/form/hooks/types/tagsTypes";

export async function fetchKeyTags(): Promise<TagStr[] | undefined> {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${urlBase}/tags/keys`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchTonalities() {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${urlBase}/tags/tonalities`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchInstruments() {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${urlBase}/tags/instruments`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchGenres() {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${urlBase}/tags/genres`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}