import { Autocomplete, Button, FormControl, Menu, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { ChangeEvent, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { AppState } from 'App'
// import { ExperimentsState } from 'views/pages/specialPages/ExperimentsPage';
import { PacketObject, PacketObjectRow } from 'utils/form/hooks/types/packetTypes';
import { useSignal, useSignalEffect } from '@preact/signals-react/runtime';
import { FlowParams } from 'utils/form/hooks/types/flowTypes';
import { ArrayInputForm, TagForm, TagFormNumber, TagSwitch } from 'components';
import { useAppSelector } from 'store/store';
import { ALGORITHM, BASE_BASS_PRESET_SETTINGS, BASE_MAGENTA_SETTINGS, EXPLORATION_MODE } from 'utils/constants/constans';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { StartLearningButton } from './generalParamsManage/StartLearningButton';

interface ExperimentsFormProps {
  packets: PacketObject[];
}

export function ExperimentsForm(props: ExperimentsFormProps) {
  const state = useContext(AppState);
  // const experimentsState = useContext(ExperimentsState);

  const selectedParams = useState(state.selectedParams.value);

  const [inputValue, setInputValue] = useState('');

  const sortedPackets = [...props.packets].sort((a, b) => b.id - a.id)

  // const selectedParams = useSignal<FlowParams>(state.selectedParams.value);

  useEffect(() => {
    // setSelectedPacketId(props.packets.length - 1);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      packet_id: props.packets.length
    };
  }, [])

  const handleInputChange = (event: SyntheticEvent) => {
    setInputValue((event.target as HTMLInputElement).value);
  }

  const handleChangePacket = (value: string | null) => {
    // const selPacket = props.packets.find((packet) => packet.id === Number(event.target.value));
    // const selPacket = props.packets.find((packet) => packet.id === Number(value));

    // if (selPacket) {
    if (value) {
      const selPacket = props.packets.find((packet) => packet.id === Number(value));

      state.selectedParams.value = {
        ...state.selectedParams.value,
        packet_id: selPacket ? selPacket.id : props.packets.length
      };
      // setSelectedPacketId(selPacket.id);
    } else {
      state.selectedParams.value = {
        ...state.selectedParams.value,
        packet_id: props.packets.length - 1
      };
      // setSelectedPacketId(props.packets.length - 1);
    }
  }

  const handleChangeAlgorithm = (event: SelectChangeEvent) => {
    const selParam = ALGORITHM.find((algorithm) => algorithm === event.target.value);
    if (selParam) {
      state.selectedParams.value = {
        ...state.selectedParams.value,
        model_settings: {
          ...state.selectedParams.value.model_settings,
          algorithm: selParam
        }
      };
    } else {
      state.selectedParams.value = {
        ...state.selectedParams.value,
        model_settings: {
          ...state.selectedParams.value.model_settings,
          algorithm: ALGORITHM[1]
        }
      };
    }
  }

  const handleChangeExplorationMode = (event: SelectChangeEvent) => {
    const selParam = EXPLORATION_MODE.find((explorationMode) => explorationMode === event.target.value);
    if (selParam) {
      state.selectedParams.value = {
        ...state.selectedParams.value,
        model_settings: {
          ...state.selectedParams.value.model_settings,
          exploration_mode: selParam
        }
      };
    } else {
      state.selectedParams.value = {
        ...state.selectedParams.value,
        model_settings: {
          ...state.selectedParams.value.model_settings,
          exploration_mode: EXPLORATION_MODE[0]
        }
      };
    }
  }

  const handleChangeFirstNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selFirstNote = Number(event.target.value);

    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        first_note: selFirstNote ? selFirstNote : null
      }
    }
  }

  const handleChangeEpochs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selEpochs = Number(event.target.value);
    // if (selEpochs && selEpochs >= 0) {
      state.selectedParams.value = {
        ...state.selectedParams.value,
        epochs: selEpochs
      };
    // } else {
    //   selectedParams.value = {
    //     ...selectedParams.value,
    //     epochs: 100
    //   };
    // }
  }

  const handleChangeNumNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selNumNotes = Number(event.target.value);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        num_notes_in_melody: selNumNotes ? selNumNotes : null
      }
    };
  
  }

  const handleChangeTechTone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selTechTone = Number(event.target.value);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        TECH_TONE: selTechTone ? selTechTone : null
      }
    };
  }

  const handleChangeTransposeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selTransposeAmount = Number(event.target.value);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        transpose_amount: selTransposeAmount ? selTransposeAmount : null
      }
    };
  }

  const handleChangeBpm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selBpm = Number(event.target.value);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        bpm: selBpm ? selBpm : null
      }
    };
  }

  const handleChangeRewardScaler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selRewardScaler = Number(event.target.value);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        reward_scaler: selRewardScaler ? selRewardScaler : null
      }
    };
  }

  const handleChangeEnableRandom = (event: React.ChangeEvent<HTMLInputElement>) => {
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        enable_random: event.target.checked
      }
    };
  }

  const handleChangeStochasticObservations = (event: React.ChangeEvent<HTMLInputElement>) => {
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        stochastic_observations: event.target.checked
      }
    };
  }

  const handleDeleteKey = (index: number) => {
    let new_tech_key = state.selectedParams.value.model_settings.TECH_KEY || [12,14,16,17,19,21,23,24];
    new_tech_key.splice(index, 1);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        TECH_KEY: new_tech_key
      }
    };
  }

  const handleSetNewkey = (newKey: string) => {
    let value = parseInt(newKey);
    if (isNaN(value)) {
      value = 0;
    }
    // let value = isNaN(newKey) ? 0 : parseInt(newKey);
    let newTechKey = state.selectedParams.value.model_settings.TECH_KEY ||  [12,14,16,17,19,21,23,24];
    newTechKey.push(value);
    state.selectedParams.value = {
      ...state.selectedParams.value,
      model_settings: {
        ...state.selectedParams.value.model_settings,
        TECH_KEY: newTechKey
      }
    };
  }

  // const handleResetParams = () => {
  //   state.selectedParams.value = {
  //     ...state.selectedParams.value,
  //     model_settings: BASE_MAGENTA_SETTINGS
  //   }

  //   state.bassPreset.value = {
  //     bass_preset: 0,
  //     range_notes: null,
  //     min_num_notes: null,
  //     min_num_unique_notes: null,
  //     notes_capacity: [],
  //     notes_cutoff: null
  //   }
  // }

  return (
    <Paper sx={{p: 2, width: "100%"}}>
      <Stack direction={'row'} spacing={2}>
        <Stack direction={'column'} spacing={"4px"}>
          {/* базовые параметры */}
          <Stack spacing={"4px"} sx={{minWidth: "200px"}}>

            {/* <Button 
              variant="contained" 
              onClick={handleResetParams}
            >
              Сбросить параметры
            </Button> */}

            <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>Пакет</Typography>
            <FormControl>
              <Autocomplete
                value={state.selectedParams.value.packet_id.toString()}
                // onChange={handleChangePacket}
                onChange={(_, value) => handleChangePacket(value)}
                // inputValue={inputValue}
                // onInputChange={handleInputChange}
                options={sortedPackets.map((packet) => packet.id.toString())}
                renderInput={(params) => <TextField {...params} />}
              />

            </FormControl>
          </Stack>

            <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>Эпохи</Typography>

            <FormControl>
              <OutlinedInput
                // value={selectedParams.value.epochs.toString()}
                value={state.selectedParams.value.epochs === 0 ? "" : state.selectedParams.value.epochs.toString()}
                onChange={handleChangeEpochs}
                inputProps={{
                  type: 'number',
                }}
              />
            </FormControl>

            <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>bpm</Typography>
            <TagFormNumber
              selectedNumber={state.selectedParams.value.model_settings.bpm}
              name=''
              handleChangeNumber={handleChangeBpm}
            />

        </Stack>

        <Stack direction={'column'} spacing={"4px"}>
          {/* параметры для эксперимента */}
            <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>algorithm</Typography>
            <FormControl>
              <Select
                value={state.selectedParams.value.model_settings.algorithm || ""} 
                onChange={handleChangeAlgorithm}
              >
                {
                  ALGORITHM.map((algorithm, index) => (
                    <MenuItem key={index} value={algorithm}> {algorithm}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>num_notes_in_melody</Typography>
            <TagFormNumber 
              selectedNumber={state.selectedParams.value.model_settings.num_notes_in_melody}
              name=''
              handleChangeNumber={handleChangeNumNotes}
            />

            <Tooltip 
              title='first_note + transpose_amount (71 = 52 + 19)'
            >
              <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>
                first_note
                <HelpOutlineIcon fontSize='small' sx={{p: '2px'}}/>
              </Typography>
            </Tooltip>
            <FormControl>
              <OutlinedInput
                value={state.selectedParams.value.model_settings.first_note}
                onChange={handleChangeFirstNote}
                inputProps={{
                  type: 'number',
                }}
              />
            </FormControl>

            <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>enable_random</Typography>
            <TagSwitch 
              nameForm='enable_random'
              checked={state.selectedParams.value.model_settings.enable_random || false}
              handleChange={handleChangeEnableRandom}
            />
        </Stack>

        <Stack direction={'column'} spacing={"4px"}>
          {/* параметры для эксперимента */}
          <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>reward_scaler</Typography>
          <FormControl>
            {/* <OutlinedInput
              value={selectedParams.value.model_settings.reward_scaler || 1.0}
              onChange={handleChangeRewardScaler}
              inputProps={{
                type: 'number'
              }}
            /> */}
            <TextField
              type="number"
              value={state.selectedParams.value.model_settings.reward_scaler}
              onChange={handleChangeRewardScaler}
              InputProps={{
                inputProps: {
                  step: 0.01
                }
              }}
            />
          </FormControl>

          <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>exploration_mode</Typography>
          <FormControl>
              <Select
                value={state.selectedParams.value.model_settings.exploration_mode || ""} 
                onChange={handleChangeExplorationMode}
              >
                {
                  EXPLORATION_MODE.map((em, index) => (
                    <MenuItem key={index} value={em}> {em}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

          <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>stochastic_observations</Typography>
          <TagSwitch
            nameForm='stochastic_observations'
            checked={state.selectedParams.value.model_settings.stochastic_observations || false}
            handleChange={handleChangeStochasticObservations}
          />
        </Stack>

        <Stack direction={'column'} spacing={"4px"}>
          {/* параметры для эксперимента */}
          <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>TECH_TONE</Typography>
          <TagFormNumber
            selectedNumber={state.selectedParams.value.model_settings.TECH_TONE}
            name=''
            handleChangeNumber={handleChangeTechTone}
          />

          <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>TECH_KEY</Typography>
          <ArrayInputForm 
            tech_key={state.selectedParams.value.model_settings.TECH_KEY || []}
            handleDeleteKey={handleDeleteKey}
            handleSetNewkey={handleSetNewkey}
          />

          <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>transpose_amount</Typography>
          <TagFormNumber
            selectedNumber={state.selectedParams.value.model_settings.transpose_amount}
            name=''
            handleChangeNumber={handleChangeTransposeAmount}
          />
        </Stack>

      </Stack>

      {/* <Stack direction={"row"} spacing={"4px"} justifyContent={"flex-end"}> */}
        {/* <ExperimentalButtons selectedParams={state.selectedParams.value}/> */}
        {/* <StartLearningButton/> */}
      {/* </Stack> */}
    </Paper>
  )
}
