import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { WeightsParams } from 'utils/form/hooks/types/configTypes'

interface SavedWeightsTableProps {
    savedWeights: WeightsParams[]
}
export function SavedWeightsTable(props: SavedWeightsTableProps) {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Пресет</TableCell>
            <TableCell align="right">Эпохи</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.savedWeights.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.preset}</TableCell>
              <TableCell align="right">{row.epochs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
