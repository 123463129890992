import { useSignals } from '@preact/signals-react/runtime';
import React, { useContext, useEffect, useState } from 'react'
// import { ExperimentsState } from 'views/pages/specialPages/ExperimentsPage';
import { ResultsTable } from '../ResultsTable';
import { AppState } from 'App';
import { fetchConfigs } from 'api/configs.api';
import useAuth from 'utils/form/hooks/useAuth';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { ConfigOut } from 'utils/form/hooks/types/configTypes';
import { ExperimentsTableRow } from './ExperimentsTableRow';

export function ExperimentsTable() {
  const state = useContext(AppState);
  const {auth, setAuth} = useAuth();


  const sortedResults = [...state.configs.value]
                        .sort((a, b) => b.id - a.id);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getConfigs = async () => {
    state.configs.value = [];

    const response = await fetchConfigs(auth);
    if (!response){
      state.configs.value = [];
      return;
    } 

    state.configs.value = response;
  }

  useEffect(() => {
    try {
      getConfigs();
    } catch (error) {
    }
  }, [])

  return (
    <>
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader size='small'>
          <TableHead>
              <TableRow>
                  {/* <TableCell></TableCell> */}
                  <TableCell align="left">пакет</TableCell>
                  {/* <TableCell align="left">параметры обучения</TableCell> */}
                  <TableCell align="left">параметры</TableCell>
                  <TableCell align="right">результаты</TableCell>
              </TableRow>
          </TableHead>

          <TableBody>
            {
              sortedResults
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((res: ConfigOut, index: number) => (
                <ExperimentsTableRow 
                  key={res.id} 
                  result={res}
                  page={page}
                  rowPerPage={rowsPerPage}
                  index={index}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortedResults.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}
