import { FormControl, TextField } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useVariationPreset from 'utils/form/hooks/useVariationPreset'

export function TemperatureInput() {
  const state = useContext(AppState)
  const {handleChangeFloatData} = useVariationPreset()
  return (
    <FormControl>
      <TextField
        type='number'
        value={state.melodyRNNParams.value.temperature}
        onChange={handleChangeFloatData}
        name='temperature'
        label='temperature'
        InputProps={{
          inputProps: {
            step: 0.01
          }
        }}
      />
    </FormControl>
  )
}
