import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';
import { error } from 'console';
import React, { useContext, useEffect, useState } from 'react'
import { updateValuesFileWav } from 'store/features/fileSlice';
import { urlBase, useAppDispatch, useAppSelector } from 'store/store';
import { Packet } from 'utils/form/hooks/types/types';
import {LoadStatus} from 'utils/form/hooks/enums/loader';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Stack, TextField, Typography } from '@mui/material';
import useAuth from 'utils/form/hooks/useAuth';
import { AppState } from 'App';
import { getAxiosErrorStatus } from 'utils/helpers/axiosErrorStatus.helpers';
import { logout } from 'services/index';
import { upload } from '@testing-library/user-event/dist/upload';
import { CreatedPacketId } from 'utils/form/hooks/types/packetTypes';
import { MidiFile } from 'utils/form/hooks/types/allDataTypes';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    // selectedIds: number[];
}

export function ModalPackageSave(props: ModalProps) {
    const dispatch = useAppDispatch();
    const state = useContext(AppState);
    const {auth, setAuth} = useAuth();


    const files = useAppSelector((state) => state.files.files);
    // const packs = useAppSelector((state) => state.packs.packs);
    
    const [selectedFiles, setSelectedFiles] = useState<MidiFile[]>([]);
    // const [packageName, setPackageName] = useState<string>('');


    useEffect(() => {
        files.forEach((file) => {
            if (state.selectedRowsAllData.value.includes(file.id)) {
                setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
            }
        });
        return () => {
            setSelectedFiles([]);
        }
    }, [files, state.selectedRowsAllData.value]);


    /**
     * Sends a file to the server for upload.
     * 
     * @param file - The file to be sent.
     */
    const sendFile = async (file: MidiFile, dataset_id: number) => {
        const params = {
            // name: file.packet?.name,
            id: dataset_id,
            key_id: file.tagset.key_id,
            instrument_id: file.tagset.instrument_id,
            bpm: file.tagset.bpm,
            genre_id: file.tagset.genre_id,
        }
        const fileResponse = await axios.get<Blob>(file.fileUrl, {responseType: 'blob'});

        const fileBlob =  fileResponse.data;
        const formData = new FormData();
        formData.append('file', fileBlob, file.fileName);

        try{
            // dispatch(fileUpdateMidi({id: file.id, midiUrl: LoadStatus.LOADING}));
            // const response: AxiosResponse<Blob> = await axios.post(`${urlBase}/packages/upload/row/`, formData, {
            //     params,
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         'Content-Disposition': 'attachment; filename=' + file.fileName,
            //     },
            //     responseType: 'blob',
            //     auth: {
            //         username: auth.username,
            //         password: auth.password
            //     }
            // });

            const response: AxiosResponse<string> = await axios.post(`${urlBase}/packages/upload/row/`, formData, {
                params,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'attachment; filename=' + file.fileName,
                },
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            });

            // const resUrl = URL.createObjectURL(response.data);
            // dispatch(fileUpdateMidi({id: file.id, midiUrl: resUrl}));
        } catch (error) {
            // dispatch(fileUpdateMidi({id: file.id, midiUrl: LoadStatus.FAILED}));
            if (axios.isAxiosError(error)) {
                const status = getAxiosErrorStatus(error);

                if (status === 401) {
                    logout();
                    state.isLogin.value = false;
                }
            }
        }
    }
    
    const onSave = async () => {
        try {
            const response: AxiosResponse<CreatedPacketId> = await axios.post(`${urlBase}/packages/dataset/new/`,{}, {
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            });

            state.selectedRowsAllData.value.forEach((fileId) => {
                const updateFile = files.find((file) => file.id === fileId);
                if (updateFile){
                    const newValues: MidiFile = {
                        ...updateFile,
                        packet: response.data.dataset_id || null,
                        // id: fileId,
                        // fileName: updateFile.fileName,
                        // fileUrl: updateFile.fileUrl,
                        // packet: response.data.dataset_id || null,
                        // tagset: updateFile.tagset,
                    }
                    
                    dispatch(updateValuesFileWav({newValues: newValues}));

                    sendFile(newValues, response.data.dataset_id);

                }
            });
        } catch (error) {
            console.log(error);
        }
        props.onClose();
    }    

    return (
        <Dialog onClose={props.onClose} open={props.isOpen} className='modal-dialog'>
            <DialogTitle sx={{textAlign: 'center'}}>
                Сохранить выбранные треки в пакет?
            </DialogTitle>

            <DialogContent>
                <Stack direction="column" spacing={2}>
                    {/* <Box sx={{pt: "16px"}}>
                        <TextField fullWidth label="Название пакета" value={packageName} onChange={(e) => setPackageName(e.target.value)} />
                    </Box> */}
                    <Box sx={{maxHeight: "300px", overflow: "auto"}}>
                        <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>Выбранные файлы</Typography>
                        <List>
                            {
                                selectedFiles.map((file) => (
                                    <ListItem key={file.id}>{file.fileName}</ListItem>
                                ))
                            }
                        </List>
                    </Box>
                </Stack>
            </DialogContent>

            <DialogActions sx={{px: "24px", pb: "24px"}}>
                <Stack direction={"row"} spacing={"16px"}>
                    <Button variant='outlined' onClick={props.onClose}>Отменить</Button>
                    <Button variant='contained' onClick={onSave}>Сохранить</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
