import axios, { AxiosResponse } from "axios";
import { urlBase } from "store/store";

export const fetchInstrumentsPacketPage = async () => {
    try {
        const response: AxiosResponse<[string | null, number][]> = await axios.get(`${urlBase}/results/instruments`);
        return response.data;
    } catch {
        console.log("Не удалось загрузить инструменты, код ошибки: ");
    }
}