import {Components} from "@mui/material";
import { MuiButton } from "./button";
import { MuiTab } from "./tab";
import { MuiTabs } from "./tabs";
import { MuiContainer } from "./container";
import { MuiTableHead } from "./table/tableHead";
import { MuiTable } from "./table/table";
import { MuiTableContainer } from "./table/tableContainer";
import { MuiTableBody } from "./table/tableBody";
import { MuiTableCell } from "./table/tableCell";
import { MuiPopover } from "./popover";
import { MuiDialog } from "./dialog";
import { MuiTableRow } from "./table/tableRow";

export const components = {
    MuiButton,
    MuiTab,
    MuiTabs,
    // MuiContainer
    MuiTableHead,
    MuiTable,
    MuiTableContainer,
    MuiTableBody,
    MuiTableCell,
    MuiPopover,
    MuiDialog,
    MuiTableRow,
};

// export const test: Components = {
//     MuiTableRow
// }