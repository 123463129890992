import { Stack } from '@mui/material'
import React from 'react'


type GeneralActionsProps = {
    children?: React.ReactNode
}
export function GeneralActions(
    {children}: GeneralActionsProps
) {
  return (
    <Stack direction="row" justifyContent={"space-between"} alignItems={"start"}>
        {children}
    </Stack>
  )
}
