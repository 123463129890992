import { ResultsTable } from 'views'
import React, { useContext, useEffect, useState } from 'react'
import { AppState } from 'App';
import useAuth from 'utils/form/hooks/useAuth';
import { fetchConfigs } from 'api/configs.api';
import axios from 'axios';
import { getAxiosErrorStatus } from 'utils/helpers/axiosErrorStatus.helpers';
import { logout } from 'services/Auth/Authorization.service';
import { config } from 'process';
import { ConfigOut } from 'utils/form/hooks/types/configTypes';
import { getRatingByConfigId } from 'api/rating.api';
import { useAppDispatch } from 'store/store';
import { fetchPackageObjects } from 'store/features/packageObjectSlice';

export function ResultsPage() {
  const state = useContext(AppState);
  const dispatch = useAppDispatch();
  const {auth, setAuth} = useAuth();

  const [configsR, setConfigsR] = useState<ConfigOut[]>([]);


  const getMetaInfoByConfig = async (config_id: number) => {
    // получать наборы тегов для конфига по packet_id, params_id
  }

  const getConfigWithRating = async (config: ConfigOut) => {
    const rating = await getRatingByConfigId(config.id) || 0;
    config.rating = Number(rating);
    state.configs.value = [...state.configs.value, config];
  }

  const getConfigs = async () => {
    state.configs.value = [];

    const response = await fetchConfigs(auth);
    if (!response){
      state.configs.value = [];
      return;
    } 

    state.configs.value = response;

  }

  useEffect(() => {
    try{
      dispatch(fetchPackageObjects({username: auth.username, password: auth.password}));
      getConfigs();
    } catch (error) {
      state.configs.value = [];
        if (axios.isAxiosError(error)) {
            const status = getAxiosErrorStatus(error);
            if (status === 401) {
              logout();
              state.isLogin.value = false;
            }
        }
    }
  }, []); 
      // getConfigs();
  
  return (
    <>
      <ResultsTable/>
    </>
  )
}


