export enum Algorithm {
    DEFAULT = "default",
    PSI = "psi",
    G = "g",
    PURE_RL = "pure_rl"
}

export enum ExplorationMode {
    EGREEDY = "egreedy",
    BOLTZMANN = "boltzmann"
}