import { Box, Collapse, Divider, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import { ConfigOut } from 'utils/form/hooks/types/configTypes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AllMidiByConfig, DownloadConfigArchive, GradeContainer } from 'components/index';
import { ResultRowTags } from './resultTable/ResultRowTags';
import { ResultRowTagsList } from './resultTable/ResultRowTagsList';
import { RatingVariant } from 'utils/form/hooks/enums/rating';
import { PacketObjectRow } from 'utils/form/hooks/types/packetTypes';
import { useAppSelector } from 'store/store';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';

interface ResultRowProps {
    config: ConfigOut
}

export function ResultRow(props: ResultRowProps) {
    const [openCollapse, setOpenCollapse] = React.useState(false);
    
    const packets = useAppSelector(state => state.packageObjects.packages);
    const [rows, setRows] = useState<PacketObjectRow[]>([]);

    const onOpen = () => {
      if (openCollapse) {
        setRows([]);
      } else {
        fetchAllFiles();
      }

      setOpenCollapse(!openCollapse);

    } 

    const fetchAllFiles = async () => {
      // TODO: подгрузить данные из пакета
      setRows(packets.find(p => p.id === props.config.datasets_id)?.rows || []);
    }

  return (
    <React.Fragment>
        <TableRow
            key={props.config.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            // onClick={() => setOpenCollapse(!openCollapse)}
            hover
        >
            <TableCell 
              onClick={onOpen}
            >
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={onOpen}
                >
                    {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell 
              align="left"
              onClick={onOpen}
            >
              {props.config.datasets_id}
            </TableCell>

            <TableCell 
              align="left"
              onClick={onOpen}
            >
              Количество эпох: {props.config.epochs}
            </TableCell>
            
            <TableCell align="left">
              <GradeContainer 
                gen_params={props.config}
              />
            </TableCell>
            <TableCell align="right">
                {/* <AllMidiByConfig 
                  packet_id={props.config.datasets_id} 
                  params_id={props.config.params_id}
                  config_id={props.config.id}
                  type={TypeMidi.STANDART}
                /> */}
                <DownloadConfigArchive
                  config_id={props.config.id}
                  type={TypeMidi.STANDART}
                />
            </TableCell>
        </TableRow>

        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} sx={{backgroundColor: 'secondary.light'}}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ResultRowTagsList packet_id={props.config.datasets_id} rows={rows} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
