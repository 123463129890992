import { Download } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { urlBase } from 'store/store';
import useAuth from 'utils/form/hooks/useAuth';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';
import { fetchArchiveAll } from 'api/results.api';


interface DownloadArchiveAllButtonProps {
    packet_id: number,
    type: TypeMidi
}

export function DownloadArchiveAllButton(props: DownloadArchiveAllButtonProps) {
    const {auth, setAuth} = useAuth();

    const handleDownload = async () => {
        try {
            // const response = await axios.get(`${urlBase}/results/archive/all/${props.type}/${props.packet_id}`, {
            //     responseType: 'arraybuffer',
            //     auth: {
            //         username: auth.username,
            //         password: auth.password
            //     }
            // });
            const response = await fetchArchiveAll({auth, type: props.type, obj_id: props.packet_id})
            const resUrl = URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = resUrl;
            link.setAttribute('download', `package_${props.packet_id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(resUrl);
        } catch (error) {
          console.error(error); 
        }
    }

  return (
    <Tooltip title="Скачать архив" placement="left">
        <Button variant='contained' onClick={handleDownload}>
            package_{props.packet_id}.zip
            <DownloadIcon/>
        </Button>
    </Tooltip>
  )
}
