import { configureStore } from "@reduxjs/toolkit";
// import { jenresSlice } from "./features/jenreSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {FileSlice} from "./features/fileSlice";
// import { SubjenreSlice } from "./features/subjenreSlice";
// import { InstrumentSlice } from "./features/instrumentSlice";
// import { RhythmicPatternSlice } from "./features/rhythmicPatternSlice";
// import { PartComposeSlice } from "./features/partComposeSlice";
// import { PackageSlice } from "./features/packageSlice";
import { PackageObjectSlice } from "./features/packageObjectSlice";
import { WavPackObjSlice } from "./features/wavPackObjSlice";
import { MidiForPacketSlice } from "./features/midiForPacketSlice";

export const urlBase = process.env.REACT_APP_BACKEND_API_URL;

export const store = configureStore({
    reducer: {
        // jenres: jenresSlice.reducer,
        files: FileSlice.reducer,
        // subjenres: SubjenreSlice.reducer,
        // instruments: InstrumentSlice.reducer,
        // rhythmicPattern: RhythmicPatternSlice.reducer,
        // partsCompose: PartComposeSlice.reducer,
        // packs: PackageSlice.reducer,
        packageObjects: PackageObjectSlice.reducer,
        wavPackObjects: WavPackObjSlice.reducer,
        midisForPacket: MidiForPacketSlice.reducer
    }
})


export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch:() => typeof store.dispatch=useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector