import React from 'react'

export function FileTaggingPage() {
  return (
    <>
      file
    </>
  )
}

