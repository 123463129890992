import { Tab, Tabs, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import { Router } from 'utils/routes/Routes'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import {menuTexts} from 'utils/form/texts/tooltips'


function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      // return possibleMatch;
      return i + 1;
    }
  }

  return null;
}



export function NavTabs() {
    const routeMatch = useRouteMatch([Router.Main, Router.Packages, Router.Results]);
    // const currentTab = routeMatch?.pattern?.path;
    const currentTab = routeMatch;

    return (
      <Tabs value={currentTab ? currentTab - 1 : false}>
          <Tooltip title={menuTexts.alldata}><Tab label="Все данные" value={Router.Main} to={Router.Main} component={RouterLink}/></Tooltip>
          <Tooltip title={menuTexts.packages}><Tab label="Пакеты" value={Router.Packages} to={Router.Packages} component={RouterLink}/></Tooltip>
          <Tooltip title={menuTexts.results}><Tab label="Результаты" value={Router.Results} to={Router.Results} component={RouterLink}/></Tooltip>
          <Tab label="Experiments*" value={Router.Experiments} to={Router.Experiments} component={RouterLink}/>
      </Tabs>
    )
}
