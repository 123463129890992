import { Button, Stack, Typography } from '@mui/material'
import React, { SyntheticEvent, useContext, useState } from 'react'
import StarIcon from '@mui/icons-material/Star';
import { GradePopover } from './GradePopover';
// import { RatingVariant } from 'utils/form/hooks/enums/rating';
import { getRatingByConfigId, setRatingGeneration } from 'api/rating.api';
// import { WavFile } from 'utils/form/hooks/types/types';
import { ConfigOut } from 'utils/form/hooks/types/configTypes';
import useAuth from 'utils/form/hooks/useAuth';
import { AppState } from 'App';
import { useAppDispatch } from 'store/store';
// import { MidiFile } from 'utils/form/hooks/types/allDataTypes';
// import { updateRatingConversion } from 'store/features/fileSlice';

interface GradeContainerProps {
  // variant: RatingVariant,
  // conv_params: MidiFile | null,
  // gen_params: ConfigOut | null,
  gen_params: ConfigOut,
}

export function GradeContainer(props: GradeContainerProps) {
    const state = useContext(AppState);
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    // const [gradeNumber, setGradeNumber] = useState(0);
    // const gradeNumber = props.variant === RatingVariant.GENERATION ? props.gen_params?.rating;
    const gradeNumber = props.gen_params?.rating;
    const {auth, setAuth} = useAuth();
    
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const changeRatingGeneration = (newRating: number) => {
      // if (props.gen_params !== null) {
      let configs: ConfigOut[] = state.configs.value;
      configs.forEach((config) => {
        if (config.datasets_id === props.gen_params?.datasets_id && config.params_id === props.gen_params?.params_id) {
          config.rating = newRating;
        }
      });

      state.configs.value = configs;
      // }     
    }

    // const changeRatingConversion = (newRating: number) => {
    //   if (props.conv_params !== null) {
    //     dispatch(updateRatingConversion({id: props.conv_params.id, rating: newRating}));
    //   }
    // }

    const setRating = async (rating: number) => {
      // switch (props.variant) {
      //   case RatingVariant.CONVERSION:
      //     if (props.conv_params !== null) {
      //       const result = await setRatingConversion(props.conv_params, rating, auth);
      //       changeRatingConversion(result?.rating || 0);
      //       return result;
      //     }
      //     break;
      //   case RatingVariant.GENERATION:
      //     if (props.gen_params !== null) {
      //       const result = await setRatingGeneration(props.gen_params, rating, auth);
      //       if (result?.status === 'done') {
      //         const newRating = await getRatingByConfigId(props.gen_params.id) || 0;
      //         changeRatingGeneration(Number(newRating));
      //       }
      //       return result;
      //     }
      //     break;
      //   default:
      //     break;
      // }
      const result = await setRatingGeneration(props.gen_params, rating, auth);
      // if (result?.status === 'done') {
      //   const newRating = await getRatingByConfigId(props.gen_params.id) || 0;
      //   changeRatingGeneration(Number(newRating));
      // }
      return result;
    }

    const changeGradeNumber = async (event: SyntheticEvent<Element, Event>, newValue: number | null) => {
      if (newValue!== null) {
        try {
          const rating = await setRating(newValue);
        } catch (error) {
          console.log(error);
        }
      }
      handlePopoverClose();
    }

  return (
    <React.Fragment>
        <Button variant='text' onClick={handlePopoverOpen}>
            <Stack direction="row" spacing={'16px'} alignItems="center">
                <StarIcon fontSize='small'/>
                <Typography>{gradeNumber || null}</Typography>
            </Stack>
        </Button>

        <GradePopover 
            onClose={handlePopoverClose} 
            gradeNumber={gradeNumber || 0} 
            onChangeGrade={changeGradeNumber}
            anchorEl={anchorEl} 
            open={open} 
        />
    </React.Fragment>
  )
}
