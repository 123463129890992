import { Button } from '@mui/material';
import { PlayerElement } from 'html-midi-player';
import React, { useEffect, useRef, useState } from 'react'

interface MidiPlayerWrapperProps {
    src: string;
}
  
// export function MidiPlayerWrapper(props: MidiPlayerWrapperProps) {
//     const playerRef = useRef<HTMLDivElement | null>(null);

//     const [isPlay, setIsPlay] = useState(false);

//   const handleButtonClick = () => {
//     try {
//       if (playerRef.current) {
//         const player = new PlayerElement();
//         player.soundFont = '';
//         player.src = props.src;
//         playerRef.current.appendChild(player);
//       }
//       setIsPlay(true);
//     } catch (error) {
//       console.error(error);
//     }
//   }


//   useEffect(() => {
//     const handleClick = () => {
//       const context = new AudioContext();
//     }
//     document.addEventListener('click', handleClick);
//     return () => {
//       document.removeEventListener('click', handleClick);
//     }
//   }, []);

//   return (
//     <div>
//         {
//             !isPlay ? <Button variant="outlined" onClick={handleButtonClick}>Play</Button> : <></>
//         }
//       <div ref={playerRef}></div>
//     </div>
//   )

// }

export function MidiPlayerWrapper(props: MidiPlayerWrapperProps) {
  const playerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const player = new PlayerElement();
    player.src = props.src;
    playerRef.current?.appendChild(player);

    return () => {
      playerRef.current?.removeChild(player);
    }
  }, [props.src]);

  return (
    <div ref={playerRef}></div>
  )
}

