import { AppState } from "App"
import { useContext } from "react"
import useAuth, { Auth } from "./useAuth";
import { SelectChangeEvent } from "@mui/material";
import { getSynthX, updateSynthX } from "api/synth.api";
import { TagStr } from "./types/tagsTypes";
import { BASS_PRESET, VARIATIONS } from "utils/constants/constans";
import { SynthX, SynthXMidiFile } from "./types/synthTypes";

export default function useSynthX() {
    const state = useContext(AppState)
    const {auth} = useAuth();


    const initSynthX = async (auth: Auth) => {
        const response = await getSynthX(auth);

        if (typeof response !== 'string') {
            state.synthX.value = {
                category: response.category,
                packet_id: response.packet_id,
                variation: VARIATIONS.find(tag => tag.name === response.variation)!.name || VARIATIONS[0].name,
                midi_data_id: response.midi_data_id,
                epochs: response.epochs,
                id: response.id,
                bass_preset: response.bass_preset
            }
        } else {
            console.log(response)
        }
    }

    const handleChangePacketId = (event: SelectChangeEvent) => {
        const selTag = state.packets.value.find(tag => tag.id === Number(event.target.value))
        if (selTag) {
            state.synthX.value = {
                ...state.synthX.value,
                packet_id: selTag.id
            }
        }
    }

    const handelChangeEpochs = (event: React.ChangeEvent<HTMLInputElement>) => {
        state.synthX.value = {
            ...state.synthX.value,
            epochs: Number(event.target.value) || null
        }
    }

    const handleChangeBassPreset = (event: SelectChangeEvent) => {
        const selTag = BASS_PRESET.find(tag => tag.name === event.target.value)
        if (selTag) {
            state.synthX.value = {
                ...state.synthX.value,
                bass_preset: selTag.name
            }
        }
    }

    const handleChangeVariation = (event: SelectChangeEvent) => {
        const selTag = VARIATIONS.find(tag => tag.name === event.target.value)
        if (selTag) {
            state.synthX.value = {
                ...state.synthX.value,
                variation: selTag.name
            }
        }
    }

    const handleChangeMidiFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUrl = URL.createObjectURL(e.target.files[0])
            state.synthXMidiFile.value = {
                ...state.synthXMidiFile.value,
                url: fileUrl,
                filename: e.target.files[0].name
            }
        }
    }

    const sendSynthX = async () => {
        const synthX = state.synthX.value
        const synthXMidiFile = state.synthXMidiFile.value

        const response = await updateSynthX(auth, synthX, synthXMidiFile)

        if (typeof response !== 'string') {
            state.synthX.value = {
                ...state.synthX.value,
                epochs: response.epochs,
                packet_id: response.packet_id,
                bass_preset: response.bass_preset,
                variation: response.variation,
            }
        }
    }

    return {
        initSynthX,
        handleChangePacketId,
        handelChangeEpochs,
        handleChangeBassPreset,
        handleChangeVariation,
        handleChangeMidiFile,
        sendSynthX
    }
}

