import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiTableHead = {
    defaultProps?: ComponentsProps['MuiTableHead'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTableHead'];
    variants?: ComponentsVariants['MuiTableHead'];
}

export const MuiTableHead: muiTableHead = {
    styleOverrides: {
        root: {
            // borderRadius: "10px",
            // padding: "5px 20px",
            backgroundColor: colors.commonWhite,
            // textTransform: "none",

        },
    },
}