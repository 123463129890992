import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'

interface TagSwitchProps {
    checked: boolean,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    nameForm: string,
}
export function TagSwitch(props: TagSwitchProps) {
  return (
    <FormControlLabel control={
        <Switch
            checked={props.checked}
            onChange={props.handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    } label={props.nameForm}/>
  )
}
