import { Stack } from '@mui/material'
import React from 'react'

type SynthXFormProps = {
    children?: React.ReactNode
}
export function SynthXForm(
    {children}: SynthXFormProps
) {
  return (
    <Stack direction={"row"} spacing={2}>
      {children}
    </Stack>
  )
}
