import { PaletteColorOptions, PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  export interface PaletteOptions {
    blue?: PaletteColorOptions;
  }
}

export const colors = {
  commonWhite: "#fff",
  commonBlack: "#2D264B",

  primaryMain: "#7779F5",
  primaryLight: "#7779F530",
  // primaryDark: "",
  primaryContrastText: "#2D264B",
  backgroundPaper: "#FFFFFF",
  backgroundDefault: "#F5F7F9",
  secondaryText: "#7A7B7D",
  secondaryLight: "#F7F7FC",
}

export const palette: PaletteOptions = {
  common: {
      white: colors.commonWhite,
      black: colors.commonBlack,
  },
  
  primary: {
      main: colors.primaryMain,
      light: colors.primaryLight,
      contrastText: colors.primaryContrastText,
  },

  secondary: {
    main: "#1E88E5",
    light: colors.secondaryLight,
    contrastText: colors.secondaryText,
  },
  
  blue: {
      100: "#D1E9FF",
  },

  warning: {
    main: "#FFB800",
    light: "#FFB80066",
  },

  background: {
    paper: colors.backgroundPaper,
    default: colors.backgroundDefault,
  }
}