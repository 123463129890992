import {ButtonsPackages, PacketTableRow} from 'views/index';
import {TooltipIcon, Loader, StatisticBox, SearchBaseFilter, GradeContainer} from 'components/index';

import React, { useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store';
import { Instrument, PacketObject } from 'utils/form/hooks/types/packetTypes';
import {packagesTexts} from 'utils/form/texts/tooltips'
import { fetchPackageObjects } from 'store/features/packageObjectSlice';
import { LoadStatus } from 'utils/form/hooks/enums/loader';


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import TablePagination from '@mui/material/TablePagination';
import MuiInput from '@mui/material/Input';
import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Slider, Stack, Typography } from '@mui/material';

import { AppState } from 'App';
import useAuth from 'utils/form/hooks/useAuth';
import axios from 'axios';
import { getAxiosErrorStatus } from 'utils/helpers/axiosErrorStatus.helpers';

import {FOLLOWERS_MARKS, MAX_EPOCHS, MIN_EPOCHS} from 'utils/constants/constans';
import { fetchInstrumentsPacketPage } from 'api';

function numFormatter(num: number) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    }  else if (num < 1000) {
        return num; // if value < 1000, nothing to do
    }
}

function calculateValue(value: number) {
    if (value === 0) {
        return 100;
    }
    return value * 100;
}

export function PackagesTable() {
    const state = useContext(AppState); // flowResponses
    const {auth, setAuth} = useAuth();

    const dispatch = useAppDispatch();

    const packageObjs = useAppSelector((st) => st.packageObjects.packages);

    const [selectedAll, setSelectedAll] = useState(false);
    // const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [epochs, setEpochs] = useState(0);
    const [scaledEpochs, setScaledEpochs] = useState(100);

    const [selectedInstrument, setSelectedInstrument] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const sortedPackageObjs = [...packageObjs]
                                    .filter(packet => (
                                        (searchTerm === '' || packet.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                    ))
                                    .sort((a, b) => b.id - a.id);

    const getInstruments = async () => {
        const result: [string | null, number][] | undefined = await fetchInstrumentsPacketPage();
        // const result: [string | null, number][] | undefined = MOCK_INSTRUMENTS;
        if (Array.isArray(result)) {
            let instruments: Instrument[] = [];
            result.forEach((item) => {
                instruments = [...instruments, {
                    id: item[1],
                    name: item[0] || ''
                }]
            })
            state.instrumentsPacketPage.value = instruments;
        } else {
            state.instrumentsPacketPage.value = [];
            console.log("Ошибка получения инструментов");
        }
    }

    useEffect(() => {
        try{
            dispatch(fetchPackageObjects({username: auth.username, password: auth.password}));
            getInstruments();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const status = getAxiosErrorStatus(error);

                if (status === 401) {
                    state.isLogin.value = false;
                }
            }
        }
    }, []);

    const [openedPackage, setOpenedPackage] = useState<PacketObject>();

    const chooseAll = () => {
        if (selectedAll === true) {
            // Deselect all files
            state.selectedRowsPackets.value = [];
            setSelectedAll(false);
          } else {
            let allIds: number[] = [];
            packageObjs.forEach((pack) => {
                allIds = [...allIds, pack.id];
            });
            state.selectedRowsPackets.value = allIds;
            setSelectedAll(true);
          }
    }

    const handleCheckboxChange = (id: number) => {
        // setSelectedRows( (prewSelectedRows) => {
        //     if (prewSelectedRows.includes(id)) {
        //       setSelectedAll(false);

        //       return prewSelectedRows.filter((rowId) => rowId !== id);
        //     } else {
        //       return [...prewSelectedRows, id];
        //     }
        //   }
        // );

        if (state.selectedRowsPackets.value.includes(id)) {
            setSelectedAll(false);
            state.selectedRowsPackets.value = state.selectedRowsPackets.value.filter((rowId) => rowId !== id);
            return state.selectedRowsPackets.value;
        } else {
            state.selectedRowsPackets.value = [...state.selectedRowsPackets.value, id];
            return state.selectedRowsPackets.value;
        }
    }

    // const handlePackChange = (pack: PacketObject) => {
    //     setOpenedPackage(pack);
    //     createInfoPackArray(pack);
    // }

    // const createInfoPackArray = (pack: PacketObject) => {
    //     let result = [
    //         "name: " + pack.name,
    //         "path: " + pack.path,
    //         "midi_in: " + pack.midi_in,
    //         "size: " + pack.size,
    //         "genre: " + pack.genre,
    //         "subgenre: " + pack.subgenre,
    //         "tone: " + pack.tone,
    //         "bpm: " + pack.bpm,
    //         "lenght: " + pack.lenght,
    //         "stage: " + pack.stage,
    //         "id: " + pack.id ]

    //     result.push("name: " + pack.name);
    //     setInfoPack(result);
    // }

    /**
     * Обрабатывает изменение страницы в пагинируемом компоненте.
     * @param event - Объект события.
     * @param newPage - Новый номер страницы.
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    /**
     * Обрабатывает изменение количества строк на странице.
     * @param {React.ChangeEvent<HTMLInputElement>} event - Объект события.
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSliderEpochsChange = (event: Event, newValue: number | number[]) => {
        setEpochs(newValue as number);
        setScaledEpochs(calculateValue(newValue as number));
    }

    const handleInputEpochsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEpochs(Number(event.target.value) / 100);
        setScaledEpochs(Number(event.target.value));
    }

    const handleInstrumentChange = (event: SelectChangeEvent) => {
        const instrument = state.instrumentsPacketPage.value.find(i => i.name === event.target.value);
        if (instrument) {
            setSelectedInstrument(instrument.id);
        } else {
            setSelectedInstrument(0);
        }

        // console.log(state.lastFlowResults.value);
    }



    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    return (
        <Stack direction="column" spacing={"24px"} sx={{mt: "0px !important"}}> 
        <Paper sx={{ width: '100%', p: "24px", borderRadius: "24px", my: "0px" }} elevation={0}>
            <Stack direction={"column"} spacing={"48px"} justifyContent={"space-around"}>
                
                {/* <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange}></SearchFilter> */}
                <SearchBaseFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>

                <Box>
                    <TableContainer sx={{ height: '100%' }}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="left">Пакет&nbsp;<TooltipIcon text={packagesTexts["package"]}/></TableCell>
                                    <TableCell align="left">Дата/время начала обучения&nbsp;<TooltipIcon text={packagesTexts["start"]}/></TableCell>
                                    <TableCell align="left">Дата/время окончания обучения <TooltipIcon text={packagesTexts["end"]}/></TableCell>
                                    <TableCell align="left">input midi</TableCell>
                                    <TableCell align="left" style={{minWidth: 250}}>Midi</TableCell>
                                    <TableCell>
                                        <input 
                                        type="checkbox"
                                        checked={selectedAll}
                                        onChange={chooseAll}
                                        />
                                    </TableCell>
                                </TableRow>

                            </TableHead>

                            <TableBody>
                                {
                                    sortedPackageObjs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((pack) => (
                                        <PacketTableRow 
                                            key={pack.id} 
                                            packet={pack} 
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={packageObjs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Stack>
        </Paper>

        <ButtonsPackages selectedIds={state.selectedRowsPackets.value} epochs={scaledEpochs} instrument_id={selectedInstrument}/>

        <Grid container spacing={2} sx={{pb: "36px"}}>
            <Grid item xs={5}>
                <Stack direction={"column"} spacing={"8px"}>
                    <Typography variant='h6'>Параметры обучения</Typography>

                    <FormControl sx={{width: '100%'}}>
                        <Paper sx={{px: "8px", py: "4px", borderRadius: "8px"}} elevation={0}>
                            <Stack direction={"column"} spacing={"8px"} sx={{pb: "48px"}}>
                                <Typography id="input-slider" gutterBottom>
                                    Количество эпох
                                </Typography>

                                <OutlinedInput
                                    value={scaledEpochs}
                                    onChange={handleInputEpochsChange}
                                    inputProps={{
                                        step: 100,
                                        min: MIN_EPOCHS,
                                        max: MAX_EPOCHS,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />

                                <Slider
                                    sx={{mb: "24px"}}
                                    value={epochs}
                                    min={0}
                                    step={1}
                                    max={100}
                                    scale={calculateValue}
                                    valueLabelFormat={numFormatter}
                                    marks={FOLLOWERS_MARKS}
                                    onChange={handleSliderEpochsChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="non-linear-slider"
                                />

                            </Stack>

                            <Stack>
                                <Typography gutterBottom>
                                    Выбор инструмента
                                </Typography>


                                <FormControl>
                                    <InputLabel id="select-instrument-label">Инструмент</InputLabel>
                                    <Select
                                        id="simple-select-helper"
                                        onChange={handleInstrumentChange}
                                        label="Инструмент"
                                        value={state.instrumentsPacketPage.value.find((instrument) => instrument.id === selectedInstrument)?.name || ""}
                                    > 
                                        {
                                            state.instrumentsPacketPage.value.map((instrument) => (
                                                <MenuItem key={instrument.id} value={instrument.name}>{instrument.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Paper>
                    </FormControl>
                </Stack>
            </Grid>

            <Grid item xs={7}>
                {/* <Box>packet_id: {openedPackage?.id}</Box> */}
                <StatisticBox packet_info={openedPackage?.id || 0} stat={state.lastFlowResults.value.find((flow) => flow.packet_id === openedPackage?.id) || null}/>
            </Grid>
        </Grid>
        </Stack>
    )
}

