import { Button } from '@mui/material'
import React from 'react'
import useSynthX from 'utils/form/hooks/useSynthX'

export function SynthXUpdateButton() {

    const {sendSynthX} = useSynthX()

  return (
    <Button variant='contained' onClick={sendSynthX}>Обновить</Button>
  )
}
