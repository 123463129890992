import { Auth } from "utils/form/hooks/useAuth";
import { errorCatch } from "./error";
import axios, { AxiosResponse } from "axios";
import { SynthX, SynthXMidiFile } from "utils/form/hooks/types/synthTypes";

export const getSynthX = async (auth: Auth) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_API_URL}/tags/synth_x`, {
            auth: {
                username: auth.username,
                password: auth.password
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = errorCatch(error);
        return errorMessage
    }
}

export const updateSynthX = async (auth: Auth, synthX: SynthX, synthXMidiFile: SynthXMidiFile) => {
    try {
        const formData = new FormData();
        let fileMidiBlob = new Blob();

        try{
            const fileMidi = await axios.get<Blob>(synthXMidiFile.url, {
                responseType: 'blob',
            })
            fileMidiBlob = fileMidi.data    

        } catch (error) {

        }


        formData.append('midi_file', fileMidiBlob, synthXMidiFile.filename);

        const response: AxiosResponse<SynthX> = await axios.post(
            `${process.env.REACT_APP_BACKEND_API_URL}/tags/synth_x/${synthX.packet_id}/${synthX.epochs || 200}/${synthX.bass_preset}/${synthX.variation}`,
            formData, {
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            }
        )
        return response.data
    } catch (error) {
        const errorMessage = errorCatch(error);
        return errorMessage
    }

}