import { AppState } from "App";
import { useContext } from "react";
import useAuth, { Auth } from "./useAuth";
import { cancelSavingWeights, changeConfigRating, deleteWeights, fetchConfigs, getSavedWeights, saveWeights } from "api/configs.api";
import { ConfigOut } from "./types/configTypes";
import { config } from "process";
import { NonNullChain } from "typescript";
import { RatingIn } from "./types/ratingTypes";

export default function useConfig() {
    const state = useContext(AppState);
    const {auth} = useAuth();
    
    const getConfigs = async (auth: Auth) => {
        state.configs.value = [];
    
        const response = await fetchConfigs(auth);
        if (!response){
          state.configs.value = [];
          return;
        } 
    
        state.configs.value = response;
      }
    
    const handleDeleteWeights = async (
        {config_id, auth}: { config_id: number, auth: Auth }
    ) => {
        const response: boolean = await deleteWeights(auth, config_id)
        if (response){
            await getConfigs(auth);
        }
    }

    const handleSaveWeights = async (
        config_id: number, auth: Auth
    ) => {
        const response: ConfigOut | boolean = await saveWeights(auth, config_id)
        if (typeof response !== 'boolean'){
            const updatedConfigs = state.configs.value.map(c => {
                if(c.id === response.id){
                return response;
              }
              return c;
            });
            state.configs.value = updatedConfigs
        }
    }
    const handleCancelSavingOfWeights = async (
        config_id: number, auth: Auth
    ) => {
        const response: ConfigOut | boolean = await cancelSavingWeights(auth, config_id)
        if (typeof response !== 'boolean'){
            const updatedConfigs = state.configs.value.map(c => {
                if(c.id === response.id){
                return response;
              }
              return c;
            });
            state.configs.value = updatedConfigs
        }
    }

    const handleAddRating = async (
        event: React.SyntheticEvent,
        newValue: number | null,
        config_id: number
    ) => {
        if (newValue === null){
            return
        }
        const newRating = await changeConfigRating(auth, config_id, newValue)

        if (!newRating){
            return
        }
        
        state.configs.value = state.configs.value.map(c => {
            if(c.id === config_id){
                c.rating = newRating.rating_value
            }
            return c;
        })
    }

    const fetchSavedWeights = async (
        auth: Auth
    ) => {
        const response = await getSavedWeights(auth);
        if (!response){
          return [];
        }

        return response;
    }

    return {
        handleDeleteWeights,
        handleSaveWeights,
        handleCancelSavingOfWeights,
        handleAddRating,
        fetchSavedWeights,
    }
}

