import { FormControl, Input, InputAdornment, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
interface TimeBlockProps {
    config_id: number
}
export function TimeBlock(props: TimeBlockProps) {
    const state = useContext(AppState)
  return (
    <Stack direction={"column"} spacing={2} sx={{width: '30%'}}>
        <FormControl variant="standard" disabled>
            <InputLabel htmlFor="avg_bass_time_sec">
                Bass time
            </InputLabel>
            <Input
                id="avg_bass_time_sec"
                endAdornment={
                    <InputAdornment position="end">
                        <Typography>ms</Typography>
                    </InputAdornment>
                }
                value={state.configs.value.find(c => c.id === props.config_id)?.avg_bass_time || "–"}
            />
        </FormControl>

        <FormControl variant="standard" disabled>
            <InputLabel htmlFor="avg_synth_time_sec">
                Synth time
            </InputLabel>
            <Input
                id="avg_synth_time_sec"
                endAdornment={
                    <InputAdornment position="end">
                        <Typography>ms</Typography>
                    </InputAdornment>
                }
                value={state.configs.value.find(c => c.id === props.config_id)?.avg_synth_time || "–"}
            />
        </FormControl>

    </Stack>
  )
}
