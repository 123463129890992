import { Button } from '@mui/material'
import React from 'react'

interface AddMidiFileButtonProps {
    buttonText: string,
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export function AddMidiFileButton(props: AddMidiFileButtonProps) {
  return (
    <Button variant='outlined' component="label">
        {props.buttonText}
        <input
            type='file'
            accept="audio/midi"
            onChange={props.handleFileChange}
            hidden
        />
    </Button>
  )
}
