import { FormControl, OutlinedInput, Stack, Tooltip, Typography } from '@mui/material';
import { AppState } from 'App';
import React, { useContext } from 'react'
import useAuth from 'utils/form/hooks/useAuth';
import useNumNotes from 'utils/form/hooks/useNumNotes';
import usePresets from 'utils/form/hooks/usePresets';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function NumUniqueInput() {
  const state = useContext(AppState);
  const {auth} = useAuth();;
  // const {presetsParamsDescriptions} = usePresets(auth);
  const {handleChangeUnique} = useNumNotes();

  return (
    <Stack spacing={"4px"} sx={{minWidth: "200px"}}>
      <Tooltip title={state.presetsParamsDesc.value?.min_num_unique_notes || ""}>

        <Typography 
          variant="caption" 
          display="block" 
          sx={{color: 'secondary.contrastText'}}
        >
            min_num_unique_notes
            <HelpOutlineIcon fontSize='small' sx={{p: '2px'}}/>
        </Typography>
      </Tooltip>

      <FormControl>
        <OutlinedInput
          value={state.bassPreset.value.min_num_unique_notes ? state.bassPreset.value.min_num_unique_notes[0] : ''}
          onChange={handleChangeUnique}
          inputProps={{
            type: 'number',
          }}
        />
      </FormControl>
    </Stack>
  )
}
