import React, { useContext, useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { urlBase, useAppDispatch, useAppSelector } from 'store/store';
import { error } from 'console';
import { PackObjWav } from 'utils/form/hooks/types/types';
import { addWavPackObj, changeUrlWavPackObj } from 'store/features/wavPackObjSlice';
import { LoadStatus } from 'utils/form/hooks/enums/loader';
import { Button, Stack } from '@mui/material';

import { FlowParams, FlowResponse, TaskName, TaskResultOut } from 'utils/form/hooks/types/flowTypes';
import { AppState } from 'App';
import useAuth from 'utils/form/hooks/useAuth';
import { getAxiosErrorStatus } from 'utils/helpers/axiosErrorStatus.helpers';
import { logout } from 'services/index';
import { BASE_BASS_PRESET_SETTINGS, BASE_MAGENTA_SETTINGS, INTERVAL, MELODY_RNN_PARAMS, STANDARD_BASS_PRESET_SETTINGS } from 'utils/constants/constans';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';

interface ButtonsPackagesProps {
    selectedIds: number[],
    epochs: number,
    instrument_id: number
}

export function ButtonsPackages(props: ButtonsPackagesProps) {
    const state = useContext(AppState);
    const {auth, setAuth} = useAuth();

    const dispatch = useAppDispatch();

    // const fetchArchive = async (packetId: number, result_id: string) => {
    //     try {
    //         // const result_id_enc = encodeURIComponent(result_id);

    //         console.log('packetId: ', packetId);
    //         console.log('result_id: ', result_id);
    //         console.log('url: ', `${urlBase}/results/archive/packet/${TypeMidi.STANDART}/${packetId}/${result_id}`);
    //         const response = await axios.get(`${urlBase}results/archive/packet/${TypeMidi.STANDART}/${packetId}/${result_id}`, {
    //             responseType: 'arraybuffer',
    //             auth: {
    //                 username: auth.username,
    //                 password: auth.password
    //             }
    //         });
    //         const resUrl = URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
    //         dispatch(changeUrlWavPackObj({id: packetId, url: resUrl}));
    //     } catch (error) {
    //         console.error(error);
    //         dispatch(changeUrlWavPackObj({id: packetId, url: LoadStatus.FAILED}));
    //         if (axios.isAxiosError(error)) {
    //             if (axios.isAxiosError(error)) {
    //                 const status = getAxiosErrorStatus(error);
    
    //                 if (status === 401) {
    //                     logout();
    //                     state.isLogin.value = false;
    //                 }
    //             }
    //         }
    //     }
    // }

    const checkTask = async (task_id: string) => {

        const intervalId = setInterval(async () => {
            try {
                const response: AxiosResponse<TaskResultOut> = await axios.get(`${urlBase}/status/${task_id}`, {
                    auth: {
                        username: auth.username,
                        password: auth.password
                    }
                });
                // проверка
                console.log('response.data.task_result: ', response.data);
                
                if (response.data.task_result) {
                    state.flowResults.value = [...state.flowResults.value, response.data.task_result];
                    clearInterval(intervalId);
                    dispatch(changeUrlWavPackObj({id: response.data.task_result.packet_id, url: response.data.task_result.result.result_id}));
                    // fetchArchive(
                    //     response.data.task_result.packet_id, 
                    //     response.data.task_result.result.result_id, 
                    //     // response.data.task_result.result.midi_files[0]
                    // );
                    
                }
            } catch (error) {
                console.error("interval error");
            }
        }, INTERVAL);

    }

    const sendFlowRun = async (packetId: number) => {
        dispatch(changeUrlWavPackObj({id: packetId, url: LoadStatus.LOADING}));
        const params: FlowParams = {
            packet_id: packetId,
            epochs: props.epochs,
            instrument_id: props.instrument_id,
            model_settings: BASE_MAGENTA_SETTINGS,
            bass_preset_settings: STANDARD_BASS_PRESET_SETTINGS,
            melodyRNNParams: MELODY_RNN_PARAMS
        }

        try {
            const response: AxiosResponse<TaskName> = await axios.post(`${urlBase}/run/flow/`, params, {
                auth: {
                    username: auth.username,
                    password: auth.password
                }
            });
            const task: string = response.data.task_name;
            console.log('task_name: ', task);

            // вместо вызываем checkTask и туда переносим
            checkTask(task);

            // state.flowResults.value = [...state.flowResults.value, responseData];

            // sendFlowMidi(responseData.packet_id, responseData.result.result_id, responseData.result.midi_files[0]);

        } catch (error) {
            dispatch(changeUrlWavPackObj({id: packetId, url: LoadStatus.FAILED}));
            if (axios.isAxiosError(error)) {
                if (axios.isAxiosError(error)) {
                    const status = getAxiosErrorStatus(error);
    
                    if (status === 401) {
                        logout();
                        state.isLogin.value = false;
                    }
                }
            }
        }
    }

    const handleStart = () => {
        props.selectedIds.forEach((packetId) => {
            dispatch(addWavPackObj({wav: {id: packetId, urlFile: LoadStatus.IDLE}}));
            sendFlowRun(packetId);
        })
        
    }

    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={"8px"}>
                <Button variant='outlined' disabled>Результаты по пакету</Button>
                <Button variant='outlined' disabled>Конфиги, которые дообучил пакет</Button>
            </Stack>
            
            <Button variant="contained" title='Начать обучение' onClick={handleStart}>Начать обучение</Button>
            
        </Stack>

    )
}

