import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ErrorResponse } from 'react-router-dom'
import {urlBase} from 'store/store'
import { PackObjWav } from 'utils/form/hooks/types/types'

interface WavPackTypeState {
    wavs: PackObjWav[],
    loading: boolean,
    error: string | null
}

const initialState: WavPackTypeState = {
    wavs: [],
    loading: false,
    error: null
}

export const WavPackObjSlice = createSlice({
    name: "wavpack",
    initialState,
    reducers: {
        addWavPackObj(state, action: PayloadAction<{wav: PackObjWav}>) {
            // if (state.wavs.find(wav => wav.id !== action.payload.wav.id)){
            //     state.wavs.push(action.payload.wav);
            // }
            state.wavs.push(action.payload.wav);
        },
        changeUrlWavPackObj: (state, action: PayloadAction<{id: number, url: string}>) => {
            state.wavs.forEach(wav => {
                if (wav.id === action.payload.id) {
                    wav.urlFile = action.payload.url
                }
            })
        }
    },
})

export default WavPackObjSlice.reducer;
export const {addWavPackObj, changeUrlWavPackObj} = WavPackObjSlice.actions;