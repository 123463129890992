import { Typography } from '@mui/material'
import React from 'react'

interface StatisticRowProps {
  nameRow: string,
  valueRow: number
}

export function StatisticRow(props: StatisticRowProps) {
  return (
    <Typography>{props.nameRow}: {props.valueRow}</Typography>
  )
}
