import { Stack } from '@mui/material';
import React from 'react'

type Melody2RhytmFormProps = {
    children?: React.ReactNode;
}
export function Melody2RhytmForm(
    {children}: Melody2RhytmFormProps
) {
  return (
    <Stack direction={"column"} spacing={2}>
        {children}
    </Stack>
  )
}
