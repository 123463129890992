import { AppState } from "App";
import { ChangeEventHandler, useContext, useState } from "react";
import { IMelodyRNNParams } from "./types/experimentsTypes";
import { MELODY_RNN_PARAMS } from "utils/constants/constans";

export default function useVariationPreset() {
    const state = useContext(AppState)   

    const [melodyRNNParams, setMelodyRNNParams] = useState<IMelodyRNNParams>(MELODY_RNN_PARAMS);

    const handleChangeNumberData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // console.log({name, value})
        setMelodyRNNParams((prevData) => ({
            ...prevData,
            [name]: parseInt(value) || prevData[name]
        }))

        state.melodyRNNParams.value = {
            ...state.melodyRNNParams.value,
            // [name]: parseInt(value) || state.melodyRNNParams.value[name]
            [name]: value ? parseInt(value) : null
        }

        console.log(state.melodyRNNParams.value)
    }

    const handleChangeFloatData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ? parseFloat(e.target.value.replace(/,/g, '.')) : null
        setMelodyRNNParams((prevData) => ({
            ...prevData,
            temperature: value
        }))

        state.melodyRNNParams.value = {
            ...state.melodyRNNParams.value,
            temperature: value
        }
    }

    return {
        melodyRNNParams,
        handleChangeNumberData,
        handleChangeFloatData
    }
}
