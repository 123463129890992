import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiPopover = {
    defaultProps?: ComponentsProps['MuiPopover'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiPopover'];
    variants?: ComponentsVariants['MuiPopover']; 
}

export const MuiPopover: muiPopover = {
    styleOverrides: {
        root: {
            // backgroundColor: colors.secondaryLight,
            // padding: "16px",
            // borderRadius: "16px"
            ".MuiPaper-root": {
                backgroundColor: colors.secondaryLight,
                padding: "16px",
                borderRadius: "16px"
            }
        }
    }
}