import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'styles/index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from 'store/store';
import { ThemeProvider } from '@emotion/react';
import { theme } from 'styles/theme';
import { CssBaseline } from '@mui/material';
import '@preact/signals-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
