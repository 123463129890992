import { Algorithm, ExplorationMode } from "utils/form/hooks/enums/model_params";
import { ChromaticParams, IMelodyRNNParams, MagentaSettings, Melody2RhitmParams } from "utils/form/hooks/types/experimentsTypes";
import { BassPresetFlow, PresetSettings } from "utils/form/hooks/types/presetTypes";
import { SynthX } from "utils/form/hooks/types/synthTypes";
import { TagStr } from "utils/form/hooks/types/tagsTypes";

export const INTERVAL = 5000;
export const MAX_EPOCHS = 50000;
export const MIN_EPOCHS = 1;

export const FOLLOWERS_MARKS = [
    {
        value: 0,
        scaledValue: 100,
        label: "0.1k"
    },
    {
        value: 5,
        scaledValue: 500,
        label: ""
    },
    {
        value: 10,
        scaledValue: 1000,
        label: "1k"
    },
    {
        value: 50,
        scaledValue: 5000,
        label: "5k"
    },
    {
        value: 100,
        scaledValue: 10000,
        label: "10k"
    }
]

// Name,Key,Chord_type,bpm,main_genre,type,tag_1,tag_2,tag_3,tag_4,tag_5,valence,arousal
export const HEADERS_CSV = [
    "Name",
    "Key",
    "Chord_type",
    "bpm",
    "main_genre",
    "type",
    "tag_1",
    "tag_2",
    "tag_3",
    "tag_4",
    "tag_5",
    "valence",
    "arousal",
]

export const BASE_MAGENTA_SETTINGS: MagentaSettings = {
    bpm: 120,
    algorithm: Algorithm.PSI,
    num_notes_in_melody: 32,
    enable_random: false,
    first_note: 71,
    reward_scaler: 1.0,
    exploration_mode: ExplorationMode.EGREEDY,
    stochastic_observations: false,
    TECH_TONE: 16,
    TECH_KEY: [12,14,16,17,19,21,23,24],
    transpose_amount: 19,
}
const SHARP_REMOVE: PresetSettings = {
    notes_capacity: [],
    range_notes: null,
    min_num_notes: null,
    min_num_unique_notes: null,
    notes_cutoff: null
}

export const BASE_BASS_PRESET_SETTINGS: BassPresetFlow = {
    bass_preset: "sharp_remove",
    preset_settings: SHARP_REMOVE
}
export const STANDARD_BASS_PRESET_SETTINGS: BassPresetFlow = {
    bass_preset: "complex_bass",
    preset_settings: SHARP_REMOVE
}

export const ALGORITHM = [
    "default",
    "psi",
    "g",
    "pure_rl"
]

export const EXPLORATION_MODE = [
    "egreedy",
    "boltzmann"
]

export const MELODY_RNN_PARAMS: IMelodyRNNParams  = {
    num_outputs: 10,
    num_steps: 128,
    primer_midi: "",
    qpm: 120,
    temperature: 1.0,
    beam_size: 1,
    branch_factor: 1,
    steps_per_iteration: 1
}

export const MELODY_2_RHITHM_PARAMS: Melody2RhitmParams = {
    rhythm_file_url: "",
    rhythm_file_name: "",
    melody_file_url: "",
    melody_file_name: "",
    result_file_url: "",  
    result_file_name: "", 
    isLoading: false,
    error: "" 
}



export const CHROMATIC_PARAMS: ChromaticParams = {
    algorithm: "default",
    source_file_url: "",
    source_file_name: "",
    result_file_url: "",
    result_file_name: "",
    isLoading: false,
    error: ""
}

export const VARIATIONS: TagStr[] = [
    {id: 0, name: '1n1'},
    {id: 1, name: '3n1'},
]

export const BASS_PRESET: TagStr[] = [
    {id: 0, name: 'complex_bass'},
    {id: 1, name: 'middle_bass'},
]

export const SYNTH_X: SynthX = {
    category: "x",
    packet_id: 217,
    epochs: 200,
    bass_preset: BASS_PRESET[0].name,
    variation: VARIATIONS[0].name,
    midi_data_id: 1,
    id: 1
}