import { AppState } from "App"
import { useContext } from "react"
import { Auth } from "./useAuth"
import { Melody2RhitmOut } from "./types/experimentsTypes"
import { generateMelody2rhitm } from "api/melody2rhitm.api"
import { errorCatch } from "api/error"

export default function useMelody2Rhithm() {
    const state = useContext(AppState)

    const handleChangeRhitmFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUrl = URL.createObjectURL(e.target.files[0])
            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                rhythm_file_name: e.target.files[0].name,
                rhythm_file_url: fileUrl
            }
        }
    }

    const handleChangeMelodyFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileUrl = URL.createObjectURL(e.target.files[0])
            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                melody_file_name: e.target.files[0].name,
                melody_file_url: fileUrl
            }
        }
    }

    const handleGenerate = async (auth: Auth) => {
        // проверка файлов

        // обновляем сосотояние
        state.melody2rhitmParams.value = {
            ...state.melody2rhitmParams.value,
            isLoading: true,
            error: ""
        }

        // запрос
        const response = await generateMelody2rhitm(auth, state.melody2rhitmParams.value)

        if (typeof response === "string") {
            // если ошибка - обновляем состояние
            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                isLoading: false,
                error: response
                // error: response.error_massege,
                // result_file_url: response.result_file_url,
                // result_file_name: response.result_file_name
            }
            return
        }

        const resUrl = URL.createObjectURL(new Blob([response], {type: 'audio/midi'}));
        const link = document.createElement('a');
        link.href = resUrl;
        link.setAttribute('download', `melody2rhythm_${Date.now()}.mid`);
        document.body.appendChild(link);
        link.click();
        link.remove();  
        URL.revokeObjectURL(resUrl);
    }

    // const handleDownload = () => {
    //     try {
    //         const link = document.createElement('a')
    //         link.href = state.melody2rhitmParams.value.result_file_url
    //         link.setAttribute('download', 'result_file')
    //         document.body.appendChild(link)
    //         link.click()
    //         link.remove()
    //         URL.revokeObjectURL(state.melody2rhitmParams.value.result_file_url)
    //     } catch (error) {
    //         const error_massege = errorCatch(error)
    //     }
    // }

    return {
        handleChangeRhitmFile,
        handleChangeMelodyFile,
        handleGenerate,
        // handleDownload
    }
}