import React from 'react'

export function ResultInfoPage() {
  return (
    <>
      
    </>
  )
}

