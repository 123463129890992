/**
* Обрабатывает ошибки, извлекая сообщение об ошибке из данных ответа 
* или возвращаясь к сообщению об ошибке, если данные ответа недоступны.
*
* @param error - Объект ошибки, который нужно обработать.
* @returns Сообщение об ошибке в виде строки.
*/
export const errorCatch = (error: any): string => {
    const message = error?.response?.data?.message

    return message
        ? typeof error.response.data.message === 'object'
        ? message[0]
        : message
        : error.message
}