import { Stack } from '@mui/material'
import React from 'react'

type ChromaticFormProps = {
  children?: React.ReactNode
}
export function ChromaticForm(
    {children}: ChromaticFormProps
) {
  return (
    <Stack direction={"column"} spacing={2}>
        {children}
    </Stack>
  )
}
