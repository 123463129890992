import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";

type muiTabs = {
    defaultProps?: ComponentsProps['MuiTabs'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTabs'];
    variants?: ComponentsVariants['MuiTabs'];
}

export const MuiTabs: muiTabs = {
    styleOverrides: {
        root: {
            ".MuiTabs-indicator": {
                display: "none",
            },
        },
    },
}