import { FormControl, TextField } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useVariationPreset from 'utils/form/hooks/useVariationPreset'

export function StepsPerIterationInput() {
  const state = useContext(AppState)
  const {handleChangeNumberData} = useVariationPreset()
  return (
    <FormControl>
      <TextField
        type='number'
        value={state.melodyRNNParams.value.steps_per_iteration}
        onChange={handleChangeNumberData}
        name='steps_per_iteration'
        label='steps_per_iteration'
      />
    </FormControl>
  )
}
