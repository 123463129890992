import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiTableBody = {
    defaultProps?: ComponentsProps['MuiTableBody'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTableBody'];
    variants?: ComponentsVariants['MuiTableBody'];
}

export const MuiTableBody: muiTableBody = {
    styleOverrides: {
        root: {
            // borderRadius: "10px",
            // padding: "5px 20px",
            // backgroundColor: colors.primaryMain,
            // textTransform: "none",
            boxShadow: "none",
        },
    }
}