import { FormControl, MenuItem, Select } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useSynthX from 'utils/form/hooks/useSynthX'

export function SynthXPacketSelect() {
    const state = useContext(AppState)
    const {handleChangePacketId} = useSynthX()
  return (
    <FormControl>
        <Select
        value={state.synthX.value.packet_id.toString()}
        onChange={handleChangePacketId} 
        >
        {
            state.packets.value.map((pk, index) => (
            <MenuItem key={index} value={pk.id}>{pk.id}</MenuItem>
            ))
        }
        </Select>
    </FormControl>
  )
}
