import { FormControl, MenuItem, Select } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import { VARIATIONS } from 'utils/constants/constans'
import useSynthX from 'utils/form/hooks/useSynthX'

export function SynthXVariationSelect() {
    const state = useContext(AppState)
    const { handleChangeVariation} = useSynthX()
  return (
    <FormControl>
      <Select
        value={state.synthX.value.variation}
        onChange={handleChangeVariation} 
      >
        {
          VARIATIONS.map((variation, index) => (
            <MenuItem key={variation.id} value={variation.name}>{variation.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
