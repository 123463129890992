import { Alert } from '@mui/material'
import React from 'react'
import { AlertSeverity } from 'utils/form/hooks/enums/alertSeverity'

interface BasicAlertProps {
    text: string,
    severity: AlertSeverity
}
export function BasicAlert(props: BasicAlertProps) {
  return (
    <Alert severity={props.severity}>{props.text}</Alert>
  )
}
