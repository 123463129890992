import { FormControl, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

interface SearchFilterProps {
    searchTerm: string
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function SearchBaseFilter(props: SearchFilterProps) {
  return (
    <FormControl sx={{width: '100%' }}>
        <TextField
            type="search"
            value={props.searchTerm}
            onChange={props.handleSearchChange}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRoundedIcon />
                    </InputAdornment>
                ),
                placeholder: 'Поиск',
            }}
        />
    </FormControl>

  )
}
