import { Button, Tooltip } from '@mui/material';
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import useAuth from 'utils/form/hooks/useAuth';
import axios from 'axios';
import { urlBase } from 'store/store';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';
import { fetchArchivePacketResult } from 'api/results.api';

interface DownloadArchiveButtonProps {
    packet_id: number,
    url: string,
}

export function DownloadArchiveButton(props: DownloadArchiveButtonProps) {
    const {auth, setAuth} = useAuth();

    const handleDownload = async () => {
        try {
            // const response = await axios.get(`${urlBase}/results/archive/packet/${TypeMidi.STANDART}/${props.packet_id}/${props.url}`, {
            //   responseType: 'arraybuffer',
            //     auth: {
            //         username: auth.username,
            //         password: auth.password
            //     }
            // })
            const response = await fetchArchivePacketResult({auth, type: TypeMidi.STANDART, obj_id: props.packet_id, url: props.url})
            const resUrl = URL.createObjectURL(new Blob([response], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = resUrl;
            link.setAttribute('download', `package_${props.packet_id}.zip`);
            document.body.appendChild(link);

            link.click();
            link.remove();
            URL.revokeObjectURL(resUrl);
        } catch (error) {
          console.error(error); 
        }
    }
  return (
    <Tooltip title="Скачать архив" placement="left">
        <Button onClick={handleDownload}>
            package_{props.packet_id}.zip
            <DownloadIcon/>
        </Button>
    </Tooltip>
  )
}
