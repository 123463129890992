import React, { useContext } from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormControl, OutlinedInput, Stack, Tooltip, Typography } from '@mui/material';
import { AppState } from 'App';
import useAuth from 'utils/form/hooks/useAuth';
import usePresets from 'utils/form/hooks/usePresets';
import useNumNotes from 'utils/form/hooks/useNumNotes';
import { Label } from '@mui/icons-material';

export function RangeNotesInput() {
  const state = useContext(AppState);
  const {auth} = useAuth();
  // const {presetsParamsDescriptions} = usePresets(auth);
  const {handleChangeBaseNote, handleChangeDown, handleChangeUp} = useNumNotes();
  
  return (
    <Stack spacing={"4px"} sx={{minWidth: "200px"}}>
      <Tooltip title={state.presetsParamsDesc.value?.range_notes || ""}>

        <Typography 
          variant="caption" 
          display="block" 
          sx={{color: 'secondary.contrastText'}}
        >
            range_notes
            <HelpOutlineIcon fontSize='small' sx={{p: '2px'}}/>
        </Typography>
      </Tooltip>

      <Stack direction={'row'} spacing={"4px"} alignItems={'center'}>
        <FormControl>
          <OutlinedInput
            value = {
              state.bassPreset.value.range_notes ?
              state.bassPreset.value.range_notes[0] :
              ''
            }
            onChange={handleChangeBaseNote}
            inputProps={{
              type: 'number',
            }}
          />
          <Typography variant="caption" sx={{color: 'secondary.contrastText'}}>base_note</Typography>
        </FormControl>

        <FormControl>
          <OutlinedInput
            value = {
              state.bassPreset.value.range_notes ?
              state.bassPreset.value.range_notes[1] :
              ''
            }
            onChange={handleChangeDown}
            inputProps={{
              type: 'number',
              min: 0
            }}
          />
          <Typography variant="caption" sx={{color: 'secondary.contrastText'}}>down</Typography>
        </FormControl>

        <FormControl>
          <OutlinedInput
            value = {
              state.bassPreset.value.range_notes ?
              state.bassPreset.value.range_notes[2] :
              ''
            }
            onChange={handleChangeUp}
            inputProps={{
              type: 'number',
              min: 0
            }}
          />
          <Typography variant="caption" sx={{color: 'secondary.contrastText'}}>up</Typography>
        </FormControl>
      </Stack>

    </Stack>
  )
}
