import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiDialog = {
    defaultProps?: ComponentsProps['MuiDialog'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
    variants?: ComponentsVariants['MuiDialog'];
}

export const MuiDialog: muiDialog = {
    styleOverrides: {
        paper: {
            backgroundColor: colors.secondaryLight,
            p: "24px",
            borderRadius: "16px",
        }
    }
}