import { Stack } from '@mui/material'
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { AppState } from 'App';
import { getPackets } from 'api/packets.api';
import { PacketList } from 'components'
import React, { useContext, useEffect, useState } from 'react'
import { PacketObject } from 'utils/form/hooks/types/packetTypes';
import useAuth from 'utils/form/hooks/useAuth';
import { ExperimentsForm } from 'views/forms'
// import { ExperimentsState } from 'views/pages/specialPages/ExperimentsPage';

export function ExperimentsPanel() {
  // const experimentsState = useContext(ExperimentsState);
  const state = useContext(AppState);
  const packets = useSignal<PacketObject[]>([]);

  const {auth, setAuth} = useAuth();
  

  const init = async () => {
    // if (experimentsState.packets.value.length !== 0) {
    //   return;
    // }
    const response = await getPackets(auth);
    // experimentsState.packets.value = response;
    packets.value = response;
    state.packets.value = response;
  }

  useEffect(() => {
    init();
  }, []);

  
  return (
    <Stack direction="row" spacing={2}>
      {/* <PacketList packets={packets.value}/> */}
      <ExperimentsForm packets={packets.value}/>
    </Stack>
  )
}
