import { Button } from '@mui/material'
import React from 'react'
import useLearning from 'utils/form/hooks/useLearning';

export function DefaultParamsButton() {
    const {handleResetParams} = useLearning();
  return (
    <Button 
        variant="contained" 
        onClick={handleResetParams}
    >
        Сбросить параметры
    </Button>
  )
}
