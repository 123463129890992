import { Stack, Typography } from '@mui/material'
import { AppState } from 'App'
import { AddMidiFileButton } from 'components'
import React, { useContext } from 'react'
import useMelody2Rhithm from 'utils/form/hooks/useMelody2Rhithm'

export function RhythmFileInput() {
    const state = useContext(AppState)
    const {handleChangeRhitmFile} = useMelody2Rhithm()

  return (
    <Stack direction={"column"} spacing={1}>
        <AddMidiFileButton
            buttonText="Загрузить Ритм Миди файл"
            handleFileChange={handleChangeRhitmFile}
        />
        <Typography variant="caption">File: {state.melody2rhitmParams.value.rhythm_file_name}</Typography>

    </Stack>
  )
}
