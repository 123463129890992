import React, { useContext, useEffect } from 'react'
import { Header, Login } from 'views/index'
import { Outlet } from 'react-router-dom'
import { Stack } from '@mui/material'
import { AppState } from 'App'
import { getAuth } from 'services/Auth/Authorization.service'
import { fetchGenres, fetchInstruments, fetchKeyTags, fetchTonalities } from 'api/tags.api'
import { TagStr } from 'utils/form/hooks/types/tagsTypes'
import usePresets from 'utils/form/hooks/usePresets'
import useAuth from 'utils/form/hooks/useAuth'
import useSynthX from 'utils/form/hooks/useSynthX'
import { getSynthX } from 'api/synth.api'
import { SynthX } from 'utils/form/hooks/types/synthTypes'


export function HomePage() {
  const state = useContext(AppState);
  const {auth} = useAuth();
  const { fetchPresetsParamsDescriptions, handleChangePreset, fetchDefaultPresetSettings} = usePresets(auth);
  const {initSynthX} = useSynthX()
  const getTags = async () => {
    const keys: TagStr[] | undefined = await fetchKeyTags();
    const tonalities: TagStr[] | undefined = await fetchTonalities();
    const instruments: TagStr[] | undefined = await fetchInstruments();
    const genres: TagStr[] | undefined = await fetchGenres();
    await fetchPresetsParamsDescriptions(auth);
    await fetchDefaultPresetSettings(auth);
    await initSynthX(auth)

    // if (typeof synthX !== 'string') {
    //   state.synthX.value = synthX
    // } else {
    //   console.log(synthX)
    // }
    state.keyTags.value = keys || [];
    state.tonalityTags.value = tonalities || [];
    state.instrumentTags.value = instruments || [];
    state.genreTags.value = genres || [];
  }

  // useEffect(() => {
  //   getTags();
  // }, [])

  if (!state.isLogin.value) {
  // if (!state.isLogin.value) {
    return <Login />
  } else {
    // if (!state.isLogin.value) {
    //   state.isLogin.value = true
    // }

    getTags();
    return (
      <Stack spacing={2}>
          <Header/>
          <Outlet/>
      </Stack>
    )
  }
}

