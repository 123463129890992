import { Button, Tooltip } from '@mui/material';
import React from 'react'
import useAuth from 'utils/form/hooks/useAuth'
import DownloadIcon from '@mui/icons-material/Download';
import { fetchOriginalArchive } from 'api/results.api';

interface DownloadArchiveOriginalButtonProps {
  config_id: number
}
export function DownloadArchiveOriginalButton(props: DownloadArchiveOriginalButtonProps) {
  const {auth} = useAuth();

  const handleDownload = async () => {
    try {
      const response = await fetchOriginalArchive({auth, obj_id: props.config_id})
      const resUrl = URL.createObjectURL(new Blob([response], {type: 'application/zip'}));
      const link = document.createElement('a');
      link.href = resUrl;
      link.setAttribute('download', `small_${props.config_id}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(resUrl);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Tooltip title="Скачать архив" placement="left">
      <Button onClick={handleDownload} variant='outlined' sx={{color: 'primary.main'}}>
        small_{props.config_id}.zip
        <DownloadIcon/>
      </Button>
    </Tooltip>
  )
}
