import axios, { AxiosResponse } from "axios";
import { urlBase } from "store/store";
import { PacketObject } from "utils/form/hooks/types/packetTypes";
import { Auth } from "utils/form/hooks/useAuth";

export async function getPackets(auth: Auth) {
    const response: AxiosResponse<PacketObject[]> = await axios.get(`${urlBase}/packages/unique-packages/`, {
        auth: {
            username: auth.username,
            password: auth.password
        }
    });

    return response.data;
}