import { Stack } from '@mui/material'
import React from 'react'

interface SavedWeightsFormProps {
    children: React.ReactNode
}

export function SavedWeightsForm(
    {children}: SavedWeightsFormProps
) {
  return (
    <Stack direction={'column'} alignItems={'center'} spacing={2}>
        {children}
    </Stack>
  )
}
