import {TooltipIcon, MidiPlayerWrapper, GradeContainer} from 'components/index';

import React, { ChangeEvent, useContext, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'
import { addFile } from 'store/features/fileSlice'
// import { WavFile } from 'utils/form/hooks/types/types'
import { Box,CircularProgress, Stack } from '@mui/material';
import {allDataTexts} from 'utils/form/texts/tooltips'
import {LoadStatus} from 'utils/form/hooks/enums/loader';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardMedia from '@mui/material/CardMedia';
import TablePagination from '@mui/material/TablePagination';

import { AppState } from 'App';
import { MainTableRow } from './mainTable/MainTableRow';
import { MidiFile } from 'utils/form/hooks/types/allDataTypes';

/**
 * Renders the main table component.
 */
export function MainTable() {
  const state = useContext(AppState);
  const dispatch = useAppDispatch();
  const files=useAppSelector((state) => state.files.files);

  const [selectedAll, setSelectedAll] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filteredData = files.filter(file => (
    (state.filterAllData.value.name === '' || file.fileName.toLowerCase().includes(state.filterAllData.value.name.toLowerCase())) &&
    (state.filterAllData.value.keyTagId === 0 || file.tagset.key_id === state.filterAllData.value.keyTagId) &&
    (state.filterAllData.value.tonalityTagId === 0 || file.tagset.tonality_id === state.filterAllData.value.tonalityTagId) &&
    (state.filterAllData.value.instrumentTagId === 0 || file.tagset.instrument_id === state.filterAllData.value.instrumentTagId) &&
    (state.filterAllData.value.genreTagId === 0 || file.tagset.genre_id === state.filterAllData.value.genreTagId) &&
    (file.tagset.bpm === null || file.tagset.bpm !== null && state.filterAllData.value.bpmRange[0] <= file.tagset.bpm && file.tagset.bpm <= state.filterAllData.value.bpmRange[1])
  ));

  /**
   * Handles the change event when a file is selected.
   * @param event - The change event triggered by the file input element.
   */
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // выбранные файлы
    const new_files = Array.from(event.target.files ?? []);
    for (let i = 0; i < new_files.length; i++) {

      const urlFile = URL.createObjectURL(new_files[i]);

      const fileInfo: MidiFile = {
        id: 0,
        fileName: new_files[i].name,
        fileUrl: urlFile,
        tagset: {
          key_id: null,
          tonality_id: null,
          instrument_id: null,
          genre_id: null,
          bpm: null,
        },
        packet: null,
      }


      const fileIncluded = files.find((file) => file.fileName === fileInfo.fileName);
      if (fileIncluded) {
        const result = window.confirm(fileIncluded.fileName + ' уже есть в таблице. Добавить еще раз?');

        if (result) {
          dispatch(addFile({fileInfo: fileInfo}));
          console.log("Добавлен совпадающий файл");
        } else {
          console.log("Не добавлен совпадающий файл");
        }
      } else {
        dispatch(addFile({fileInfo: fileInfo}));
        console.log("Добавлен уникальный файл");
      }
    }
  };

  /**
   * Toggles the value of the rowCheckbox state.
   */
  const handleCheckboxChange = (id: number) => {
    if (state.selectedRowsAllData.value.includes(id)) {
      setSelectedAll(false);
      state.selectedRowsAllData.value = state.selectedRowsAllData.value.filter((rowId) => rowId !== id);
      return state.selectedRowsAllData.value;
    } else {
        state.selectedRowsAllData.value = [...state.selectedRowsAllData.value, id];
        return state.selectedRowsAllData.value;
    }
  }

  /**
   * Toggles the selection of all files.
   * If all files are already selected, it deselects all files.
   * If not all files are selected, it selects all files.
   */
  const chooseAll = () => {
    if (selectedAll === true) {
      // Deselect all files
      state.selectedRowsAllData.value = [];
      setSelectedAll(false);
    } else {
      // Select all files that are not already selected
      let allIds: number[] = [];
      files.forEach((file) => {
        allIds = [...allIds, file.id];
      });
      state.selectedRowsAllData.value = allIds;
      setSelectedAll(true);
    }
  }

  /**
  * Обрабатывает изменение страницы в пагинируемом компоненте.
  * @param event - Объект события.
  * @param newPage - Новый номер страницы.
  */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /**
  * Обрабатывает изменение количества строк на странице.
  * @param {React.ChangeEvent<HTMLInputElement>} event - Объект события.
  */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    return (
      <Stack direction={"column"} spacing={"24px"}>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="left">Wav<TooltipIcon text={allDataTexts["wav"]}/></TableCell> */}
                    <TableCell align="left">midi</TableCell>

                    <TableCell align="left" colSpan={2}>Тональность</TableCell>
                    <TableCell align="left">Темп, bpm</TableCell>
                    <TableCell align="left">Инструмент</TableCell>
                    <TableCell align="left">Жанр</TableCell>

                    <TableCell align="left">пакет обучения <TooltipIcon text={allDataTexts["pack"]}/></TableCell>
                    {/* <TableCell align="left">midi <TooltipIcon text={allDataTexts["midi"]}/></TableCell> */}
                    <TableCell>
                      <input 
                        type="checkbox"
                        checked={selectedAll}
                        onChange={chooseAll}
                        />
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((file) => (
                      <MainTableRow file={file} key={file.id} handleCheckboxChange={handleCheckboxChange} />
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={files.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
    </Stack>
  );
};