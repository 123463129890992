import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiButton = {
    defaultProps?: ComponentsProps['MuiButton'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
    variants?: ComponentsVariants['MuiButton'];
}

export const MuiButton: muiButton = {
    styleOverrides: {
        root: {
            textTransform: "none",
        }
    },

    variants: [
        {
            props: { variant: 'contained' },
            style: {
                backgroundColor: colors.primaryMain,
                color: colors.commonWhite,
                borderRadius: "8px",
                px: "32px",
                py: "12px",
                boxShadow: "1px 1px 5px 0px rgba(48, 49, 93, 0.10)",
                "&:hover": {
                    backgroundColor: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #7779F5",
                    boxShadow: "1px 1px 5px 0px rgba(48, 49, 93, 0.10)",
                },
                "&.active": {
                    backgroundColor: "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #7779F5",
                    boxShadow: "1px 1px 5px 0px rgba(48, 49, 93, 0.10)",
                    color: colors.commonWhite
                }
            }
        },

        {
            props: { variant: 'outlined' },
            style: {
                color: colors.commonBlack,
                backgroundColor: colors.commonWhite,
                borderRadius: "8px",
                px: "32px",
                py: "12px",
                boxShadow: "none",
                "&:hover": {
                    backgroundColor: colors.backgroundDefault,
                },
                "&.active": {
                    backgroundColor: colors.commonWhite,
                    color: "#9294F7",
                }
            }
        }
    ]
}