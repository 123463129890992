import { components } from "styles/theme/components";
import { foundations } from "styles/theme/foundations";
import { createTheme, ThemeOptions } from "@mui/material";


const themeOptions: ThemeOptions = {
  ...foundations,
};


export const theme = createTheme({
  ...themeOptions,
  components: {
    ...components,
  },
});

