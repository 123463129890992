import { Paper } from '@mui/material'
import React from 'react'
import { DefaultParamsButton, GeneralActions, StartLearningButton } from 'views/forms'

export function GeneralActionsPanel() {
  return (
    <Paper sx={{padding: 2}}>
        <GeneralActions>
            <DefaultParamsButton/>
            <StartLearningButton/>
        </GeneralActions>
    </Paper>
  )
}
