// import { HelpOutline } from '@mui/icons-material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Tooltip } from '@mui/material';
import React, { useState } from 'react'

interface TooltipIconProps {
    text: string;
}

export function TooltipIcon(props: TooltipIconProps) {
    return (
    <Tooltip title={props.text}>
        <HelpOutlineOutlinedIcon fontSize='small' color='disabled'/>
    </Tooltip>
    )
}

