import { FormControl, MenuItem, Select } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import { BASS_PRESET } from 'utils/constants/constans'
import useSynthX from 'utils/form/hooks/useSynthX'

export function SynthXBassPresetSelect() {
    const state = useContext(AppState)
    const {handleChangeBassPreset} = useSynthX()

  return (
    <FormControl>
        <Select
        value={state.synthX.value.bass_preset}
        onChange={handleChangeBassPreset} 
        >
        {
            BASS_PRESET.map((pk, index) => (
            <MenuItem key={pk.id} value={pk.name}>{pk.name}</MenuItem>
            ))
        }
        </Select>
    </FormControl>
  )
}
