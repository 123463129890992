import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useAuth from 'utils/form/hooks/useAuth'
import useMelody2Rhithm from 'utils/form/hooks/useMelody2Rhithm'
import DownloadIcon from '@mui/icons-material/Download';
import axios, { AxiosResponse } from 'axios'
import { generateMelody2rhitm } from 'api/melody2rhitm.api'

export function GenerateButton() {
    const state = useContext(AppState)
    const {auth} = useAuth()

    const handleGenerate = async (auth: any) => {
        // проверка: загружены все файлы
        if (state.melody2rhitmParams.value.melody_file_name === "" || state.melody2rhitmParams.value.rhythm_file_name === "") {
            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                error: "Файл(ы) не загружены"
            }
            return
        }

        try {
            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                isLoading: true,
                error: ""
            }

            const response = await generateMelody2rhitm(auth, state.melody2rhitmParams.value)

            if (typeof response === "string") {
                // если ошибка - обновляем состояние
                state.melody2rhitmParams.value = {
                    ...state.melody2rhitmParams.value,
                    isLoading: false,
                    error: response
                }
                return
            }
            const resUrl = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = resUrl;
            link.setAttribute('download', `melody2rhythm_${Date.now()}.mid`);
            document.body.appendChild(link);
            link.click();
            link.remove();  
            URL.revokeObjectURL(resUrl);

            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                isLoading: false,
                error: ""
            }
        } catch (error) {
            state.melody2rhitmParams.value = {
                ...state.melody2rhitmParams.value,
                isLoading: false,
                error: "что-то не так"
            }
        }

    }

  return (
    <Stack direction={"column"} spacing={1}>
        <Stack direction="row" spacing={1}>
            <Button variant="contained"
                onClick={() => handleGenerate(auth)}
                disabled={state.melody2rhitmParams.value.isLoading}
            >
                {
                    state.melody2rhitmParams.value.isLoading ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <CircularProgress/> 
                            <Typography>Loading...</Typography>
                        </Stack>
                    ) : 'Сгенерировать'
                }
            </Button>

        </Stack>
        
        {
            state.melody2rhitmParams.value.error !== "" ? (
                <Alert severity="error">{state.melody2rhitmParams.value.error}</Alert>
            ) : null
        }
    </Stack>
  )
}
