import React, { createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Router } from 'utils/routes/Routes';
import {HomePage, ResultsPage, PackagesPage, FileTaggingPage, MainPage, ResultInfoPage, ExperimentsPage} from 'views/pages/index';
import { Container } from '@mui/material';
import { createAppState } from 'state/state';

export const AppState = createContext(createAppState());

function App() {
  return (
    <AppState.Provider value={createAppState()}>
      <Container maxWidth="xl">
        <Routes>
          
          <Route path={Router.Home} element={<HomePage/>}>

            <Route path={Router.Main} element={<MainPage/>} />
            <Route path={Router.Results} element={<ResultsPage />} />
            <Route path={Router.Packages} element={<PackagesPage />} />
            <Route path={Router.Experiments} element={<ExperimentsPage />} />

          </Route>

          <Route path={Router.FileTagging} element={<FileTaggingPage />} />
          <Route path={Router.ResultInfo} element={<ResultInfoPage />} />

        </Routes>
      </Container>
    </AppState.Provider>
  );
}

export default App;
