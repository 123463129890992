import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SavedWeightsForm, SavedWeightsTable } from 'views/forms';
import useAuth from 'utils/form/hooks/useAuth';
import useConfig from 'utils/form/hooks/useConfig';
import { WeightsParams } from 'utils/form/hooks/types/configTypes';

export function SavedWeightsPanel() {

    const {auth} = useAuth()
    const {fetchSavedWeights} = useConfig()

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [savedWeights, setSavedWeights] = useState<WeightsParams[]>([])
    const handleChange = (panel: string) => async (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (!isExpanded) {
        return
      }
      const response = await fetchSavedWeights(auth)
      setSavedWeights(response)
    };

  return (
    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
            Сохраненные веса
        </AccordionSummary>
        <AccordionDetails>
            <SavedWeightsForm>
                <SavedWeightsTable savedWeights={savedWeights}/>
            </SavedWeightsForm>
        </AccordionDetails>
    </Accordion>
  )
}
