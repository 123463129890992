import { Stack, Typography } from '@mui/material'
import { AppState } from 'App'
import { AddMidiFileButton } from 'components'
import React, { useContext } from 'react'
import useSynthX from 'utils/form/hooks/useSynthX'

export function SynthPresetAddMidi() {
    const state = useContext(AppState)
    const {handleChangeMidiFile} = useSynthX()

  return (
    <Stack direction={"column"} spacing={1}>
      <AddMidiFileButton
        buttonText="midi data"
        handleFileChange={handleChangeMidiFile}
      />

      <Typography variant="caption">File: {state.synthXMidiFile.value.filename}</Typography>

    </Stack>
  )
}
