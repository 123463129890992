import { Stack } from '@mui/material'
import { AppState } from 'App';
import { SearchFilter } from 'components'
import React, { useContext, useState } from 'react'
import { useAppSelector } from 'store/store';

export function TableFilter() {
  const state = useContext(AppState);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    state.filterAllData.value = {
      ...state.filterAllData.value,
      name: event.target.value
    }
  }

  return (
    <Stack direction="row" sx={{ width: '70%'}}>
      <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange}></SearchFilter>
    </Stack>
  )
}
