import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Tooltip } from '@mui/material';
import useConfig from 'utils/form/hooks/useConfig';
import useAuth from 'utils/form/hooks/useAuth';

interface DeleteConfigButtonProps {
    config_id: number;
}

export function DeleteConfigButton(props: DeleteConfigButtonProps) {
    const {auth} = useAuth();
    const {handleDeleteWeights} = useConfig();

  return (
    <Button
        variant='outlined'
        onClick={() => handleDeleteWeights({config_id: props.config_id, auth})}
    >
        <Tooltip title='Удалить веса'>
          <DeleteOutlineIcon color='primary' />
        </Tooltip>
    </Button>
  )
}
