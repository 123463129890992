export const menuTexts = {
    "alldata":"Просмотр всех данных для обучения",
    "packages":"Отсортированное по пакетам хранилище загруженных файлов и тегов",
    "configs": "Полученные в ходе обучения конфигурации",
    "results": "Готовые результаты обучения"
}

export const allDataTexts = {
    "select": "Выбрать все файлы",
    "wav": "Файл формата wav",
    "jenre": "Основной жанр трека",
    "subjenre": "Второстепенный жанр трека",
    "instrument": "Основной музыкальный инструмент в треке",
    "rhythmicPattern": "Соотношение длительностей последовательного ряда звуков",
    "partCompose": "Часть композиции, которую нужно получить в готовом треке",
    "bpm": "Темп трека",
    "pack": "Идентификатор пакета для сортировки треков",
    "midi": ""
}


export const packagesTexts = {
    "select": "Выбрать все пакеты",
    "package": "Наименование пакета",
    "start": "Дата и время старта обучения пакета",
    "end": "Дата и время завершения обучения пакета",
    "statistics": "Статистика по выбранному пакету"
}

export const configTexts = {
    "configId": "",
    "jenre": "Основной жанр трека",
    "subjenre": "Второстепенный жанр трека",
    "instrument": "Основной музыкальный инструмент в треке",
    "rhythmicPattern": "Соотношение длительностей последовательного ряда звуков",
    "partCompose": "Часть композиции, которую нужно получить в готовом треке",
    "bpm": "Темп трека",
    "pack": "Идентификатор пакета для сортировки треков",
    "midi": "Ссылка на готовый трек в формате midi",
    "resMetric": "Результат метрики качества обучения"
}

export const resultsTexts = {
    "wav": "Сгенерированный wav-файл",
    "type": "Авто / ручной",
    "date": "Дата и время",
    "config": "Ссылка на конфиг",
    "grade": "От 0 до 5",
    "comment": "Комментарий"
}