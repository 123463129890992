import { Button, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react'
import axios from 'axios';
import { urlBase } from 'store/store';
import useAuth from 'utils/form/hooks/useAuth';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';

interface DownloadMidiButtonProps {
    packet_id: number,
    name: string,
    result_id: string,
    type: TypeMidi
}

export function DownloadMidiButton(props: DownloadMidiButtonProps) {
  const {auth, setAuth} = useAuth();

    const handleDownload = async () => {
      try {
        const response = await axios.get(`${urlBase}/results/midi/${props.type}/${props.result_id}/${props.packet_id}/${props.name}`, {
          responseType: 'blob',
          auth: {
            username: auth.username,
            password: auth.password
          }
        });

        // ссылка на полученный файл
        const url = URL.createObjectURL(new Blob([response.data]));

        // ссылка на скачивание файла
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', `${props.name.slice(0, -4)}`);
        link.setAttribute('download', `${props.name}`);
        document.body.appendChild(link);

        // симуляция нажатия
        link.click();

        // очистка
        link.remove();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error); 
      }
    }

  return (
    <>
      <Tooltip title="Скачать в формате MIDI" placement="left">
        <Button onClick={handleDownload}>
          <DownloadIcon/>
        </Button>
      </Tooltip>
    </>
  )
}
