import { computed, effect, signal } from "@preact/signals-react";
import { BASE_BASS_PRESET_SETTINGS, BASE_MAGENTA_SETTINGS, CHROMATIC_PARAMS, MELODY_2_RHITHM_PARAMS, MELODY_RNN_PARAMS, SYNTH_X } from "utils/constants/constans";
import { FiltersFile } from "utils/form/hooks/types/allDataTypes";
import { ConfigOut } from "utils/form/hooks/types/configTypes";
import { ChromaticParams, IMelodyRNNParams, Melody2RhitmParams } from "utils/form/hooks/types/experimentsTypes";
import { FlowParams, FlowResponse, ParamsDescriptions } from "utils/form/hooks/types/flowTypes";
import { Instrument, MidisForPacket, PacketObject } from "utils/form/hooks/types/packetTypes";
import { BassPreset, DefaultPresetSettings, PresetSettings } from "utils/form/hooks/types/presetTypes";
import { SynthX, SynthXMidiFile } from "utils/form/hooks/types/synthTypes";
import { TagStr } from "utils/form/hooks/types/tagsTypes";
// import { WavFile } from "utils/form/hooks/types/types";

export function createAppState() {

    const isLogin = signal(false);

    const keyTags = signal<TagStr[]>([]);
    const tonalityTags = signal<TagStr[]>([]);
    const instrumentTags = signal<TagStr[]>([]);
    const genreTags = signal<TagStr[]>([]);

    const filterAllData = signal<FiltersFile>({
        name: '',
        keyTagId: 0,
        tonalityTagId: 0,
        instrumentTagId: 0,
        genreTagId: 0, 
        bpmRange: [0, 1000000],
    });

    const uploadedCsvName = signal<string>('');
    const csvFile = signal<string[][]>([]);

    const selectedRowsAllData = signal<number[]>([]);
    const selectedRowsPackets = signal<number[]>([]);

    const flowResults = signal<FlowResponse[]>([]);

    const lastFlowResults = computed(() => {
        const resArray: FlowResponse[] = Object.values<FlowResponse>(
            flowResults.value.reduce((acc, obj) => {
                if (!acc[obj.packet_id] || obj.end_time > acc[obj.packet_id].end_time) {
                    acc[obj.packet_id] = obj;
                }
                return acc;
            }, {} as Record<number, FlowResponse>)
        );
        return resArray;
    });

    const instrumentsPacketPage = signal<Instrument[]>([]);

    const allMidisForPacket = signal<MidisForPacket[]>([]);

    const configs = signal<ConfigOut[]>([]);
    const unConfigs = computed(() => {
        const resArray: ConfigOut[] = Object.values<ConfigOut>(
            configs.value.reduce((acc, obj) => {
                if (!acc[obj.id]) {
                    // if(obj.datasets_id === acc[obj.id].datasets_id && obj.params_id === acc[obj.id].params_id) {
                        acc[obj.id] = obj;
                    // }
                }
                return acc;
            }, {} as Record<number, ConfigOut>)
        );
        return resArray;
    });
    const selectedConfig = signal<ConfigOut | null>(null);
    const allMidisForSelectedConfig = signal<MidisForPacket[]>([]);

    const presetsParamsDesc = signal<ParamsDescriptions | null>(null);
    const defaultBassPresets = signal<DefaultPresetSettings | null>(null);
    const selectedBassPreset = signal<number>(0);

    const bassPreset = signal<BassPreset>({
        bass_preset: 0,
        notes_capacity: null,
        range_notes: null,
        min_num_notes: null,
        min_num_unique_notes: null,
        notes_cutoff: null
    });

    const melodyRNNParams = signal<IMelodyRNNParams>(MELODY_RNN_PARAMS)

    const selectedParams = signal<FlowParams>({
        packet_id: 1,
        epochs: 100,
        instrument_id: 0,
        model_settings: BASE_MAGENTA_SETTINGS,
        bass_preset_settings: BASE_BASS_PRESET_SETTINGS,
        melodyRNNParams: MELODY_RNN_PARAMS
    });

    const melody2rhitmParams = signal<Melody2RhitmParams>(MELODY_2_RHITHM_PARAMS)
    const chromaticParams = signal<ChromaticParams>(CHROMATIC_PARAMS)

    const synthX = signal<SynthX>(SYNTH_X);
    const synthXMidiFile = signal<SynthXMidiFile>({
        url: "",
        filename: ""
    })
    const packets = signal<PacketObject[]>([]);

    return { 
        isLogin, 
        keyTags,
        tonalityTags,
        instrumentTags,
        genreTags,
        filterAllData,
        uploadedCsvName,
        csvFile,
        selectedRowsAllData,
        selectedRowsPackets,
        flowResults, 
        lastFlowResults, 
        instrumentsPacketPage, 
        allMidisForPacket,
        configs,
        unConfigs,
        selectedConfig,
        allMidisForSelectedConfig,
        selectedParams,
        presetsParamsDesc,
        defaultBassPresets,
        selectedBassPreset,
        bassPreset,
        melodyRNNParams,
        melody2rhitmParams,
        chromaticParams,
        synthX,
        synthXMidiFile,
        packets
    };
}