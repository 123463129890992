import { FormControl, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material'
import React from 'react'

interface TagFormNumberProps {
    selectedNumber: number | null,
    handleChangeNumber: (e: React.ChangeEvent<HTMLInputElement>) => void,
    name: string
}

export function TagFormNumber(props: TagFormNumberProps) {
  return (
    <FormControl>
        <OutlinedInput
            value={props.selectedNumber === 0 ? '' : props.selectedNumber}
            onChange={props.handleChangeNumber}
            inputProps={{
                // min: 0,
                type: "number"
            }}
            endAdornment={<InputAdornment position="end">{props.name}</InputAdornment>}
        />
    </FormControl>
  )
}
