import { TooltipIcon } from 'components/index';

import React, { useContext, useEffect } from 'react'
import {resultsTexts} from 'utils/form/texts/tooltips'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AppState } from 'App';
import { ConfigOut } from 'utils/form/hooks/types/configTypes';
import { Box, Collapse, IconButton, TablePagination } from '@mui/material';
import { ResultRow } from './ResultRow';

export function ResultsTable() {
    const state = useContext(AppState);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const sortedResults = [...state.unConfigs.value]
                            .sort((a, b) => b.id - a.id);

    /**
     * Обрабатывает изменение страницы в пагинируемом компоненте.
     * @param event - Объект события.
     * @param newPage - Новый номер страницы.
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };

    /**
     * Обрабатывает изменение количества строк на странице.
     * @param {React.ChangeEvent<HTMLInputElement>} event - Объект события.
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

  return (
    <Paper sx={{ width: '100%', p: "24px", borderRadius: "24px", my: "0px" }} elevation={0}>
        <TableContainer sx={{ height: '100%' }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {/* <TableCell align="left"><input type="checkbox" /></TableCell> */}
                        <TableCell align="left">пакет</TableCell>
                        <TableCell align="left">параметры обучения</TableCell>
                        <TableCell align="left">оценка</TableCell>
                        <TableCell align="right">результаты</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        sortedResults
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((config: ConfigOut) => (
                            <ResultRow config={config} key={config.id} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={sortedResults.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />

    </Paper>
  )
}

