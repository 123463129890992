import { Stack } from '@mui/material';
import React from 'react'

type BassPresetFormProps = {
    children?: React.ReactNode;
}
export function BassPresetForm({children}: BassPresetFormProps) {
  return (
    <Stack direction="column" spacing={2}>
        {children}
    </Stack>
  )
}
