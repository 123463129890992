import { Button, Tooltip } from '@mui/material'
import React from 'react'
import useAuth from 'utils/form/hooks/useAuth'
import useConfig from 'utils/form/hooks/useConfig'

interface SaveConfigButtonProps {
  config_id: number,
  is_saved: boolean | null
}

export function SaveConfigButton(props: SaveConfigButtonProps) {
    const {auth} = useAuth()
    const {handleSaveWeights, handleCancelSavingOfWeights} = useConfig()
  return (
    <Tooltip title={props.is_saved? 'Отменить сохранение' : 'Сохранить веса'}>
      <Button 
          variant={props.is_saved? 'outlined' : 'contained'}
          onClick={() => props.is_saved ? handleCancelSavingOfWeights(props.config_id, auth) : handleSaveWeights(props.config_id, auth)}
          // disabled={props.is_saved || false}
      >
        {
          props.is_saved ? 'Отменить сохранение' : 'Сохранить веса'
        }
      </Button>
    </Tooltip>
  )
}
