import { Paper, Stack, Typography } from '@mui/material'
import { AppState } from 'App';
import React, { useContext } from 'react'
import { Melody2RhytmForm, MelodyFileInput, RhythmFileInput, GenerateButton } from 'views/forms'

export function Melody2RhytmPanel() {
    const state = useContext(AppState);
  return (
    <Paper sx={{padding: 2}}>
        <Melody2RhytmForm>
            <Typography variant="body2">Melody2Rhytm</Typography>
            <MelodyFileInput />
            <RhythmFileInput />
            <GenerateButton />
        </Melody2RhytmForm>
    </Paper>
  )
}
