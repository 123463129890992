import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "react-router-dom";
import { urlBase } from "store/store";
import { PacketObject } from "utils/form/hooks/types/packetTypes";


interface PackageObjectsTypeState {
    packages: PacketObject[],
    loading: boolean,
    error: string | null
}

const initialState: PackageObjectsTypeState = {
    packages: [],
    loading: false,
    error: null
}

interface AuthParams {
    username: string,
    password: string
}

export const fetchPackageObjects = createAsyncThunk(
    "package-objects/fetch",
    async (params: AuthParams)=> {
        const response = await axios.get(`${urlBase}/packages/unique-packages/`, {
            auth: {
                username: params.username,
                password: params.password
            }
        });
        return response.data;
    }
)


export const PackageObjectSlice = createSlice({
    name: "package-objects",
    initialState,
    reducers: {
        addPackageObject: (state, action: PayloadAction<PacketObject>) => {
            state.packages.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPackageObjects.fulfilled, (state, action: PayloadAction<PacketObject[]>)=>{
            // state.packages.push(action.payload.packageObjects);
            state.packages = action.payload
        })
    }
})

export default PackageObjectSlice.reducer;
export const {addPackageObject} = PackageObjectSlice.actions;