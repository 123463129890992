import { useState } from 'react'
import { LocalStorage, SessionStorage } from 'services/index';

export interface Auth {
    username: string
    password: string
}

export default function useAuth() {
    const getAuth = () => {
        // const name = SessionStorage.getItem('username');
        // const pass = SessionStorage.getItem('password');
        const name = LocalStorage.get('username');
        const pass = LocalStorage.get('password');

        const auth: Auth = {
            username: name ? JSON.parse(name) : '',
            password: pass ? JSON.parse(pass) : '',
        };
        return auth;
    };

    const [auth, setAuth] = useState(getAuth());

    const saveAuth = (authData: Auth) => {
        // SessionStorage.clear();
        // SessionStorage.setItem('username', JSON.stringify(authData.username));
        // SessionStorage.setItem('password', JSON.stringify(authData.password));
        LocalStorage.clear();
        LocalStorage.set('username', JSON.stringify(authData.username));
        LocalStorage.set('password', JSON.stringify(authData.password));
        setAuth(authData);
    };

    return {
        setAuth: saveAuth,
        auth
    }
}