import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";
import {colors} from "styles/theme/foundations/palette";

type muiTableContainer = {
    defaultProps?: ComponentsProps['MuiTableContainer'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTableContainer'];
    variants?: ComponentsVariants['MuiTableContainer'];
}

export const MuiTableContainer: muiTableContainer = {
    styleOverrides: {
        root: {
            // borderRadius: "10px",
            // padding: "10px 20px",
            // backgroundColor: colors.primaryMain,
            boxShadow: "none",
        }
  }
}