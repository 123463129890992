import { Button, FormControl, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useContext } from 'react'
import useAuth from 'utils/form/hooks/useAuth';
import usePresets from 'utils/form/hooks/usePresets';
import useNotesCapacity from 'utils/form/hooks/useNotesCapacity';
import { NotesCapacitiInputButton } from './NotesCapacitiInputButton';
import { AppState } from 'App';
import AddIcon from '@mui/icons-material/Add';

export function NotesCapacityInput() {
  const {auth} = useAuth();
  const state = useContext(AppState);
  // const {presetsParamsDescriptions} = usePresets(auth);
  const {        
    notesCapacity,
    directions,
    selectedNotesCapacity,
    deleteKey,
    setNewItem,
    changeNote,
    changeDirection,
    changeCapacity
  } = useNotesCapacity();

  const capacity = (value: number) => {
    return value * 100;
  }

  return (
    <Stack spacing={"4px"} sx={{minWidth: "200px"}}>
      <Tooltip title={state.presetsParamsDesc.value?.notes_capacity}>

        <Typography 
          variant="caption" 
          display="block" 
          sx={{color: 'secondary.contrastText'}}
        >
            notes_capacity
            <HelpOutlineIcon fontSize='small' sx={{p: '2px'}}/>
        </Typography>
      </Tooltip>

      <Stack direction={'row'} spacing={"8px"} alignItems={'center'}>
        {/* note */}
        <FormControl sx={{maxWidth: '80px'}}>
          <OutlinedInput
            type='number'
            value={selectedNotesCapacity?.note}
            onChange={changeNote}
            size='small'
          />
        </FormControl>
        <Typography variant='body1'> is </Typography>
        {/* direction */}
        <FormControl>
          <Select
            value={
              directions.findIndex((d) => d === selectedNotesCapacity?.direction) ? 
              directions.findIndex((d) => d === selectedNotesCapacity?.direction).toString() : 
              '0'
            }
            onChange={changeDirection}
            size='small'
          >
            {
              directions.map((direction, index) => (
                <MenuItem key={index} value={index}>{direction}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <Typography variant='body1'> than </Typography>
        {/* capacity */}
        <FormControl sx={{maxWidth: '100px'}}>
          <OutlinedInput
            type='number'
            // value={selectedNotesCapacity?.capacity * 100}
            value={capacity(selectedNotesCapacity?.capacity)}
            onChange={changeCapacity}
            size='small'
            endAdornment={<Typography variant='body1'>%</Typography>}
          />
        </FormControl>

        <Button
          variant='contained'
          // sx={{p: "2px"}}
          // size='small'
          onClick={setNewItem}
        >
          <AddIcon/>
        </Button>
      </Stack>

      <Stack direction={'column'} spacing={"4px"}>
        {
          state.bassPreset.value.notes_capacity ? (
            state.bassPreset.value.notes_capacity.map((item, index) => (
              <NotesCapacitiInputButton 
                key={index}
                index={index}
                value={item}
                handleDeleteNoteCapacity={deleteKey}
              />
            ))) : null
        }
      </Stack>
    </Stack>
  )
}
