import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import React from 'react'
import { TagStr } from 'utils/form/hooks/types/tagsTypes'

interface TagFormProps {
    tagList: TagStr[],
    handleChangeTag: (event: SelectChangeEvent) => void,
    selectedTag: number,
    nameForm: string
}

export function TagForm(props: TagFormProps) {
  return (
    <Stack spacing={"4px"} sx={{minWidth: "200px"}}>
        <Typography variant="caption" display="block" sx={{color: 'secondary.contrastText'}}>{props.nameForm}</Typography>
        <FormControl>
            <Select
                value={props.selectedTag.toString() || ''}
                onChange={props.handleChangeTag}
                >
                <MenuItem key={0} value={0}>Не выбран</MenuItem>
                {
                    props.tagList.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    </Stack>
  )
}
