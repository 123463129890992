import { Button, Tooltip } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { urlBase } from 'store/store';
import { TypeMidi } from 'utils/form/hooks/enums/typesResult';
import useAuth from 'utils/form/hooks/useAuth';
import DownloadIcon from '@mui/icons-material/Download';
import { fetchConfigArchive } from 'api/results.api';


interface DownloadConfigArchiveProps {
    config_id: number,
    type: TypeMidi
}
export function DownloadConfigArchive(props: DownloadConfigArchiveProps) {
    const {auth, setAuth} = useAuth();

    const handleDownload = async () => {
        try {
            // const response = await axios.get(`${urlBase}/results/archive/config/${props.type}/${props.config_id}`, {
            //     responseType: 'arraybuffer',
            //     auth: {
            //         username: auth.username,
            //         password: auth.password
            //     }
            // });
            const response = await fetchConfigArchive({auth, type: props.type, obj_id: props.config_id})
            const resUrl = URL.createObjectURL(new Blob([response], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = resUrl;
            link.setAttribute('download', `full_tracks_${props.config_id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();  
            URL.revokeObjectURL(resUrl);
        } catch (error) {
            console.error(error);
        }
    }

  return (
    <Tooltip title="Скачать архив" placement="left">
        <Button
            variant="contained"
            onClick={handleDownload}
        >
            full_tracks_{props.config_id}.zip
            <DownloadIcon/>
        </Button>
    </Tooltip>
  )
}
