import React from 'react'
import {statisticsTexts} from 'utils/form/texts/statistic';
import {StatisticRow} from 'components/index';
import { Paper, Stack, Typography } from '@mui/material';
import { FlowResponse, FlowStats } from 'utils/form/hooks/types/flowTypes';

interface StatisticBoxProps {
  stat: FlowResponse | null,
  packet_info: number
}
export function StatisticBox(props: StatisticBoxProps) {
  const getText = (text: string, valueN: number) => {
    return(text.replace("N", valueN.toString()));
  }

  return (
    <Stack spacing={"8px"} direction={"column"}>
      <Typography variant="h6">Статистика для пакета {props.packet_info}</Typography>

      <Paper elevation={0} sx={{py: "8px", px: "12px", borderRadius: "8px"}}>
        {
          props.stat ? (
            <Stack spacing={1}>
              {/* <StatisticRow nameRow={statisticsTexts.notes_not_in_key} valueRow={props.stat.stats.notes_not_in_key}/> */}
              <StatisticRow nameRow={getText(statisticsTexts.notes_not_in_key, props.stat.stats.num_compositions)} valueRow={props.stat.stats.notes_not_in_key}/>

              <StatisticRow nameRow={getText(statisticsTexts.notes_in_motif, props.stat.stats.num_compositions)} valueRow={props.stat.stats.notes_in_motif}/>
              <StatisticRow nameRow={getText(statisticsTexts.notes_in_repeated_motif, props.stat.stats.num_compositions)} valueRow={props.stat.stats.notes_in_repeated_motif}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_starting_tonic, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_starting_tonic}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_repeated_notes, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_repeated_notes}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_octave_jumps, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_octave_jumps}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_fifths, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_fifths}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_fourths, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_thirds}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_sixths, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_sixths}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_seconds, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_seconds}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_fourths, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_fourths}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_sevenths, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_sevenths}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_rest_intervals, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_rest_intervals}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_special_rest_intervals, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_special_rest_intervals}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_in_key_preferred_intervals, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_in_key_preferred_intervals}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_resolved_leaps, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_resolved_leaps}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_leap_twice, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_leap_twice}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_high_unique, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_high_unique}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_low_unique, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_low_unique}/>
              <StatisticRow nameRow={getText(statisticsTexts.num_compositions, props.stat.stats.num_compositions)} valueRow={props.stat.stats.num_compositions}/>
              <StatisticRow nameRow={getText(statisticsTexts.total_notes, props.stat.stats.num_compositions)} valueRow={props.stat.stats.total_notes}/>
            </Stack>
          ) : <></>
        }
      </Paper>

    </Stack>
  )
}