import { FormControl, TextField } from '@mui/material'
import { AppState } from 'App'
import React, { useContext } from 'react'
import useVariationPreset from 'utils/form/hooks/useVariationPreset'

export function QpmInput() {
  const state = useContext(AppState)
  const {handleChangeNumberData} = useVariationPreset()
  return (
    <FormControl>
      <TextField
        type='number'
        value={state.melodyRNNParams.value.qpm}
        onChange={handleChangeNumberData}
        name='qpm'
        label='qpm'
      />
    </FormControl>
  )
}
